export const setItems = (items = []) => ({
  type: 'SET_ITEMS',
  items
})
export const setScrollPosition = (scrollPosition = 0) => ({
  type: 'SET_SCROLL_POSITION',
  scrollPosition
})
export const setAmount = (amountItems = 0) => ({
  type: 'SET_AMOUNT',
  amountItems
})
export const setLimit = (pages = 0) => ({
  type: 'SET_LIMIT',
  pages
})
export const setFilterData = filterData => ({
  type: 'SET_FILTER_DATA',
  filterData
})