const initialState = {
  advanceMode: false,
  shouldDisplay: false,
  collection: '0',
  dateRange: '0',
  text: '0',
  favText: '0',
  page: 0,
  transversalApproach: '0',
  keyword: '0',
  label: '0',
  genders: [],
  mediums: [],
  typologies: [],
  languages: [],
  techniques: [],
  thematicCoverage: [],
  agentType: [],
  funds: [],
  advanceCollection: [],
  titleType: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADVANCE_MODE': return { ...state, advanceMode: !state.advanceMode }
    case 'SET_COLLECTION': return { ...state, collection: action.collection }
    case 'SET_DATE_RANGE': return { ...state, dateRange: action.dateRange, }
    case 'SET_TEXT_FILTER': return { ...state, text: action.text }
    case 'SET_PAGE': return { ...state, page: action.page, }
    case 'SET_TRANSVERSAL_APPROACH': return { ...state, transversalApproach: action.transversalApproach, }
    case 'SET_KEYWORD': return { ...state, keyword: action.keyword, }
    case 'SET_LABEL': return { ...state, label: action.label }

    case 'ADD_GENDER': return { ...state, genders: [...state.genders, action.gender] }
    case 'REMOVE_GENDER': return { ...state, genders: state.genders.filter(gender => gender !== action.gender) }
    case 'ADD_MEDIUM': return { ...state, mediums: [...state.mediums, action.medium] }
    case 'REMOVE_MEDIUM': return { ...state, mediums: state.mediums.filter(medium => medium !== action.medium) }
    case 'ADD_TYPOLOGY': return { ...state, typologies: [...state.typologies, action.typology] }
    case 'REMOVE_TYPOLOGY': return { ...state, typologies: state.typologies.filter(typology => typology !== action.typology) }
    case 'ADD_LANG': return { ...state, languages: [...state.languages, action.lang] }
    case 'REMOVE_LANG': return { ...state, languages: state.languages.filter(lang => lang !== action.lang) }
    case 'ADD_TECHNIQUE': return { ...state, techniques: [...state.techniques, action.technique] }
    case 'REMOVE_TECHNIQUE': return { ...state, techniques: state.techniques.filter(technique => technique !== action.technique) }
    case 'ADD_kEYWORD': return { ...state, thematicCoverage: [...state.thematicCoverage, action.keyword] }
    case 'REMOVE_kEYWORD': return { ...state, thematicCoverage: state.thematicCoverage.filter(keyword => keyword !== action.keyword) }

    case 'ADD_TITLE_TYPE': return { ...state, titleType: [...state.titleType, action.titleType] }
    case 'REMOVE_TITLE_TYPE': return { ...state, titleType: state.titleType.filter(titleType => titleType !== action.titleType) }

    case 'ADD_AGENT_TYPE': return { ...state, agentType: [...state.agentType, action.agentType] }
    case 'REMOVE_AGENT_TYPE': return { ...state, agentType: state.agentType.filter(agentType => agentType !== action.agentType) }

    case 'ADD_ADVANCE_COLLECTION':
      return {
        ...state,
        advanceCollection: [...state.advanceCollection, action.advanceCollection]
      }
    case 'REMOVE_ADVANCE_COLLECTION':
      return {
        ...state,
        advanceCollection: state.advanceCollection.filter(advanceCollection => advanceCollection !== action.advanceCollection)
      }

    case 'ADD_FUND': return { ...state, funds: [...state.funds, action.fund] }
    case 'REMOVE_FUND': return { ...state, funds: state.funds.filter(fund => fund !== action.fund) }

    case 'DISPLAY': return { ...state, shouldDisplay: true }
    case 'HIDE': return { ...state, shouldDisplay: false }

    case 'SET_FAV_TEXT': return { ...state, favText: action.text }

    case 'RESET_STATE': return { ...initialState, advanceMode: state.advanceMode, shouldDisplay: state.shouldDisplay }
    default: return state
  }
}
