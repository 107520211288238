import React from 'react'
import { createUser } from '../../services/learn'
import { isFormValid } from '../../utils/fieldValidations'


/**
 * Clase para 
 *
 * @export
 * @class NewUser
 * @extends {React.Component}
 */
export default class NewUser extends React.Component {
  state = {
    name: '',
    email: '',
    password: '',
    warningFields: []
  }

  /**
   * Valida los valores del formulario
   *
   * @memberof NewUser
   */
  validateFields = () => {
    let fields = {
      username: {
        name: 'nombre de usuario',
        value: this.state.name
      },
      email: {
        name: 'correo electrónico',
        value: this.state.email
      },
      password: {
        name: 'contraseña',
        value: this.state.password
      }
    }
    isFormValid(fields, fields => {
      if (fields.length === 0) this.createUserHandler()
      else this.setState({ warningFields: fields })
    })
  }

  /**
   * Registra el usuario en el servidor
   *
   * @memberof NewUser
   */
  createUserHandler = () => {
    const { email, name, password } = this.state
    createUser({ email, password, name, alucina_team: '0', password_confirmation: password, role: 5 })
      .then(res => {
        if (Number(res.data.status) === 200) {
          this.props.onNewUserCreated({ email, password })
        }
      })
      .catch(error => console.log('error', error))
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement} 
   * @memberof NewUser
   */
  render() {
    return <div className="inner-login text-center">
      <h1 className="title font_bold uppercase text-center">Nuevo Usuario</h1>
      <p className="required-account">Ingresa tu datos para registrarte</p>
      <input
        className='btn_shadow awesome_input'
        value={this.state.name}
        onChange={e => { this.setState({ name: e.target.value }) }}
        placeholder='Nombre completo' />
      <input
        className='btn_shadow awesome_input'
        value={this.state.email}
        onChange={e => { this.setState({ email: e.target.value }) }}
        placeholder='Email' />
      <input
        type='password'
        className='btn_shadow awesome_input'
        value={this.state.password}
        onChange={e => { this.setState({ password: e.target.value }) }}
        placeholder='Contraseña' />
      <button onClick={this.validateFields} className="pink_btn btn_shadow">
        <span>Crear usuario</span>
      </button>
      {
        this.state.warningFields.map((warning, index) => <p key={index} className={`error error-${index}`}>{warning.message}</p>)
      }
      <h5 className='create-account grey_font'><span className="action-account pink_font" onClick={() => this.props.onChangeSectionHandler('login')}>Iniciar sesión</span></h5>
    </div>
  }
}
