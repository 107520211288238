const initialState = {
  isLoading: true,
  footerLinkNames: {
    acknowledgement: 'Créditos y agradecimientos',
    legals: 'Legales',
    terms: 'Términos y condiciones',
    privacy: 'Aviso de privacidad',
    declaration: 'Declaración de uso',
  },
  acknowledgement: {
    section1: {
      title: 'Créditos y agradecimientos',
      content: []
    },
    section2: {
      title: 'CRÉDITOS M68 (exhibición y colección digital)',
      content: []
    },
    section3: {
      title: 'Este proyecto es posible gracias al apoyo y la colaboración de:',
      content: ''
    },
  },
  legals: {
    title: 'Legales',
    content: ''
  },
  terms: {
    title: 'Términos y condiciones',
    content: ''
  },
  privacy: {
    title: 'Aviso de privacidad',
    content: ''
  },
  declaration: {
    title: 'Declaración de uso',
    content: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FOOTER_DATA': return { ...state, ...action.data }
    default: return state
  }
}