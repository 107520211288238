import axios from 'axios'

/*
* production:  https://plataforma.m68.mx/api
* development: https://stage.m68.mx/api
*/

const instance = axios.create({
  baseURL: 'https://plataforma.m68.mx/api'
})

export default instance
