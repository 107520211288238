import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ReactGA from 'react-ga'

import PrivateRoute from './baseComponents/PrivateRoute'

import About from './containers/About'
import Background from './baseComponents/Background'
import Collection from './containers/Collection'
import CollectionItem from './containers/CollectionItem'
import Footer from './baseComponents/Footer'
import Home from './containers/Home'
import Learn from './containers/Learn'
import Navbar from './baseComponents/Navbar'
import NotFound from './containers/NotFound'
import Privacy from './containers/Privacy'
import PrivacyNotice from './containers/PrivacyNotice'
import Visit from './containers/Visit'
import Legal from './containers/Legal'
import Credits from './containers/Credits'
import MyCollection from './containers/MyCollection'
import RegeneratePassword from './containers/RegeneratePassword'
import DeclarationOfUse from './containers/DeclarationOfUse'
import Chat from './baseComponents/Chat'
import GalleryScreen from './containers/GalleryScreen'

import { getFavs } from './services/userFavs'
import { setFavs } from './redux/actions/user'
import { setData } from './redux/actions/app'
import axios from 'axios'
import axios2 from './services/instance'

import store from './redux/store'
import { authUser } from './redux/actions/user'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './app.css'
import { setFooter } from './redux/actions/footer'

ReactGA.initialize('UA-126641503-1')

/**
 * Clase controladora de las rutas de la aplicación
 *
 * @class App
 * @extends {React.Component}
 */
class App extends React.Component {


  /**
   * Método que valida la existencia de usuario y carga información sobre este
   *
   * @memberof App
   */
  componentDidMount() {
    ReactGA.initialize('UA-126641503-1')
    let user = sessionStorage.getItem('0mZTFbxo1u')

    axios2('/cms/footer')
      .then(res => {
        let { footer } = res.data
        footer = JSON.parse(footer)
        store.dispatch(setFooter({ ...footer, isLoading: false }))
      })
      .catch(err => {
        console.log(err)
      })

    axios('https://plataforma.m68.mx/api/cms/cms')
      .then(res => {
        let cms = res.data[0]
        if (cms) {
          store.dispatch(setData({
            archives: JSON.parse(cms.archives),
            about: cms.about,
            collection_txt: cms.collection_txt,
            keywords: cms.keywords.split('||'),
            visit_title: cms.visit_title,
            biblio_sources: cms.biblio_sources === null ? [] : cms.biblio_sources.split('|||'),
            visit_txt1: cms.visit_txt1
              .split('|||')
              .map((text, index) => {
                let title = text.split('||>')
                  , body = title.length > 1 ? title[1] : ''
                return { id: index, title: title[0], body }
              }),
            visit_txt2: cms.visit_txt2
              .split('|||')
              .map((text, index) => {
                let title = text.split('||>')
                  , body = title.length > 1 ? title[1] : ''
                return { id: index, title: title[0], body }
              }),
            spaces_img: [
              `${cms.spaces_img1}`,
              `${cms.spaces_img2}`,
              `${cms.spaces_img3}`,
            ],
            spaces_txt: [
              cms.spaces_txt1,
              cms.spaces_txt2,
              cms.spaces_txt3
            ]
          }))
        }
        return axios('https://plataforma.m68.mx/api/cms/glosary')
      })
      .then(res => {
        store.dispatch(setData({
          glosary: res.data
        }))
      })
      .catch(err => {
        throw new Error(err)
      })
    if (user !== null) {
      user = JSON.parse(user)
      store.dispatch(authUser({ ...user }))
      getFavs()
        .then(items => {
          store.dispatch(setFavs(items))
        })
        .catch(err => {
          throw new Error(err)
        })
    }
  }

  /**
   * Método encargado de renderizar las rutas
   *
   * @returns
   * @memberof App
   */
  render() {
    return <React.Fragment>
      <Navbar history={this.props.history} location={this.props.location} />
      <Background />
      <TransitionGroup style={{ width: '100%', height: '100vh' }}>
        <CSSTransition
          key={this.props.location.key}
          classNames='page'
          timeout={2400}
        >
          <Switch location={this.props.location}>
            <Route exact path='/' component={Home} />
            <Route exact path='/acerca-de' component={About} />
            <Route exact path='/aprende' component={Learn} />
            <Route exact path='/coleccion' component={Collection} />
            <Route exact path='/coleccion/:id' component={CollectionItem} />
            <Route exact path='/terminos-y-condiciones' component={Privacy} />
            <Route exact path='/visita' component={Visit} />
            <Route exact path='/legal' component={Legal} />
            <Route exact path='/creditos-y-agradecimientos' component={Credits} />
            <Route exact path='/declaracion-de-uso' component={DeclarationOfUse} />
            <Route exact path='/aviso-de-privacidad' component={PrivacyNotice} />
            <Route exact path='/galeria' component={GalleryScreen} />
            <Route exact path='/nueva-contrasena/:id' component={RegeneratePassword} />
            <PrivateRoute path='/mi-coleccion' component={MyCollection} />
            <Route component={NotFound} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer history={this.props.history} location={this.props.location} />
      {
        //<Chat {...this.props} />
      }
      <Chat {...this.props} />
    </React.Fragment>
  }
}

export default withRouter(App)
