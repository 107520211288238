import React from 'react'

import AudioContainer from './AudioContainer/AudioContainer'
import FilesContainer from './FilesContainer/FilesContainer'
import ImageContainer from './ImageContainer/ImageContainer'
import VideoContainer from './VideoContainer/VideoContainer'

import './Modal.css'


/**
 * Clase para controlar el modal de visor de contenido multimedia
 *
 * @class Modal
 * @extends {React.Component}
 */
class Modal extends React.Component {

  state = {
    files: [],
    index: 0,
    propIndex: 0,
    width: 0,
    height: 0,
    hasDimensions: false,
  }


  /**
   * Toma el siguiente elemento del arreglo
   *
   * @memberof Modal
   */
  changeResource = (e, value) => {
    e.stopPropagation()
    let newIndex = this.state.index + value
    newIndex = newIndex < 0
      ? this.state.files.length - 1
      : newIndex >= this.state.files.length
        ? 0
        : newIndex
    this.setState({ index: newIndex })
  }


  /**
   * retorna el componente pare mostrar el archivo multimedia
   *
   * @returns {ReactComponent}
   * @memberof Modal
   */
  getMediaResource = () => {
    if (this.state.propIndex !== this.props.index) this.setState({ index: this.props.index, propIndex: this.props.index })
    const resource = this.state.files.filter((_, i) => this.state.index === i)[0]
    let content = null
      , media = resource.mime.split('/')[0]

    switch (media) {
      case 'image':
        content = <ImageContainer src={resource.fullPath} origin={resource.origin} />
        break;
      case 'video':
        content = <VideoContainer src={resource.fullPath} />
        break;
      case 'application':
        content = <FilesContainer src={resource.fullPath} />
        break;
      case 'audio':
        content = <AudioContainer src={resource.fullPath} />
        break;
      default:
        break;
    }
    return content
  }


  /**
   * Detiene la propagación del click
   *
   * @memberof Modal
   */
  stopPropagation = e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }

  componentDidMount() {
    this.setState({
      files: this.props.files,
      index: this.props.index,
      propIndex: this.props.index
    })
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof Modal
   */
  render() {
    let classes = this.state.files.length === 1 ? 'hidden' : ''
    return this.props.mustShow
      ? <React.Fragment>
        <div onClick={this.props.onClose} className="close_modal">
          <i className="icon icon-times"></i>
        </div>
        <span onClick={e => { e.stopPropagation(); this.changeResource(e, -1) }} className={`arrow left ${classes}`}><i className="icon icon-chevron-left"></i></span>
        <div className="modal fade in" id="myModal" onClick={this.props.onClose}>
          <div className="vertical-alignment-helper">
            <div className="modal-dialog vertical-align-center">
              <div id='content' className="modal-content" onClick={this.stopPropagation}>
                {this.getMediaResource()}
              </div>
            </div>
          </div>
        </div >
        <span onClick={e => { e.stopPropagation(); this.changeResource(e, 1) }} className={`arrow right ${classes}`}><i className="icon icon-chevron-right"></i></span>
      </React.Fragment>
      : null
  }
}

export default Modal