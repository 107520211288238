import React from 'react'
import animations from '../../utils/animations'
import ReactGA from 'react-ga'

import title_visit from '../../../assets/img/titles/title_visit.svg'
import appstore_logo from '../../../assets/img/App_Store_Badge.svg'
import playstore_logo from '../../../assets/img/playstore_logo.svg'

import { connect } from 'react-redux'

const $ = window.$
  , google = window.google

/**
 * Clase contralar la interfaz de la pantalla Visit
 *
 * @export
 * @class Privacy
 * @extends {React.Component}
 */
class Visit extends React.Component {

  getUrlFromText = text => {
    let urlRegex = /(https?:\/\/[^\s]+)/g
      , paragraphs = text.replace(urlRegex, url => `||${url}||`).split('||')
    if (paragraphs.length > 2) {
      let paragraph = paragraphs.filter((_, i) => i % 2 === 0)
        , links = paragraphs.filter((_, i) => i % 2 === 1)
      return paragraph.map((p, i) => {
        return links[i]
          ? <a target='_blank' rel="noopener noreferrer" href={links[i]}>{p}</a>
          : <span>{p}</span>
      })
    }
    return <p>{text}</p>
  }


  /**
   * Ejecuta la animación cuando el componente se ha montado
   *
   * @memberof Visit
   */
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)
    if ($(document).find("#map_container").length > 0) {
      let map, directionsDisplay
      let marker, m68Lat
      m68Lat = new google.maps.LatLng(19.449984, -99.137485)

      var mapOptions = {
        zoom: 16,
        center: m68Lat,
        panControl: false,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        mapTypeControl: false
      }

      map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions)

      directionsDisplay = new google.maps.DirectionsRenderer()
      directionsDisplay.setMap(map)

      marker = new google.maps.Marker({
        position: m68Lat,
        title: "Centro Cultural Universitario Tlatelolco",
        icon: "https://maps.google.com/mapfiles/ms/icons/pink-dot.png"
      })

      let infoMaker = "Centro Cultural Universitario Tlatelolco"
      let infowindowMarker = new google.maps.InfoWindow({ content: infoMaker })

      google.maps.event.addListener(marker, 'click', () => { infowindowMarker.open(map, marker) })
      infowindowMarker.open(map, marker)
      marker.setMap(map)

      var styles = [
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e9e9e9"
            },
            {
              "lightness": 17
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 17
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 29
            },
            {
              "weight": 0.2
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 18
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 16
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            },
            {
              "lightness": 21
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dedede"
            },
            {
              "lightness": 21
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#ffffff"
            },
            {
              "lightness": 16
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "saturation": 36
            },
            {
              "color": "#333333"
            },
            {
              "lightness": 40
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f2f2f2"
            },
            {
              "lightness": 19
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#fefefe"
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#fefefe"
            },
            {
              "lightness": 17
            },
            {
              "weight": 1.2
            }
          ]
        }
      ]

      map.setOptions({ styles: styles })
    }
    animations.visit.in()
  }
  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Visit
   */
  render() {
    return <div id="visit" className="section section_padding ">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-sm-push-1">
            <div className="row">
              <div className="col-sm-12">
                <div id="visit_title" className='title font_medium visit_animation'>
                  <img className="img-responsive" alt="Visita" src={title_visit} />
                </div>
                <div id="visit_description" className='description visit_animation'>
                  <p className="subtitle pink_font font_medium uppercase">
                    Av. Ricardo Flores Magón 1. Tlatelolco, 06995 Cuauhtémoc, CDMX
									</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h3 id="visit_subtitle_02" className="visit_animation font_bold">{this.props.app.visit_title}</h3>
              </div>
              <div className="col-sm-12 col-md-5">
                <p id="visit_text_01" className='visit_animation '>
                  {
                    this.props.app.visit_txt1.map(text => <React.Fragment>
                      <span className="font_bold">{text.title}</span><br />
                      {text.body.split('\n').map((t, i) => <React.Fragment>
                        {t}
                        <br />
                      </React.Fragment>)}
                    </React.Fragment>)
                  }
                </p>
              </div>
              <div className="col-sm-12 col-md-6">
                <p id="visit_text_02" className='visit_animation '>
                  {
                    this.props.app.visit_txt2.map(text => <React.Fragment>
                      <span className="font_bold">{text.title}</span><br />
                      {text.body.split('\n').map((t, i) => <React.Fragment>
                        {t}
                        <br />
                      </React.Fragment>)}
                    </React.Fragment>)
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-sm-push-1">
            <div id="map_container" className='visit_animation'>
              <div id="map-canvas"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="download_app" className="container-fluid visit_animation">
        <div className="row">
          <div className="col-sm-10 col-sm-push-1">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="font_bold title">Descarga nuestra app</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <a rel="noopener noreferrer" target="_blank" href="https://itunes.apple.com/mx/app/m68/id1439252912?mt=8">
                  <img className="store_logo" alt="Appstore" src={appstore_logo} />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.alucinastudio.m68&hl=es">
                  <img className="store_logo" alt="Google Play" src={playstore_logo} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="visit_other_spaces" className="container-fluid visit_animation">
        <div className="row">
          <div className="col-sm-10 col-sm-push-1">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="font_bold title">Otras actividades en Tlatelolco</h3>
              </div>
            </div>
            <div className="row">
              {
                this.props.app.spaces_img.map((image, index) => <div key={image} className="col-sm-4">
                  <div className="info_block">
                    <div className="image">
                      <img className="img-responsive center-block" alt="Unidad de vinculación artística" src={image} />
                    </div>
                    <div className="description ">
                      {this.getUrlFromText(this.props.app.spaces_txt[index])}
                    </div>
                  </div>
                </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  app: state.app
})

export default connect(mapStateToProps)(Visit)