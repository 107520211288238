import React from 'react'

import spinner from '../../../assets/img/spinner.svg'

/**
 * Componente stateless para renderizar animación de carga
 *
 * @param {Object} props
 */
const loading = props => <div className='section collection_animation section_padding'>
  <div className="container-fluid">
    <div className="col-sm-12 text-center spinner">
      <img src={spinner} alt='spinner' />
    </div>
  </div>
</div>
export default loading