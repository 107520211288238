import React from 'react'

/**
 * Component stateless para filtro tema
 *
 * @param {Object} props
 */
const collectionFilter = props => props.activeSearch === 'collection'
  ? <React.Fragment>
    {
      props.filters.map(filter => <div key={filter.title} className='subject'>
        <div className='title uppercase font_bold'>{filter.title}</div>
        {
          filter.data.map(item => <div key={item.id} className='search_bar_col'>
            <span className='search_item_link' onClick={() => props.addFilterHandler('collection', item.id)}>{item.public_name}</span>
          </div>)
        }
      </div>)
    }
  </React.Fragment>
  : null

export default collectionFilter