import React from 'react'

import './AudioContainer.css'
import radio_wave from '../../../../../../assets/img/radio_wave.jpg'


/**
 * Componente stateless para la representación del visor de audio
 *
 * @param {*} props
 * @returns
 */
const audioContainer = props => {
  let url = props.src.replace('.jpg', '')
  return <div className="audio_container">
    <div className="audio_poster">
      <img className="img-responsive" src={radio_wave} alt='Audio' />
    </div>
    <audio controls>
      <source src={url} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
}

export default audioContainer




