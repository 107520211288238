const initialState = {
  about: '',
  collection_txt: '',
  keyword: '',
  keywords: [],
  visit_title: '',
  visit_txt1: [],
  visit_txt2: [],
  spaces_txt: [],
  spaces_img: [],
  biblio_sources: [],
  glosary: [],
  archives: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA': return { ...state, ...action.data }
    default: return state
  }
}