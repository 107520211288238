import React from 'react'

/**
 * Clase controladora de errores lógicos en al aplicación
 *
 * @export
 * @class ErrorBoundary
 * @extends {React.Component}
 */
export default class ErrorBoundary extends React.Component {

  state = { hasError: false }


  /**
   * Método que intercepta errores lógicos en tiempo de ejecución
   *
   * @param {Object} error
   * @param {Object} info
   * @memberof ErrorBoundary
   */
  componentDidCatch(error, info) {
    this.setState({ hasError: true })
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof ErrorBoundary
   */
  render() {
    if (this.state.hasError) return <div id="collection" className='section collection_animation in section_padding'>
      <div className="container-fluid">
        <h1>Algo salió mal, por favor intenta más tarde.</h1>
      </div>
    </div>

    return this.props.children
  }
}