import React from 'react'
import ReactGA from 'react-ga'
import Loading from '../../baseComponents/Loading'

import { connect } from 'react-redux'

import animations from '../../utils/animations'
/**
 * Clase contralar la interfaz de la pantalla Privacy
 *
 * @export
 * @class PrivacyNotice
 * @extends {React.Component}
 */
class PrivacyNotice extends React.Component {
  /**
   * Ejecuta la animación cuando el componente se ha montado
   *
   * @memberof PrivacyNotice
   */
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)
    animations.privacy.in()
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) animations.privacy.in()
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof PrivacyNotice
   */
  render() {
    const { isLoading, privacy } = this.props.footer

    if (isLoading) return <Loading />

    return <div id="privacy" className="section section_padding privacy_animation">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-push-2">
            <div id="privacy_title" className='font_medium text-center privacy_animation'>
              <h1 className="font_bold">{privacy.title}</h1>
            </div>
            <div className='description privacy_animation'>

              <div dangerouslySetInnerHTML={{ __html: privacy.content }} />

            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => ({
  footer: state.footer,
})

export default connect(mapStateToProps)(PrivacyNotice)