import React from 'react'
import { connect } from 'react-redux'

import Contact from './components/Contact'
import Login from './components/Login'

import animations from '../../utils/animations'
import { setItems, setScrollPosition } from '../../redux/actions/data'
import { removeAuth, showAlert } from '../../redux/actions/user'


import './NavBar.css'

import logo from '../../../assets/img/logo_tlatelolco_01.svg'
const $ = window.$


/**
 * Clase para controlar el estado de interfaz del menú de la aplicación
 *
 * @class NavBar
 * @extends {React.Component}
 */
class NavBar extends React.Component {
  state = { isOpen: false, show: false, loginForm: false, hoverLogin: false }


  /**
   * Modifica el estado del menú en movil
   *
   * @memberof NavBar
   */
  onOpen = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }


  /**
   * Valida si un elemento esta activo
   *
   * @returns {Boolean}
   * @memberof NavBar
   */
  isActive = path => this.props.location.pathname.search(path) !== -1 ? 'active' : ''


  /**
   * Despliega el formulario de contacto
   *
   * @memberof NavBar
   */
  showForm = () => {
    this.setState(prevState => ({
      show: !prevState.show
    }))
  }


  /**
   * Despliega el formulario de login
   *
   * @memberof NavBar
   */
  showLoginForm = () => {
    if (this.props.user.showAlert) this.props.dispatch(showAlert())
    else this.setState(prevState => ({
      loginForm: !prevState.loginForm
    }))
  }


  /**
   * Actualiza el estado de la apliación cuando un elemento obtiene el hover
   *
   * @memberof NavBar
   */
  enterHover = () => {
    this.setState(prevState => ({ hoverLogin: true }))
  }

  /**
   * Actualiza el estado de la apliación cuando un elemento pierde el hover
   *
   * @memberof NavBar
   */
  leaveHover = () => {
    this.setState(prevState => ({ hoverLogin: false }))
  }


  /**
   * Manejador de las animaciones
   *
   * @memberof NavBar
   */
  onclickHandler = path => {
    this.props.dispatch(setItems([]))
    this.props.dispatch(setScrollPosition(0))
    if (this.props.location.pathname === path) return
    switch (this.props.location.pathname) {
      case '/': { animations.home.out(); break }
      case '/coleccion': { animations.collection.out(); break }
      case '/acerca-de': { animations.about.out(); break }
      case '/visita': { animations.visit.out(); break }
      case '/aviso-de-privacidad': { animations.privacy.out(); break }
      case '/aprende': { animations.learn.out(); break }
      case '/galeria': { animations.collection.out(); break }
      case '/creditos-y-agradecimientos': { animations.credits.out(); break }
      case '/legal': { animations.legal.out(); break }
      case '/terminos-y-condiciones': { animations.privacy.out(); break }
      default: { animations.single.out() }
    }
    if ($(window).width() < 768)
      $("#hambuguer_btn").click()
    setTimeout(() => { this.props.history.push(path) }, 1200)
  }


  /**
   * Elimina la sesión del usuario
   *
   * @memberof NavBar
   */
  destroySession = () => {
    sessionStorage.removeItem('0mZTFbxo1u')
    this.props.dispatch(removeAuth())
    this.props.history.push('/')
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof NavBar
   */
  render() {
    return (
      <React.Fragment>
        <Contact show={this.state.show} onClick={this.showForm} />
        <Login showMessage={this.props.user.showAlert} show={this.state.loginForm || this.props.user.showAlert} onClick={this.showLoginForm} />
        <div id="menu" className="font_bold grey_font navBar menu_animation">
          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header">
                <button onClick={this.onOpen} type="button" id="hambuguer_btn" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a style={{ cursor: 'pointer' }} className="navbar-brand" href='logo' onClick={e => { e.preventDefault(); this.onclickHandler('/') }}><img alt='logo' src={logo} /></a>
              </div>
              <div id="navbar" className="navbar-collapse collapse">
                <ul className="nav navbar-nav">
                  <li className={this.isActive('/acerca-de')}><a className='menu_animation' href='elemento' onClick={e => { e.preventDefault(); this.onclickHandler('/acerca-de') }}>M68</a></li>
                  <li className={this.isActive('/coleccion')}><a className='menu_animation' href='elemento' onClick={e => { e.preventDefault(); this.onclickHandler('/coleccion') }}> COLECCIÓN</a></li>
                  <li className={this.isActive('/galeria')}><a className='menu_animation' href='elemento' onClick={e => { e.preventDefault(); this.onclickHandler('/galeria') }}>GALERÍA</a></li>
                  <li className={this.isActive('/visita')}><a className='menu_animation' href='elemento' onClick={e => { e.preventDefault(); this.onclickHandler('/visita') }}>VISITA</a></li>
                  <li className={this.isActive('/aprende')}><a className='menu_animation' href='elemento' onClick={e => { e.preventDefault(); this.onclickHandler('/aprende') }}>APRENDE</a></li>
                  <li><a href='elemento' className='menu_animation' onClick={e => { e.preventDefault(); this.showForm() }} title="contacto">CONTACTO</a></li>
                  {
                    !this.props.user.isLogged
                      ? <li><a className='movile-option menu_animation' href='elemento' onClick={e => { e.preventDefault(); this.showLoginForm() }}><span>INICIA SESIÓN</span></a></li>
                      : <li><a className="movile-option menu_animation" href='elemento' onClick={e => { e.preventDefault(); this.onclickHandler('/mi-coleccion') }}>MI COLECCIÓN <i className="icon icon-like-fill pink_font"></i></a></li>
                  }
                </ul>
                <ul className="nav navbar-nav navbar-right">
                  <li className="desktop-option rss_container" onMouseEnter={this.enterHover} onMouseLeave={this.leaveHover}>
                    {
                      !this.props.user.isLogged
                        ? <div className={`option-signin text_item`}><span onClick={this.showLoginForm}>INICIA SESIÓN</span></div>
                        : <a href='elemento' className={`my-collection-action btn_shadow ${this.props.user.isLogged ? 'no-after' : ''}`}
                          onClick={e => {
                            e.preventDefault()
                            if (this.props.user.isLogged) this.onclickHandler('/mi-coleccion')
                          }} title={this.props.user.isLogged ? 'mi colección' : ''}>
                          <i className={`icon icon-like-fill ${this.props.user.isLogged ? 'pink_font' : 'black_font'}`}></i>
                        </a>
                    }
                  </li>
                  <li>
                    <div className={`option-signin text_item`}><a rel="noopener noreferrer" target="_blank" href="http://eepurl.com/giGPvT">BOLETÍN</a></div>
                  </li>
                  <li className="rss_container">
                    <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/ccutlatelolco"><i className="icon icon-twitter"></i></a>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/CCUTlatelolco"><i className="icon icon-facebook"></i></a>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/ccutlatelolco/"><i className="icon icon-instagram"></i></a>
                    {
                      this.props.user.isLogged
                        ? <div className="option-signout btn_shadow_ hidden-option show"><span onClick={this.destroySession}>Cerrar Sesión</span></div>
                        : ''
                    }
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div id="white_screen" style={{ display: this.state.isOpen ? 'block' : 'none' }} ></div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(NavBar)
