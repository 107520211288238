import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { showAlert, setFav, removeFav } from '../../../redux/actions/user'

import { addFav, deleteFav } from '../../../services/userFavs'

import animations from '../../../utils/animations'

import logo from '../../../../assets/img/no-file.jpg'
import audio from '../../../../assets/img/audio_icon.png'
import doc from '../../../../assets/img/doc_icon.png'
import './MasonryItem.css'


/**
 * Clase controladora de elementos en la rejilla de objetos
 *
 * @class MasonryItem
 * @extends {React.Component}
 */
class MasonryItem extends React.Component {

  state = { isFav: false }


  /**
   * Manejador del botón de favoritos sobre un objeto
   *
   * @memberof MasonryItem
   */
  favouriteHandler = () => {
    if (this.props.user.isLogged) {
      if (this.state.isFav) {
        deleteFav(this.props.item.id)
          .then(res => {
            this.props.dispatch(removeFav(Number(this.props.item.id)))
            this.setState({ isFav: false })
            if (this.props.onDelete) this.props.onDelete()
          })
          .catch(err => console.log('Masonry error', err))
      } else {
        addFav(this.props.item.id)
          .then(res => {
            this.props.dispatch(setFav({ ...this.props.item, id: Number(this.props.item.id), available: true }))
            this.setState({ isFav: true })
          })
          .catch(err => console.log('Masonry error', err))
      }
    } else {
      this.props.dispatch(showAlert())
    }
  }


  /**
   * Controlador de ruta sobre objetos
   *
   * @memberof MasonryItem
   */
  onClickHandler = () => {
    animations.collection.out()

    this.props.onClickHandler()

    setTimeout(() => { this.props.history.push(`/coleccion/${this.props.item.id}`) }, 1200)
  }


  /**
   * Inyecta información adicional sobre el objeto
   *
   * @memberof MasonryItem
   */
  componentDidMount() {
    let isAvailable = this.props.user.userFavs.filter(fav => fav.id === this.props.item.id)
    isAvailable = isAvailable.length !== 0
      ? this.props.user.userFavs.filter(fav => fav.id === this.props.item.id)[0].available
      : true

    this.setState({ isFav: this.props.user.userFavs.filter(fav => fav.id === Number(this.props.item.id)).length !== 0, isAvailable })
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof MasonryItem
   */
  render() {
    let rowSpan = Math.random() > 0.5
      ? Math.random() < 0.5
        ? 'row-3'
        : 'row-1'
      : 'row-2'
    let columnSpan = Math.random() > 0.5
      ? Math.random() < 0.5
        ? 'column-3'
        : 'column-1'
      : 'column-2'

    let isImage = this.props.item.thumb === null ? '' : '_'
    let title = typeof this.props.item.title === 'string'
      ? this.props.item.title.split('||')[0]
      : 'No title'
      , author = this.props.deleteFav
        ? this.props.item.author[0]
        : this.props.item.author

    author = typeof author === 'string' ? author : 'No author'

    title = title.length > 60 ? `${title.substring(0, 57)}...` : title
    author = author.length > 100 ? `${author.substring(0, 97)}` : author

    let { isFav } = this.state

    return (
      <a href='elemento' onClick={e => e.preventDefault()}>
        <div className={`masonry-item ${rowSpan} ${columnSpan}`}>
          <div className={`masonry_object ${isImage === '' ? 'no_image' : ''}`}>
            <img className='img-responsive' alt={title} src={isImage === '' ? logo : this.props.item.thumb} />
            {
              isImage === '' &&
              <div className='no_image_title'>
                <div className='wrapper'>
                  <div className='content'>
                    <img className='img-responsive' style={{ margin: '0 auto' }} alt={title} src={this.props.item.file_extension ? audio : doc} />
                    <div className="title font_bold uppercase" style={{ marginTop: '10px', fontSize: '14px', textAlign: 'center' }}>{title}</div>
                  </div>
                </div>
              </div>
            }
            <div className="info_container collection_animation">
              <div className="wrapper">
                <div className="content text-center">
                  <div className="title font_bold uppercase">{title}</div>
                  <div className="subtitle uppercase">{author}</div>
                  {
                    this.state.isAvailable
                      ? <button onClick={this.onClickHandler} className="white_btn btn_shadow home_animation" type="submit"><span>ver más</span></button>
                      : null
                  }
                </div>
              </div>
              <div onClick={this.favouriteHandler} className='heart_btn'>
                {
                  this.props.deleteFav
                    ? <span className='icon'><i className="icon icon-like-fill"></i></span>
                    : <span className='icon'><i className={`icon ${isFav ? 'icon-like-fill' : 'icon-like'}`}></i></span>
                }
              </div>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withRouter(MasonryItem))