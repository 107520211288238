import { createStore, combineReducers } from 'redux'

import filtersReducer from './reducers/filters'
import dataReducer from './reducers/data'
import userReducer from './reducers/user'
import appReducer from './reducers/app'
import footerReducer from './reducers/footer'

const reducers = combineReducers({
  filters: filtersReducer,
  data: dataReducer,
  user: userReducer,
  app: appReducer,
  footer: footerReducer
})

// const store = createStore(reducers)

// devToolsExtension es un setup de configuración para poder examinar
// el flujo de estados desde devtools de Redux
const store = (window.devToolsExtension
  ? window.devToolsExtension()(createStore)
  : createStore)(reducers);

export default store
