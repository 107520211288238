
export const setAdvanceMode = (mode = false) => ({
  type: 'SET_ADVANCE_MODE',
  mode
})
export const setPage = (page = '0') => ({
  type: 'SET_PAGE',
  page
})
export const setDateRange = (dateRange = '0') => ({
  type: 'SET_DATE_RANGE',
  dateRange
})
export const setCollection = (collection = '0') => ({
  type: 'SET_COLLECTION',
  collection
})
export const setTextFilter = (text = '0') => ({
  type: 'SET_TEXT_FILTER',
  text
})
export const setTransversalApproach = (transversalApproach = '0') => ({
  type: 'SET_TRANSVERSAL_APPROACH',
  transversalApproach
})
export const setKeyword = (keyword = '0') => ({
  type: 'SET_KEYWORD',
  keyword
})
export const setLabel = (label = '0') => ({
  type: 'SET_LABEL',
  label
})

export const addGender = (gender = '') => ({
  type: 'ADD_GENDER',
  gender
})
export const removeGender = (gender = '') => ({
  type: 'REMOVE_GENDER',
  gender
})

export const addMedium = (medium = '') => ({
  type: 'ADD_MEDIUM',
  medium
})
export const removeMedium = (medium = '') => ({
  type: 'REMOVE_MEDIUM',
  medium
})
export const addTypology = (typology = '') => ({
  type: 'ADD_TYPOLOGY',
  typology
})
export const removeTypology = (typology = '') => ({
  type: 'REMOVE_TYPOLOGY',
  typology
})
export const addLang = (lang = '') => ({
  type: 'ADD_LANG',
  lang
})
export const removeLang = (lang = '') => ({
  type: 'REMOVE_LANG',
  lang
})
export const addTechnique = (technique = '') => ({
  type: 'ADD_TECHNIQUE',
  technique
})
export const removeTechnique = (technique = '') => ({
  type: 'REMOVE_TECHNIQUE',
  technique
})
export const addKeyword = (keyword = '') => ({
  type: 'ADD_kEYWORD',
  keyword
})
export const removeKeyword = (keyword = '') => ({
  type: 'REMOVE_kEYWORD',
  keyword
})

export const shouldDisplay = () => ({
  type: 'DISPLAY',
})
export const shouldHide = () => ({
  type: 'HIDE',
})

export const setFavText = (text = '0') => ({
  type: 'SET_FAV_TEXT',
  text
})


export const addAgentType = (agentType = '') => ({
  type: 'ADD_AGENT_TYPE',
  agentType
})
export const removeAgentType = (agentType = '') => ({
  type: 'REMOVE_AGENT_TYPE',
  agentType
})

export const addTitleType = (titleType = '') => ({
  type: 'ADD_TITLE_TYPE',
  titleType
})
export const removeTitleType = (titleType = '') => ({
  type: 'REMOVE_TITLE_TYPE',
  titleType
})

export const addAdvanceCollection = (advanceCollection = '') => ({
  type: 'ADD_ADVANCE_COLLECTION',
  advanceCollection
})
export const removeAdvanceCollection = (advanceCollection = '') => ({
  type: 'REMOVE_ADVANCE_COLLECTION',
  advanceCollection
})


export const addFund = (fund = '') => ({
  type: 'ADD_FUND',
  fund
})
export const removeFund = (fund = '') => ({
  type: 'REMOVE_FUND',
  fund
})

export const resetFilters = () => ({
  type: 'RESET_STATE',
})
