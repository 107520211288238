const validator = ({ field, name, value }) => {
  let error_field = 'campos requeridos'
  let errors = []
  switch (field) {
    case 'email': {
      // Se desactiva el siguiente warning por que detecta \ como un escape de caracter innecesario 
      //eslint-disable-next-line
      let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (value === '') errors.push({ name, message: error_field })
      if (emailRegex.exec(value) === null) errors.push({ name, message: 'ingresa un correo valido' })
      break
    }
    case 'password': {
      if (value === '') errors.push({ name, message: error_field })
      if (!isNaN(value)) errors.push({ name, message: 'La contraseña debe tener al menos una letra' })
      break
    }
    case 'username': {
      if (value === '') errors.push({ name, message: error_field })
      break
    }
    case 'lecture': {
      if (value === '') errors.push({ name, message: error_field })
      break
    }
    case 'activity': {
      if (value === '') errors.push({ name, message: error_field })
      break
    }
    case 'activityName': {
      if (value === '') errors.push({ name, message: error_field })
      break
    }
    case 'file': {
      if (value === null) errors.push({ name, message: error_field })
      break
    }
    default: return errors
  }
  return errors
}

export const isFormValid = (params, cb) => {
  let fields = Object.keys(params)
  fields = fields
    .map(field => ({ ...params[field], field }))
    .map(field => {
      return validator(field)[0]
    })
    .filter(errors => errors !== undefined && errors.length !== 0)
  cb(fields)
}
