import React from 'react'


/**
 * Componente stateless para la representación de los titulos
 *
 * @param {*} props
 */
const header = props => <div className="title_container">
  <h1 className="title main_title uppercase font_bold">{props.headers.title}</h1>
  <h3 className="subtitle pink_font uppercase font_bold">{props.headers.author}</h3>
</div>

export default header