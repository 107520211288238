import React from 'react'
import { authUserService } from '../../services/learn'
import { isFormValid } from '../../utils/fieldValidations'
import { withRouter } from 'react-router-dom'

import store from '../../redux/store'
import { authUser } from '../../redux/actions/user'


/**
 * Clase para controlar el formulario de login
 *
 * @class Login
 * @extends {React.Component}
 */
class Login extends React.Component {
  state = {
    email: '',
    password: '',
    warningFields: [],
    comesFrom: false
  }


  /**
   * Valida los datos en el formulario de login
   *
   * @memberof Login
   */
  validateFields = () => {
    let fields = {
      email: {
        name: 'correo electrónico',
        value: this.state.email
      },
      password: {
        name: 'contraseña',
        value: this.state.password
      }
    }
    isFormValid(fields, fields => {
      if (fields.length === 0) this.authUserHandler()
      else this.setState({ warningFields: fields })
    })
  }


  /**
   * Maneja el proceso de autenticación con el servidor
   *
   * @memberof Login
   */
  authUserHandler = () => {
    const { password, email } = this.state
    authUserService({ password, email })
      .then(res => {
        if (res.data.status === 200) {
          sessionStorage.setItem('0mZTFbxo1u', JSON.stringify({
            ui: res.data.id,
            ut: res.data.user_token,
            ur: res.data.user_role
          }))
          this.props.onChangeSectionHandler('success')
          store.dispatch(authUser({
            ui: res.data.id,
            ut: res.data.user_token,
            ur: res.data.user_role
          }))
          this.props.history.push('/mi-coleccion')
        } else {
          this.setState({ email: '', password: '', warningFields: [{ message: 'El usuario o password es incorrecto' }] })
        }
      })
      .catch(error => console.log('error', error))
  }


  /**
   * Pre-carga los valores en el formulario si existen
   *
   * @memberof Login
   */
  componentDidMount() {
    this.setState({
      email: this.props.user.email,
      password: this.props.user.password,
      comesFrom: this.props.user.email !== ''
    })
  }


  /**
   * Reinicia el estado de interfaz
   *
   * @param {*} nextProps
   * @memberof Login
   */
  componentWillReceiveProps(nextProps) {
    if (!nextProps.show) this.setState({ comesFrom: false })
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof Login
   */
  render() {
    return <div className="inner-login text-center">
      <h1 className="title font_bold uppercase text-center">Inicia sesión</h1>
      {
        this.props.showMessage
          ? <p className="required-account">Para poder guardar elementos primero tienes que iniciar sesión o registrate para tener una cuenta.</p>
          : ''
      }

      {
        this.state.comesFrom
          ? <p className="required-account">Tu cuenta se ha sido creada.</p>
          : null
      }

      <div className="title font_bold hidden">Ingresa tu datos para comenzar</div>
      <input
        className='btn_shadow awesome_input'
        value={this.state.email}
        onChange={e => { this.setState({ email: e.target.value }) }}
        placeholder='Usuario' />
      <input
        className='btn_shadow awesome_input'
        type='password'
        value={this.state.password}
        onChange={e => { this.setState({ password: e.target.value }) }}
        placeholder='Contraseña' />
      <button onClick={this.validateFields} className="pink_btn white-txt btn_shadow">
        <span>Entrar</span>
      </button>
      <h5 className='create-account grey_font'>¿No tienes cuenta? <span className="action-account pink_font" onClick={() => this.props.onChangeSectionHandler('newUser')}>Regístrate aquí</span></h5>
      <h5 className='create-account grey_font'>¿Olvidaste tu Contraseña? <span className="action-account pink_font" onClick={() => this.props.onChangeSectionHandler('forgot')}>Da click aquí</span></h5>
      {
        this.state.warningFields.map((warning, index) => <p className={`error error-${index}`} key={index}><small>{warning.message}</small></p>)
      }
    </div>
  }
}

export default withRouter(Login)
