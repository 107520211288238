import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

import animations from '../../utils/animations'
import { setTextFilter, resetFilters, setAdvanceMode } from '../../redux/actions/filters'

import logo from '../../../assets/img/logo_m68_small.svg'
import logoCiudadanias from '../../../assets/img/logo_ciudadanias.svg'
import logos_unam from '../../../assets/img/logos/logo_cultura-unam.png'

/**
 * Clase para la representación de la pantalla Home
 *
 * @class Home
 * @extends {React.Component}
 */
class Home extends React.Component {

  state = {
    search: '',
  }

  /**
   * Actualiza el valor del campo de busqueda
   *
   * @memberof Home
   */
  onTextChange = e => {
    this.setState({ search: e.target.value })
  }

  /**
   * Dispara el proceso de busqueda y redirecciona a la página de colección
   *
   * @memberof Home
   */
  onSearch = () => {
    animations.home.out()
    setTimeout(() => {
      this.props.dispatch(resetFilters())
      this.props.dispatch(setTextFilter(this.state.search === '' ? '0' : this.state.search))
      this.props.history.push('/coleccion')
    }, 1200)
  }
  /**
   * Redirecciona a la página de colección
   *
   * @memberof Home
   */
  goTo = () => {
    animations.home.out()
    setTimeout(() => {
      this.props.dispatch(setAdvanceMode())
      this.props.history.push('/coleccion')
    }, 1200)
  }
  /**
   * Dispara las animaciones cuando el componente se ha montado
   *
   * @memberof Home
   */
  componentDidMount() {
    ReactGA.pageview('/')
    document.title = 'm68 - ciudadanías en movimiento'
    this.setState({ search: this.props.filters.text === '0' ? '' : this.props.filters.text })
    animations.home.in()
  }
  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Home
   */
  render() {
    const { isLoading, footerLinkNames } = this.props.footer

    return <div id="home" className="section full_height">
      <div className="wrapper">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-5 col-sm-push-1">
                <div id="home_logo" className='home_animation'>
                  <div id="home_m68" className="home_animation">
                    <img alt='image1' className="img-responsive" src={logo} />
                  </div>
                  <div id="home_ciudadanias" className='home_animation'>
                    <img alt='image2' className="img-responsive " src={logoCiudadanias} />
                    <img alt='logo' style={{ display: 'none' }} src={logoCiudadanias} />
                  </div>
                </div>
                <div id="home_description" className='home_animation hidden'>
                  <span className="font_medium uppercase">La colección sobre movimientos sociales más grande de México</span>
                </div>
                <div id="home_search" className='home_animation'>
                  <div className="form">
                    <input
                      value={this.state.search}
                      onChange={this.onTextChange}
                      onKeyPress={e => { if (e.key === 'Enter') this.onSearch() }}
                      id="home_search_input"
                      className="search_input "
                      type="text"
                      placeholder="Buscar en la colección..." />
                    <button onClick={this.onSearch} id="home_search_btn" className='pink_btn btn_shadow home_animation search_icon_btn' type="submit">
                      <span><i className="icon icon-search"></i></span>
                    </button>
                  </div>
                </div>
                <div id="home_intro_text" className="home_animation ">
                  <span>Repositorio digital sobre los movimientos sociales que, desde 1968 a la fecha, han impulsado el reconocimiento de derechos en México. De consulta abierta y en permanente construcción, M68 es una iniciativa en la que colaboran múltiples archivos públicos y privados.</span>
                </div>
                <div id="home_all_collections_link" className="home_animation font_bold grey_font">
                  <a href="Búsqueda avanzada" onClick={e => { e.preventDefault(); this.goTo(); }}>Búsqueda avanzada <i className="icon icon-triangle-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        !isLoading &&
        <div id="home_footer" className="font-regular home_animation">
          <div className="container">
            <div className="row">
              <div className="col-sm-9">
                <div className="item">
                  <Link style={{ marginLeft: '10px' }} className="item" to="/creditos-y-agradecimientos">{footerLinkNames.acknowledgement}</Link>
                  <Link style={{ marginLeft: '10px' }} className="item" to="/legal">{footerLinkNames.legals}</Link>
                  <Link style={{ marginLeft: '10px' }} className="item" to="/terminos-y-condiciones">{footerLinkNames.terms}</Link>
                </div>
                <div className="item">
                  <img className="" src={logos_unam} alt='Logo UNAM' />
                </div>
              </div>
              <div className="col-sm-3">
                {/* <div className="aura grey_font">
                by  <a href="http://www.auracultura.com/" target="_blank"><img className="" src={aura_logo} /></a>
              </div> */}
              </div>
            </div>
          </div>

        </div>
      }
    </div>
  }
}

const mapStateToProps = state => ({
  filters: state.filters,
  footer: state.footer
})

export default connect(mapStateToProps)(Home)