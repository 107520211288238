import React from 'react'

/**
 * Component stateless para filtro palabras clave
 *
 * @param {Object} props
 */
const transversalApproachFilter = props => props.activeSearch === 'transversal'
  ? <React.Fragment>
    {
      props.filters.map(filter => <div key={filter.id} className="search_bar_col">
        <span onClick={() => props.addFilterHandler('transversal', filter.id)} className="search_item_link">{filter.name}</span>
      </div>)
    }
  </React.Fragment>
  : null

export default transversalApproachFilter