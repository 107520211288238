import React from 'react'
import Masonry from 'react-masonry-component'
import MasonryItem from './MasonryItem'

import './Masonry.css'


/**
 * Clase para manejar la rejilla de objetos
 *
 * @export
 * @class MasonryContainer
 * @extends {React.PureComponent}
 */
export default class MasonryContainer extends React.PureComponent {

  state = {
    mustShow: false,
    completed: false,
    resize: false
  }


  /**
   * Actualiza la interfaz cuando la carga de objetos termina
   *
   * @memberof MasonryContainer
   */
  onComplete = () => {
    this.setState({ completed: true })
    this.props.isLoaded()
  }


  /**
   * Actualiza la interfaz despues de 500 milisegundos
   *
   * @memberof MasonryContainer
   */
  componentDidMount() {
    setTimeout(() => this.setState({ mustShow: true }), 500)
  }


  /**
   * Modifica el estado de interfaz cuando se actualiza el modo de busqueda
   *
   * @param {*} nextProps
   * @memberof MasonryContainer
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.advanceSearch !== this.props.advanceSearch) {
      this.setState({ completed: false })
    }
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof MasonryContainer
   */
  render() {
    return this.state.mustShow
      ? <div className={`collection_animation masonry-container ${this.state.completed ? 'in' : ''}`}>
        <Masonry
          className='masonry'
          onLayoutComplete={this.onComplete}
        >
          {
            this.props.items.map(item => <MasonryItem onDelete={this.props.onDelete} deleteFav={this.props.deleteFav} onClickHandler={this.props.onClickHandler} key={`${item.id}-${Date.now()}`} item={item} />)
          }
        </Masonry>
      </div>
      : null
  }
}
