import React from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

import BasicInfo from './components/BasicInfo'
import ExtendedInfo from './components/ExtendedInfo'
import Header from './components/Header'
import MediaModal from './components/Modal'
import Loading from '../../baseComponents/Loading'

import { getObject } from '../../services/object'
import animations from '../../utils/animations'
// import MediaModal from '../../baseComponents/MediaModal/MediaModal'

import store from '../../redux/store'
import { setKeyword, setPage, setLabel, resetFilters } from '../../redux/actions/filters'
import { setItems } from '../../redux/actions/data'
import { addFav, deleteFav } from '../../services/userFavs'
import { showAlert, setFav, removeFav } from '../../redux/actions/user'

import audioThumb from '../../../assets/img/audio_thumb.jpg'
import fileThumb from '../../../assets/img/file_thumb.jpg'
import videoThumb from '../../../assets/img/video_thumb.png'
import fallbackThumb from '../../../assets/img/thumb_fallback.png'

import './Collection.css'

let $ = window.$


class Image extends React.Component {
  state = {
    src: this.props.images,
    className: this.props.className
  }

  render() {
    return <img
      onError={e => {
        console.log('error', this.state.src)
        this.setState({ src: fallbackThumb, className: `${this.props.className} no-shadow` })
      }}
      onClick={this.props.onClick}
      alt={this.props.alt}
      className={this.state.className}
      src={this.state.src} />
  }
}

/**
 * Clase para controlar la interfaz interna de un objeto de m68
 *
 * @class CollectionItem
 * @extends {React.Component}
 */
class CollectionItem extends React.Component {

  timers = []

  state = {
    object: {},
    singleContainer: false,
    showModal: false,
    mimeType: '',
    resource: '',
    position: 0,
    error: false
  }

  /**
   * Ejecuta la visualización en carrousel de imagenes
   *
   * @memberof CollectionItem
   */
  loadSlick = () => {
    let slides_num = $('.single_slide').length

    $(".slider_controls").removeClass("hidden")

    $('.single_slider').on('init', function (event, slick) {

      $(".single_slide").find("img").on("load", function () {
        if (($(this).height() < 300) || ($(this).width() < 400)) {
          $(this).parent().parent().parent().addClass("small_image")
        }
      }).error(function (err) {
        console.log("error load image", err)
        // $("#single").addClass("no_image");
      });

      if ($(".single_slide").find("img").length <= 0) {
        $("#single").addClass("no_image");
      }
      if ($(".single_slide").find("img").length === 1) {
        $(".slider_controls").addClass("hidden");
      }

    });

    $('.single_slider').slick({
      arrows: false,
      //autoplay: true,
      autoplaySpeed: 5000,

    });

    $("#slider_pager").find(".current_slide").empty().text(1);
    $("#slider_pager").find(".total_slides").empty().text(slides_num);

    $('.single_slider').on("afterChange", function (event, slick, currentSlide, nextSlide) {
      var currentSlide1 = $('.single_slider').slick('slickCurrentSlide');
      $("#slider_pager").find(".current_slide").empty().text(currentSlide1 + 1);
    });

    $("#slider_control_left").on("click", function () {
      $('.single_slider').slick('slickPrev');
    });
    $("#slider_control_right").on("click", function () {
      $('.single_slider').slick('slickNext');
    });
  }


  onClickHandler = () => {
    animations.single.out()
    this.timers[0] = setTimeout(() => {
      if (this.props.history.length > 2) this.props.history.goBack()
      else this.props.history.push('/coleccion')
    }, 1200)
  }


  /**
   * Dispara la visualización del modal de archivos multimedia
   *
   * @memberof CollectionItem
   */
  showMediaHandler = (media, mimeType, position) => {
    this.setState({
      resource: media,
      mimeType,
      showModal: true,
      position
    })
  }

  /**
   * Oculta el modal de archivos multimedia
   *
   * @memberof CollectionItem
   */
  onCloseHandler = () => {
    this.setState({
      showModal: false,
      resource: '',
      mimeType: '',
      position: 0
    })
  }


  /**
   * Actualiza el objeto seleccionado de relacionados
   *
   * @memberof CollectionItem
   */
  updateObject = id => {
    this.props.history.push(`/coleccion/${id}`)
  }


  /**
   * Reinicia los valores guardados en redux para palabras claves
   *
   * @param {String} keyword
   * @memberof CollectionItem
   */
  updateSearch = keyword => {
    animations.single.out()
    store.dispatch(setItems([]))
    store.dispatch(setPage(0))
    store.dispatch(setKeyword(keyword))
    this.props.history.push('/coleccion')
  }


  /**
   * Reinicia los valores guardados en redux para etiquetas
   *
   * @param {String} label
   * @memberof CollectionItem
   */
  updateSearchWithLabels = label => {
    store.dispatch(setItems([]))
    store.dispatch(setPage(0))
    store.dispatch(resetFilters())
    store.dispatch(setLabel(label))
    this.props.history.push('/coleccion')
  }

  /**
   * actualiza el valor de favorito sobre el objeto
   *
   * @memberof CollectionItem
   */
  favouriteHandler = () => {
    if (this.props.user.isLogged) {
      if (this.state.isFav) {
        deleteFav(this.props.match.params.id)
          .then(res => {
            this.setState({ isFav: false })
            this.props.dispatch(removeFav(Number(this.props.match.params.id)))
          })
          .catch(err => console.log('CollectionItem error', err))
      } else {
        addFav(this.props.match.params.id)
          .then(res => {
            this.setState({ isFav: true })
            this.props.dispatch(setFav({ ...this.state.object, id: Number(this.state.object.id), available: true }))
          })
          .catch(err => console.log('CollectionItem error', err))
      }
    } else {
      this.props.dispatch(showAlert())
    }
  }


  /**
   * Carga información inicial sobre el objeto
   *
   * @memberof CollectionItem
   */
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)
    animations.single.in()
    getObject(this.props.match.params.id)
      .then(res => {
        document.title = `${res.headers.title} - m68 - ciudadanías en movimiento`
        this.setState({
          isFav: this.props.user.userFavs.filter(fav => fav.id === Number(this.props.match.params.id)).length !== 0,
          object: {
            ...res,
            modal: res.modal.map(item => {
              let media = item.mime.split('/'), path = item.fullPath
              media = media[0]
              /* AUDIO */
              let images = audioThumb //'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Text-x-generic.svg/1024px-Text-x-generic.svg.png'

              if (item.thumbLarge !== null) {
                images = item.thumbLarge.replace(/ /g, '%20')
                images = images.replace(/\.png\.jpg/g, '.png')
              }
              if (media === 'image') {
                path = images
              }
              if (media === 'application' && item.mime !== 'application/pdf') {
                images = fileThumb
              }

              return {
                fullPath: path,
                mime: item.mime,
                s3Path: item.s3Path,
                thumb: item.thumb,
                thumbLarge: images,
                origin: res.comesFrom
              }
            }),
            modalFiles: []
          }
        })
        animations.single.in()
        this.loadSlick()
      })
      .catch(err => {
        animations.single.in()
        console.log('error', err)
        this.setState({ error: err })
      })
  }

  /**
   * Actualiza la información cuando la url del objeto cambia
   *
   * @param {Object} nextProps
   * @param {Object} nextState
   * @memberof CollectionItem
   */
  componentWillReceiveProps(nextProps, nextState) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      ReactGA.pageview(this.props.location.pathname)
      this.setState({ object: {} })
      getObject(nextProps.match.params.id)
        .then(res => {
          document.title = `${res.headers.title} - m68 - ciudadanías en movimiento`
          this.setState({
            isFav: this.props.user.userFavs.filter(fav => fav.id === Number(this.props.match.params.id)).length !== 0,
            object: {
              ...res,
              modal: res.modal.map(item => {
                let media = item.mime.split('/'), path = item.fullPath
                media = media[0]

                let images = audioThumb //'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Text-x-generic.svg/1024px-Text-x-generic.svg.png'

                if (item.thumbLarge !== null) {
                  images = item.thumbLarge.replace(/ /g, '%20')
                  images = images.replace(/\.png\.jpg/g, '.png')
                }
                if (media === 'image') {
                  path = images
                }

                if (media === 'application' && item.mime !== 'application/pdf') {
                  images = fileThumb
                }

                return {
                  fullPath: path,
                  mime: item.mime,
                  s3Path: item.s3Path,
                  thumb: item.thumb,
                  thumbLarge: images,
                  origin: res.comesFrom
                }
              }),
              modalFiles: []
            }
          })
          animations.single.in()
          this.loadSlick()
        })
        .catch(err => console.error(err))
    }
  }

  /**
   * Limpia subscripciones antes de eliminar el componente
   *
   * @memberof CollectionItem
   */
  componentWillMount() {
    this.timers.map(timer => clearTimeout(timer))
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof CollectionItem
   */
  render() {
    let url = window.location.href
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/home?status=${url}`;
    // const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`

    if (!this.state.object.id && !this.state.error) return <Loading />

    return this.state.object.id !== undefined
      ? <React.Fragment>
        <MediaModal
          mustShow={this.state.showModal}
          mimeType={this.state.mimeType}
          resource={this.state.resource}
          index={this.state.position}
          files={this.state.object.modal}
          onClose={this.onCloseHandler}
        />
        <div id="single" className='section section_padding collection_animation'>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-sm-9 col-md-9 col-lg-10">
                <div className="back_container">
                  <div onClick={this.onClickHandler} id="close_object" className="back_btn font_medium">
                    <i className="icon icon-chevron-left"></i> regresar
                  </div>
                </div>
                <Header headers={this.state.object.headers} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-9 col-md-9 col-lg-10">
                <div id="single_slider_container">
                  <div id="single_info_slider" className="single_slider ">
                    {
                      this.state.object.modal !== undefined && this.state.object.modal.length > 0 &&
                      this.state.object.modal.map((image, index) => {
                        let media = image.mime.split('/')
                        media = media[0]

                        let images = fileThumb //'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Text-x-generic.svg/1024px-Text-x-generic.svg.png'

                        if (image.thumbLarge !== null) {
                          images = image.thumbLarge.replace(/ /g, '%20')
                          images = images.replace(/\.png\.jpg/g, '.png')
                        }
                        let videoClass = ''
                        if (image.mime === "video/mp4")
                          videoClass = true

                        return <div key={image.s3Path} className="single_slide">
                          <div className="wrapper">
                            <div className="content">
                              <Image
                                onClick={() => this.showMediaHandler(image.fullPath, media, index)}
                                alt={image.fullPath}
                                images={images}
                                className={`slider_image_height ${videoClass} `} />
                              <img onClick={() => this.showMediaHandler(image.fullPath, media, index)} alt={image.fullPath} className="video_thumb" style={{ display: videoClass ? 'block' : 'none' }} src={videoThumb} />
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
                {
                  this.state.object.modal !== undefined && this.state.object.modal.length > 1
                    ? <div className="slider_controls hidden">
                      <div id="slider_control_left" className="slider_control"> <i className="icon icon-triangle-left"></i> </div>
                      <div id="slider_pager" className="slider_control"><span className="current_slide">1</span>/<span className="total_slides">7</span></div>
                      <div id="slider_control_right" className="slider_control"> <i className="icon icon-triangle-right"></i> </div>
                    </div>
                    : null
                }
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span className='icon'>
                      <i className={`icon ${this.state.isFav ? 'icon-like-fill' : 'icon-like'}`} style={{ fontSize: '25px' }}></i>
                    </span>
                    <a style={{ display: 'flex', flexDirection: 'column' }} className='card' href={`https://plataforma.m68.mx/api/dublinCore/${this.state.object.id}`} target='_blank' rel='noopener noreferrer'>
                      <span className='icon' style={{ fontSize: '25px', display: 'inline-block', verticalAlign: 'top', lineHeight: '20px' }}>
                        <i className='icon icon-download-line' style={{ fontSize: '20px' }}></i>
                      </span>
                      <span>XML</span>
                    </a>
                  </div>

                  <div style={{ float: 'right' }}>
                    <span className='title font_bold' style={{ marginRight: '10px', fontSize: '15px', display: 'inline-block', verticalAlign: 'middle' }}>
                      Compartir
                    </span>
                    <a className='card' href={twitterUrl} target='_blank' rel='noopener noreferrer'>
                      <span className='icon' style={{ marginRight: '10px', fontSize: '25px', display: 'inline-block', verticalAlign: 'middle' }}>
                        <i className={`icon icon-twitter`} style={{ fontSize: '20px' }}></i>
                      </span>
                    </a>
                    <a className='card' href={facebookUrl} target='_blank' rel='noopener noreferrer'>
                      <span className='icon' style={{ fontSize: '25px', display: 'inline-block', verticalAlign: 'middle' }}>
                        <i className='icon icon-facebook' style={{ fontSize: '20px' }}></i>
                      </span>
                    </a>
                  </div>
                </div>
                {
                  /*
                  <div>
                  <span className='icon'>
                    <i className={`icon ${this.state.isFav ? 'icon-like-fill' : 'icon-like'}`} style={{ fontSize: '25px' }}></i>
                  </span>
                  <div style={{ float: 'right' }}>
                    <span className='title font_bold' style={{ marginRight: '10px', fontSize: '15px', display: 'inline-block', verticalAlign: 'middle' }}>
                      Compartir
                    </span>
                    <a className='card' href={twitterUrl} target='_blank'>
                      <span className='icon' style={{ marginRight: '10px', fontSize: '25px', display: 'inline-block', verticalAlign: 'middle' }}>
                        <i className={`icon icon-twitter`} style={{ fontSize: '20px' }}></i>
                      </span>
                    </a>
                    <a className='card' href={facebookUrl} target='_blank'>
                      <span className='icon' style={{ fontSize: '25px', display: 'inline-block', verticalAlign: 'middle' }}>
                        <i className='icon icon-facebook' style={{ fontSize: '20px' }}></i>
                      </span>
                    </a>
                  </div>
                </div>  
                  */
                }

                <div className="row">
                  <div className="col-sm-8 col-sm-push-2">
                    <BasicInfo
                      headers={this.state.object.headers}
                      info={this.state.object.basicInfo}
                      onClick={this.onClickHandler}
                    />

                    <ExtendedInfo
                      info={this.state.object.extendedInfo}
                    />
                    <div className="description" style={{ color: '#777', marginBottom: '100px' }}>
                      Si tienes más información sobre este registro, escríbenos a <a href='mailto:museom68@unam.mx'>museom68@unam.mx</a>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-sm-3 col-md-3 col-lg-2">
                <div className="object_related_info">
                  {
                    this.state.object.keywords.length > 0 &&
                    <div className="info_block">
                      <div className="title font_bold uppercase">Palabras clave</div>
                      <div className="description">
                        {
                          this.state.object.keywords.map(keyword => <span onClick={() => this.updateSearch(keyword)} key={keyword} className="keyword">{keyword}</span>)
                        }
                      </div>
                    </div>
                  }

                  {
                    this.state.object.related.length > 0 &&
                    <div className="info_block">
                      <div className="title font_bold uppercase">También te puede interesar</div>
                      {
                        this.state.object.related.map(related => <div onClick={() => this.updateObject(related.id)} key={related.id} className="related_object">
                          <div className="related_image" style={{ backgroundImage: `url('${related.thumb.replace(/ /g, '%20')}')` }}></div>
                          <div className="title font_regular uppercase">
                            {related.first_title.length <= 70 ? related.first_title : `${related.first_title.substring(0, 67)}...`}
                          </div>
                        </div>)
                      }
                    </div>
                  }

                  {
                    this.state.object.labels.length > 0 &&
                    <div className="info_block">
                      <div className="title font_bold uppercase">Machine Learning</div>
                      <div className="description">
                        {
                          this.state.object.labels.map((label, index) => <span onClick={() => this.updateSearchWithLabels(label)} key={index} className="keyword">{label}</span>)
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      : this.state.error
        ? <div id="single" className='section section_padding collection_animation'>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-sm-8 col-sm-push-2">
                <div id="privacy_title" className='font_medium text-center privacy_animation'>
                  <h1 className="font_bold">Registro no encontrado</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(CollectionItem)