import React from 'react'

import './WatsonResponse.css'

/**
 * Componente stateless para renderizar un cuadro de mensaje
 *
 * @param {Object} props
 * @returns {HTMLObjectElement}
 */
const watsonResponse = props => {
  switch (props.data.type) {
    case 'text':
      if (props.data.message) {
        let isLink = props.data.message.search('@:') !== -1
        if (isLink) {
          let name = props.data.message.split('|')[0].substring(2)
            , id = props.data.message.split('|')[1]
          return <div onClick={() => props.history.push(`/coleccion/${id}`)} className='chat-block'>
            <p className='chat-message letf-message' key={`${name}`}>{name}</p>
          </div>
        }
      }
      return <div className='chat-block'>
        <p className='chat-message letf-message' key={`${props.data.message || props.data.text}`}>{props.data.message || props.data.text}</p>
      </div>

    case 'option':
      return <div className='chat-block '>
        <div className='chat-message letf-message' key={`${props.data.message}`}>
          <p>{props.data.title}</p>
          {
            props.data.options
              .map(option => <div
                className={`option ${props.isLast ? 'option-active' : ''}`}
                disabled={!props.isLast}
                key={option.label}
                onClick={() => { props.onOptionClick(option.value) }}
              >{option.label}</div>)
          }
        </div>

      </div>
    case 'pause': return null
    default:
      return <p>Upss, algo salió mal</p>
  }
}

export default watsonResponse