import React from 'react'
import { connect } from 'react-redux'

/**
 * Da formato a números 
 *
 * @param {Number} number
 * @returns {String} - número con formato
 */
const numberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')


/**
 * Obtiene el elemento para el filtro de archivo (en modo avanzado)
 *
 * @param {*} collections
 * @param {*} id
 * @returns
 */
const getCollectionItem = (collections, id) => {
  let collection = collections
    .map(c => c.data.filter(f => Number(f.id) === Number(id)))
    .filter(c => c.length !== 0)
  return collection[0][0]
}

/**
 * Despliega información sobre filtros aplicados y número de objetos
 *
 * @param {*} props
 * @returns
 */
const infobar = props => {
  let dates = ''

  if (props.filters.dateRange !== '0') {
    dates = props.filters.dateRange.split('_')
    dates = dates[0] === dates[1]
      ? dates[0]
      : `${dates[0]} a ${dates[1]}`
  }

  let collections = props.data.filterData.collections
    .map(item => item.data)
    .reduce((prev, next) => {
      return [...prev, ...next]
    })

  let filters = [
    {
      type: 'text',
      value: props.filters.text === '0' ? '' : props.filters.text,
    },
    {
      type: 'dateRange',
      value: dates,
    },
    {
      type: 'collection',
      value: props.filters.collection === '0'
        ? ''
        : collections.filter(filter => Number(filter.id) === Number(props.filters.collection))[0].name,
    },
    {
      type: 'keyword',
      value: props.filters.keyword === '0'
        ? ''
        : props.filters.keyword,
    },
    {
      type: 'transversalApproach',
      value: props.filters.transversalApproach === '0'
        ? ''
        : props.data.filterData.transversalApproaches.filter(filter => Number(filter.id) === Number(props.filters.transversalApproach))[0].name,
    },
    {
      type: 'label',
      value: props.filters.label === '0'
        ? ''
        : props.filters.label
    }
  ]
    .filter(input => input.value !== '')
    .map(input => <span onClick={() => { props.reset(input.type) }} style={{ marginLeft: '10px' }} key={input.type}>{input.value}</span>)

  let isData = filters.length > 0 ? 'relacionandos con ' : ''

  let advanceFilters = [
    {
      type: 'text',
      value: props.filters.text === '0'
        ? ''
        : <span onClick={() => { props.reset('text', props.filters.text) }} style={{ marginLeft: '10px' }}>{props.filters.text}</span>,
    },
    {
      value: props.filters.genders.length === 0
        ? ''
        : props.filters.genders
          .map(filter => props.data.filterData.genders.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('genders', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.titleType.length === 0
        ? ''
        : props.filters.titleType
          .map(filter => props.data.filterData.titleType.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('titleType', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.mediums.length === 0
        ? ''
        : props.filters.mediums
          .map(filter => props.data.filterData.mediums.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('mediums', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.typologies.length === 0
        ? ''
        : props.filters.typologies
          .map(filter => props.data.filterData.typologies.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('typologies', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.languages.length === 0
        ? ''
        : props.filters.languages
          .map(filter => props.data.filterData.languages.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('languages', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.techniques.length === 0
        ? ''
        : props.filters.techniques
          .map(filter => props.data.filterData.techniques.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('techniques', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.thematicCoverage.length === 0
        ? ''
        : props.filters.thematicCoverage
          .map(filter => props.data.filterData.keywords.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('thematicCoverage', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.agentType.length === 0
        ? ''
        : props.filters.agentType
          .map(filter => props.data.filterData.agentType.filter(f => Number(f.id) === Number(filter))[0])
          .map(input => <span onClick={() => { props.reset('agentType', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.advanceCollection.length === 0
        ? ''
        : props.filters.advanceCollection
          .map(filter => getCollectionItem(props.data.filterData.collections, filter))
          .map(input => <span onClick={() => { props.reset('advanceCollection', input.id) }} style={{ marginLeft: '10px' }} key={input.id}>{input.name}</span>)
    },
    {
      value: props.filters.funds.length === 0
        ? ''
        : props.filters.funds
          .map(input => <span onClick={() => { props.reset('funds', input) }} style={{ marginLeft: '10px' }} key={input}>{input}</span>)
    },
    {
      type: 'dateRange',
      value: dates === ''
        ? dates
        : props.filters.shouldDisplay
          ? <span onClick={() => { props.reset('dateRange') }} style={{ marginLeft: '10px' }} key={dates}>{dates}</span>
          : ''
    },
  ]

  return <div className="submenu">
    {
      !props.filters.advanceMode
        ? <p>{numberWithCommas(props.amount)} registros {isData} {filters}</p>
        : <p>
          {numberWithCommas(props.amount)} registros
          {advanceFilters[0].value}
          {advanceFilters[1].value}
          {advanceFilters[2].value}
          {advanceFilters[3].value}
          {advanceFilters[4].value}
          {advanceFilters[5].value}
          {advanceFilters[6].value}
          {advanceFilters[7].value}
          {advanceFilters[8].value}
          {advanceFilters[9].value}
          {advanceFilters[10].value}
          {advanceFilters[11].value}
        </p>
    }
  </div>
}
const mapStateToProps = state => ({
  filters: state.filters,
  data: state.data
})
export default connect(mapStateToProps)(infobar)