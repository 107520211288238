import React from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'

/**
 * Component stateless para filtro de fecha
 *
 * @param {Object} props
 */
const dateRangeFilter = props => props.activeSearch === 'date'
  ? <React.Fragment>
    <div className="search_bar_range">
      <div className="search_bar_year_col year_range_container">
        <InputRange
          allowSameValues={true}
          maxValue={(new Date()).getFullYear()}
          minValue={1960}
          value={props.date}
          onChange={value => props.onDateChange(value)}
          onChangeStart={props.onChangeStart} />
      </div>
      <div className="search_bar_year_col year_button">
        <button id="" className="pink_btn btn_shadow" onClick={props.onSearch} >
          <span>Aplicar</span>
        </button>
      </div>
    </div>

  </React.Fragment>
  : null

export default dateRangeFilter