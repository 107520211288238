import axios from './instance'
import store from '../redux/store'

import { getFavs } from './userFavs'

const getUI = () => {
  const { ui } = store.getState().user
  if (ui === 0) {
    let user = sessionStorage.getItem('0mZTFbxo1u')
    if (user !== null) {
      user = JSON.parse(user)
      return user.ui
    }
  }
  return ui
}

export const getPaginated = async (search_type, pageIndex = null) => {
  const {
    advanceMode,
    collection, dateRange, keyword, page, text, transversalApproach, label,
    genders, mediums, typologies, languages, techniques, thematicCoverage,
    agentType, funds, advanceCollection, titleType,
  } = store.getState().filters

  const { isLogged, userFavs } = store.getState().user

  let favs = userFavs
  if (getUI() !== 0 && isLogged === false) {
    favs = await getFavs()
  }

  let filteredKeyword = keyword.split('/')[0]

  let currentPage = pageIndex !== null ? pageIndex : page

  if (advanceMode) {
    let formData = new FormData()
      , gender = genders.length ? genders : ['0']
      , medium = mediums.length ? mediums : ['0']
      , typology = typologies.length ? typologies : ['0']
      , language = languages.length ? languages : ['0']
      , technique = techniques.length ? techniques : ['0']
      , thematicCoverageS = thematicCoverage.length ? thematicCoverage : ['0']
      , agentTypeFilter = agentType.length ? agentType : ['0']
      , fund = funds.length ? funds : ['0']
      , advanceCollectionFilter = advanceCollection.length ? advanceCollection : ['0']
      , titleTypeFilter = titleType.length ? titleType : ['0']

    titleTypeFilter.forEach(gender => formData.append('meta_title[]', gender))
    gender.forEach(gender => formData.append('gender[]', gender))
    medium.forEach(gender => formData.append('medium[]', gender))
    typology.forEach(gender => formData.append('tipology[]', gender))
    language.forEach(gender => formData.append('language[]', gender))
    technique.forEach(gender => formData.append('techniques[]', gender))
    thematicCoverageS.forEach(gender => formData.append('keyword[]', gender))
    agentTypeFilter.forEach(gender => formData.append('agent_type[]', gender))
    fund.forEach(gender => formData.append('fondo[]', gender))
    advanceCollectionFilter.forEach(gender => formData.append('collection[]', gender))
    formData.append('date_range', dateRange)
    formData.append('num_items', 30)
    formData.append('page', currentPage)
    formData.append('search_txt', text)
    formData.append('search_type', search_type)
    formData.append('label', '0')
    formData.append('transversal_approach[]', '0')
    formData.append('topics[]', '0')

    return axios.post('/advanced_search', formData)
      .then(res => {
        let items = Object.keys(res.data.data_list)
        items = items.map(item => res.data.data_list[item])
        return {
          amountItems: res.data.total_objects,
          pages: res.data.total_pages,
          items: items.map(item => {
            let title = item.first_title === null ? '' : item.first_title
            let author = item.first_author === null
              ? ''
              : typeof item.first_author === 'string' && (item.first_author.toUpperCase() === 'NO IDENTIFICADO')
                ? ''
                : item.first_author

            return {
              id: item.id,
              thumb: item.thumb,
              hasThumnail: item.thumb === null,
              author,
              title,
              isFav: favs.filter(fav => fav.id === item.id).length,
              file_extension: item.file_extension
            }
          })
        }
      })
  } else {
    let formData = new FormData()

    // filter_objects/${dateRange}/${page}/30/${collection}/${text}/${transversalApproach}/${filteredKeyword}/${label}

    formData.append('date_range', dateRange)
    formData.append('page', currentPage)
    formData.append('num_items', 30)
    formData.append('collection[]', collection)
    formData.append('search_txt', text)
    formData.append('transversal_approach[]', transversalApproach)
    formData.append('search_type', 'full')
    formData.append('label', label)
    formData.append('meta_title[]', '0')
    formData.append('gender[]', '0')
    formData.append('medium[]', '0')
    formData.append('tipology[]', '0')
    formData.append('language[]', '0')
    formData.append('techniques[]', '0')
    formData.append('keyword[]', '0')
    formData.append('agent_type[]', '0')
    formData.append('fondo[]', '0')
    formData.append('topics[]', filteredKeyword)

    return axios.post('/advanced_search', formData)
      .then(res => {
        let items = Object.keys(res.data.data_list)
        items = items.map(item => res.data.data_list[item])
        return {
          amountItems: res.data.total_objects,
          pages: res.data.total_pages,
          items: items.map(item => {
            let title = item.first_title === null ? '' : item.first_title
            let author = item.first_author === null
              ? ''
              : typeof item.first_author === 'string' && (item.first_author.toUpperCase() === 'NO IDENTIFICADO')
                ? ''
                : item.first_author

            return {
              id: item.id,
              thumb: item.thumb,
              hasThumnail: item.thumb === null,
              author,
              title,
              isFav: favs.filter(fav => fav.id === item.id).length,
              file_extension: item.file_extension
            }
          })
        }
      })
  }
}