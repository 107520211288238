import axios from './instance'
import store from '../redux/store'

const getUI = () => {
  const { ui } = store.getState().user
  if (ui === 0) {
    let user = sessionStorage.getItem('0mZTFbxo1u')
    if (user !== null) {
      user = JSON.parse(user)
      return user.ui
    }
  }
  return ui
}

const getObject = id => {
  return axios.get(`/returnObject/${id}`)
    .then(res => ({ ...res.data[0], id: id }))
}

export const getFavs = params => {
  const { favText } = store.getState().filters
  const ui = getUI()
  return axios.get(`/getFavorites/${ui}/${favText}`)
    .then(async (res) => {
      let objects = res.data.map(id => getObject(id.id_objeto))
      objects = await Promise.all(objects)
      return objects.map(obj => {
        let available = res.data.filter(data => data.id_objeto === obj.id)[0].available
        return { ...obj, available, isFav: true }
      })
    })
    .then(res => res.map(obj => obj))
}

export const getCollection = params => {
  return axios.get('/cms/expos')
    .then(async (res) => {
      let collection = res.data
      collection = collection[collection.length - 1]
      if (collection) {
        let objects = collection.collection
          .split(',')
          .map(id => getObject(id))
        objects = await Promise.all(objects)

        objects = objects.filter(obj => typeof obj.title === 'string')

        return {
          ...collection,
          collection: objects
        }
      }
      else {
        return {
          title: 'Sin contenido',
          description: '',
          collection: []
        }
      }
    })
}

export const addFav = id => {
  const ui = getUI()
  return axios.post('/addFavorite', { id_user: ui, id_objeto: id, available: 1 })
    .then(res => {
      return res
    })
}

export const deleteFav = id => {
  const ui = getUI()
  return axios.get(`/delFavorite/${ui}/${id}`)
    .then(res => {
      return res
    })
}