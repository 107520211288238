import React from 'react'
import ReactDOM from 'react-dom'

const modalRoot = document.getElementById('modal-portal')

class Modal extends React.Component {

  componentWillUnmount() {
    modalRoot.style.display = 'none'
  }

  render() {
    let { isVisible = false, onClickOverlay = () => null } = this.props
    modalRoot.style.display = isVisible ? 'flex' : 'none'

    modalRoot.onclick = e => {
      if (e.target.id === 'modal-portal') onClickOverlay()
    }

    return ReactDOM.createPortal(
      this.props.children,
      modalRoot,
    )
  }
}

export default Modal