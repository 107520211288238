import React from 'react'
import Draggable from 'react-draggable'
import './ImageContainer.css'

/**
 * Clase para controlar la visualización de imagenes
 *
 * @export
 * @class ImageContainer
 * @extends {React.Component}
 */
export default class ImageContainer extends React.Component {

  state = {
    dimensions: {},
    zoom: 1,
    currentImgZoom: 1,
    zoomLevel: 0.5,
    boundsY: 0,
    boundsX: 0,
    rotationAxis: 0,
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,

    panBottom: 0,
    panLeft: 0,
    panRight: 0,
    panTop: 0,
    draggableOn: true,
  }

  onImgLoad = ({ target: img }) => {
    let image = new Image()

    image.onload = () => {
      this.setState({
        dimensions: {
          height: image.height,
          width: image.width
        }
      })
    }
    image.src = img.src
  }

  zoomIn = () => {
    this.setState(prevState => ({ zoom: prevState.zoom + 1 }), this.calculate_bounds)
  }

  zoomOut = () => {
    if (this.state.zoom > 1) this.setState(prevState => ({ zoom: prevState.zoom - 1 }), this.calculate_bounds)
    else {
      this.reset()
      this.reset_draggable()
      this.calculate_bounds()
    }
  }

  reset() {
    this.setState({ rotationAxis: 0, currentImgZoom: 1 })
    this.calculate_bounds();
  }

  reset_draggable() {
    this.setState(prevState => ({ draggableOn: !prevState.draggableOn }))
  }

  calculate_bounds() {
    const image = new Image()
    let { left, top } = this.panContainer.getBoundingClientRect()

    image.src = this.props.src
    image.onload = () => {
      if (image.height < image.width) {
        this.setState({
          bottom: Math.floor((top * this.state.zoom) / 2),
          left: -Math.floor((left * this.state.zoom) - (50 * (this.state.zoom - 2))),
          right: Math.floor((left * this.state.zoom) - (50 * (this.state.zoom - 2))),
          top: -Math.floor((top * this.state.zoom) / 2)
        })
      } else {
        this.setState({
          bottom: Math.floor((top * this.state.zoom)),
          left: -Math.floor((left * this.state.zoom) - (50 * (this.state.zoom - 2))),
          right: Math.floor((left * this.state.zoom) - (50 * (this.state.zoom - 2))),
          top: -Math.floor((top * this.state.zoom))
        })
      }
    }
  }

  componentDidMount() {
    this.calculate_bounds()
  }

  render() {
    const { width, height } = this.state.dimensions
    let classes = width > height ? 'large' : 'tall'

    return <React.Fragment>
      <div style={{ textAlign: 'center', padding: '10px', }}>
        <span onClick={this.zoomIn} className="zoom_in modal-icon " aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="zoom in" data-original-title="Acercar">
          <i style={{ fontSize: '30px', lineHeight: '30px' }} className="pink_font icon icon-zoomin"></i>
        </span>
        <span onClick={this.zoomOut} className="zoom_in modal-icon " aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="zoom in" data-original-title="Acercar">
          <i style={{ fontSize: '30px', lineHeight: '30px' }} className="pink_font icon icon-zoomout"></i>
        </span>
      </div>
      <div ref={input => { this.panContainer = input }} style={{ overflow: 'hidden' }}>
        <div id='zoom-container-div' className="zoom-container" style={{ transform: `scale(${this.state.zoom})`, transition: "0.2s" }}>
          <Draggable axis="both"
            bounds={
              this.state.zoom === 1
                ? { bottom: 0, left: 0, right: 0, top: 0 }
                :
                {
                  bottom: (this.state.bottom),
                  left: (this.state.left),
                  right: (this.state.right),
                  top: (this.state.top)
                }
            }
            defaultPosition={{ x: this.state.panRight, y: this.state.bottom }}>
            <div className="handle" style={this.props.currentImgZoom === 1 ? { transform: `translate(${this.props.dragPosition}px, ${this.props.dragPosition}px)` } : ''}>
              <div className="bounds" ref={(input) => { this.imageContainer = input; }}>
                <div className='image_tools-container'>
                  <img onLoad={this.onImgLoad} className={` img ${classes}`} src={this.props.src} alt={this.props.src} />
                </div>
              </div>
            </div>
          </Draggable>
        </div>
      </div>
    </React.Fragment>
  }
}











/*
import React from 'react'
import Draggable from 'react-draggable'
import './ImageContainer.css'

export default class ImageHandler extends React.Component {

  // crecer los bounds conforme crece la imagen.
  // Siempre son del tamañio de la imagen
  // Si crece la iamgen crecen los bounds

  constructor(props) {
    super(props);
    this.zoom_in = this.zoom_in.bind(this);
    this.zoom_out = this.zoom_out.bind(this);
    this.myRef = React.createRef();
    this.rotate_left = this.rotate_left.bind(this);
    this.rotate_right = this.rotate_right.bind(this);
    this.reset = this.reset.bind(this);
    this.calculate_bounds = this.calculate_bounds.bind(this);
    this.reset_draggable = this.reset_draggable.bind(this);
    this.state = {







}

zoom_in() {
  if (this.props.currentImgZoom < 3) {
    this.setState({ currentImgZoom: this.props.currentImgZoom + this.props.zoomLevel });
    this.calculate_bounds();
  }
}

zoom_out() {
  if (this.props.currentImgZoom > 1) {
    this.setState({ currentImgZoom: this.props.currentImgZoom - this.props.zoomLevel });
    this.calculate_bounds();
    }
}

  tate_left() {
  this.setState({ rotationAxis: this.props.rotationAxis - 90 });
    this.calculate_bounds();
}

  tate_right() {
  this.setState({ rotationAxis: this.props.rotationAxis + 90 })
    this.calculate_bounds();
}

reset() {
    this.setState({ rotationAxis: 0, currentImgZoom: 1 })
  this.calculate_bounds();
  }

  set_draggable() {
    is.setState(prevState => ({ draggableOn: !prevState.draggableOn }));
  }

  lculate_bounds() {

      t image = new Image()
    t widthImg = 0
    , heightImg = 0

    age.src = this.props.currentImage
    age.onload = () => {
    let withCon = this.panContainer.offsetWidth
        , heightCon = this.panContainer.offsetHeight
    widthImg = image.width > withCon ? withCon : image.width
      ightImg = image.height > heightCon ? heightCon : image.height

       Dimensiones de Marco
      t frameTop = this.panContainer.getBoundingClientRect().top
    let frameBottom = this.panContainer.getBoundingClientRect().bottom

    this.setState({
        bottom: Math.floor(heightImg / 16),
      left: -Math.floor(widthImg / 8),
      right: Math.floor(widthImg / 8),
      top: -Math.floor(heightImg / 16)
      })
  }


      entDidMount() {
        alculate_bounds();
  }

  render() {
    return (
      <div className="main-carousel">
        <div className="pan-container" ref={(input) => { this.panContainer = input; }}>
          <div className="reference">

            {/*MANEJA EL ZOOM *}
            <ZoomContainer style={{ position: 'relative' }} zoom={this.props.currentImgZoom}>
              {console.log('[ImageHandler] movements =>', this.props.movements)}
              {/*MANEJA EL Arrastre y el tope en la iamgen dentro de un marco (bounds) *}
              <Draggable axis="both"
                bounds={
                  this.props.currentImgZoom == 1
                    ? {
                      bottom: 0,
                      left: 0,
                      right: 0,
                      top: 0
                    }
                    :
                    {
                      bottom: (this.state.bottom * this.props.currentImgZoom),
                      left: (this.state.left * this.props.currentImgZoom),
                      right: (this.state.right * this.props.currentImgZoom),
                      top: (this.state.top * this.props.currentImgZoom)
                    }
                }
                defaultPosition={{ x: this.state.panRight, y: this.state.bottom }}>
                <div className="handle" style={this.props.currentImgZoom == 1 ? { transform: `translate(${this.props.dragPosition}px, ${this.props.dragPosition}px)` } : ''}>
                  {/*MANEJA La Rotación de la imagen *}
                  <RotateContainer rotationAxis={this.props.rotationAxis}>
                    <div className="bounds" ref={(input) => { this.imageContainer = input; }}>
                      <ActiveImage
                        image={this.props.currentImage}
                        movements={this.props.movements} />
                    </div>
                  </RotateContainer>
                </div>
              </Draggable>
            </ZoomContainer>
          </div>
        </div>


      </div>


    )
  }
}


const RotateContainer = (props) => (
  <div className="rotate_container"
    style={{ transform: `rotate(${props.rotationAxis}deg)`, transition: "0.2s" }}>
    {props.children}
  </div>
);


const ZoomContainer = (props) => (
  <div className="zoom-container"
    style={{ transform: `scale(${props.zoom})`, transition: "0.2s" }}>
    {props.children}
  </div>
)


class ActiveImage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="image_tools-container">
        <img
          alt={this.props.image + this.props.movements}
          className="img-responsive"
          src={this.props.image} />
      </div>
    )
  }

}
*/