import React from 'react'

import './VideoContainer.css'

/**
 * Componente stateless para la representación del visor de videos
 *
 * @param {*} props
 * @returns
 */
const videoContainer = props => {
  let url = props.src.replace('.jpg', '')
  return <div className="embed-responsive embed-responsive-16by9">
    <video controls>
      <source src={url} type="video/mp4" />
      Your browser does not support the video tag.
  </video>
  </div>
}

export default videoContainer