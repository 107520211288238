import React from 'react'
import ReactGA from 'react-ga'

import { getActivities } from '../../services/learn'

import Lectures from './components/Lectures'
import Glossary from './components/Glossary'
import Teacher from './components/Teacher'

import animations from '../../utils/animations'

import title_learn from '../../../assets/img/titles/mediacion_title.svg'

import learn_theme from '../../../assets/img/virtual_classroom/01_theme_icon.png'
import learn_glossary from '../../../assets/img/virtual_classroom/02_glossary_icon.png'
import learn_design from '../../../assets/img/virtual_classroom/03_design_icon.png'
import { connect } from 'react-redux'
import Modal from '../../baseComponents/Modal'
import StarIcon from './components/StarIcon'
import { rateLesson } from '../../services/activities'

const stars = [1, 2, 3, 4, 5]


/**
 * Clase para controlar la interfaz de la sección Aprende
 *
 * @export
 * @class Learn
 * @extends {React.Component}
 */
class Learn extends React.Component {

  state = {
    activity: null,
    section: 'none',
    activities: {
      studentActivism: [],
      studentActivismOnline: [],
      socialActivism: [],
      socialActivismOnline: []
    }
  }


  /**
   * Carga la información sobre las actividades disponibles
   *
   * @memberof Learn
   */
  componentDidMount() {
    animations.learn.in()
    ReactGA.pageview(this.props.location.pathname)
    this.getLectures()
  }

  /**
   * Obtiene las actividades del servidor
   *
   * @memberof Learn
   */
  getLectures = () => {
    getActivities()
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          this.setState({
            activities: {
              elemetarySchool: res.data.filter(activism => activism.topic.search('Primaria') !== -1),
              highSchool: res.data.filter(activism => activism.topic.search('Secundaria') !== -1),
              highSchool2: res.data.filter(activism => activism.topic.search('Preparatoria') !== -1),
              university: res.data.filter(activism => activism.topic.search('Universidad') !== -1),
            }
          })
        }
      })
      .catch(error => console.log(error))
  }
  //.filter(activism => activism.activity.search('en el memorial') !== -1),
  /**
   * Actualiza las interfaz visible
   *
   * @memberof Learn
   */
  selectSecctionHandler = sectionName => {
    this.setState({ section: sectionName })
  }

  /**
   * Recibe una lectura para mostrar un pop-up
   *
   * @memberof Learn
   */
  onLectureRank = activity => {
    this.setState({ activity })
  }

  onSetRank = value => {
    let { activity } = this.state
    console.log(activity.id, value, activity.rating)
    rateLesson(activity.id, value, activity.rating)
      .then(res => {
        return getActivities()
      })
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          this.setState({
            activity: null,
            activities: {
              elemetarySchool: res.data.filter(activism => activism.topic.search('Primaria') !== -1),
              highSchool: res.data.filter(activism => activism.topic.search('Secundaria') !== -1),
              highSchool2: res.data.filter(activism => activism.topic.search('Preparatoria') !== -1),
              university: res.data.filter(activism => activism.topic.search('Universidad') !== -1),
            }
          })
        }
      })
      .catch(err => {
        this.setState({ activity: null })
      })
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Learn
   */
  render() {
    let { activities, activity } = this.state
    return <div id="learn" className="section section_padding learn_animation">
      {
        activity
          ? <Modal isVisible={true} onClickOverlay={() => { this.setState({ activity: null }) }}>
            <div onClick={e => e.stopPropagation()} className='rank-container'>
              <h2>¿QUE TE PARECIÓ LA CLASE?</h2>
              <p>Ayúdanos a calificar la clase para seguir subiendo contenido interesante para ti.</p>
              <div className='rank-stars'>
                {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} onClick={this.onSetRank} />)}
              </div>
            </div>
          </Modal>
          : null
      }
      <div id="learn_title" className="container">
        <div className="row">
          <div className="col-sm-12">
            <div id="learn_title" className='font_medium text-center visit_animation'>
              <img style={{ width: '80%', maxWidth: '500px' }} className="img-responsive center-block" alt="Visita" src={title_learn} />
            </div>
            <div id="learn_subtitle" className='description text-center visit_animation'>
              <p className="subtitle pink_font font_medium uppercase">
                Conoce el programa para los distintos niveles educativos
                </p>
            </div>
          </div>
        </div>
      </div>
      <div id="learn_content" className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="virtual_classroom_container">

              <div id="virtual_classroom_menu">

                <div className="row">

                  <div className="vcenter virtual_classroom_block col-xs-4">
                    <div className="virtual_classroom_option text-center">
                      <h2>Elige tu clase</h2>
                      <img className="img-responsive center-block" src={learn_theme} alt="Tema" />
                      <button onClick={() => this.selectSecctionHandler('lectures')} className="white_btn btn_shadow"><span>Ver más</span>s</button>
                    </div>
                  </div>
                  <div className="vcenter virtual_classroom_block col-xs-4">
                    <div className="virtual_classroom_option text-center">
                      <h2>Decide tu experiencia</h2>
                      <img className="img-responsive center-block" src={learn_glossary} alt="Experiencia" />
                      <button onClick={() => this.selectSecctionHandler('glossary')} className="white_btn btn_shadow"><span>Ver más</span></button>
                    </div>
                  </div>
                  <div className="vcenter virtual_classroom_block col-xs-4">
                    <div className="virtual_classroom_option text-center">
                      <h2>Diseña tu clase</h2>
                      <img className="img-responsive center-block" src={learn_design} alt="Actividad" />
                      <button onClick={() => this.selectSecctionHandler('teacher')} className="white_btn btn_shadow"><span>Ver más</span></button>
                      {
                        // <button className="white_btn btn_shadow disabled" disabled><span>Próximamente</span></button>
                      }
                    </div>
                  </div>
                </div>

              </div>
              {
                this.state.section === 'lectures'
                  ? <Lectures activities={activities} onLectureRank={this.onLectureRank} />
                  : this.state.section === 'glossary'
                    ? <Glossary items={this.props.app.glosary} words={this.props.app.biblio_sources} />
                    : this.state.section === 'teacher'
                      ? <Teacher />
                      : null
              }

            </div>

          </div>
        </div>
        {
          /*
          
          <div className="row">
          <div className="col-sm-8 col-sm-push-2">
            {
              /**
              <h3 className="big_title grey_font font_medium">Introducción al área</h3>
              <p className="title uppercase font_medium">Educación</p>

              <p className="title font_medium">¿Qué es la Mediación Educativa?</p>
              <p>
                El área de Mediación Educativa es un espacio de investigación, diseño y aplicación de proyectos para vincular al público con los acervos, las exposiciones permanentes y temporales del Centro Cultural Universitario Tlatelolco.
              </p>
              <p>
                Busca ser un espacio que posibilite la interacción y construcción social e individual del visitante encaminado hacia el empoderamiento ciudadano. El área ofrece una experiencia que en la relación del público con los contenidos expositivos y acervos involucre el pensamiento crítico y la posibilidad de reflexionar, expresarse y conocerse a partir de diversas actividades educativas diseñadas para estos fines.
              </p>
              *
            }
            <h3 className="big_title font_medium">Mediación</h3>

            <img className="learn_image img-responsive center-block" src={learn_00} />

            <p className="title font_medium">Profesores y alumnos </p>
            <p>
              La visita al M68 ofrece experiencias generadoras de aprendizajes en diferentes ramas del conocimiento para los alumnos de cualquier nivel académico, a través de un recorrido dinámico e interactivo.
            </p>
            <p>
              Para reflexionar y discutir sobre los temas abordados en el museo realizamos talleres después del recorrido por la sala, mismos que ayudan a los estudiantes a relacionar el contenido museístico con una experiencia plástico reflexiva, propiciando nuevos aprendizajes desde un horizonte diferente.
            </p>
            <img className="learn_image img-responsive center-block" src={learn_01} />

            <p className="title font_medium">Nivel básico y nivel medio</p>
            <p>
              Todas las visitas están diseñadas de acuerdo al nivel educativo de los escolares y se acompañan de  objetivos transversales de los planes de estudio por nivel.
            </p>

            <p className="title font_medium">Material complementario</p>
            <p>
              Para nosotros es importante que la experiencia en el museo no sólo se quede dentro de este espacio, por eso proponemos a los profesores dos guías didácticas que les pueden ayudar a trabajar con sus alumnos temáticas relacionadas con las exposiciones, estas guías proponen actividades para antes y después de venir al Centro Cultural Universitario Tlatelolco.
            </p>

            <p className="title font_medium">El  museo va a tu prepa</p>
            <p>
              Mediación Educativa cuenta con mini-exposiciones portátiles que son llevadas al patio de las escuelas de nivel medio, el objetivo de esta actividad es aprovechar el tiempo libre de los alumnos para dar a conocer el contexto del Memorial del 68 mediante temáticas contraculturales que de manera reflexiva, lúdica e interactiva los acerquen al tema.
            </p>
            <p>
              A pesar de encontrarnos en un espacio de educación formal nuestro interés también radica en mostrar a los jóvenes que los museos pueden ser espacios desvinculados de la obligatoriedad formativa y en ellos podemos comprender cosas del pasado que pueden ser atractivas y útiles para comprendernos en nuestro contexto más cercano.
            </p>
            <img className="learn_image img-responsive center-block" src={learn_02} />
            <p>
              Contamos con diversos diseños de Miniexpo + Taller que podemos dinamizar en las preparatorias:
            </p>
            <ul>
              <li>Comic Underground en los años sesenta</li>
              <li>El 68 a través de Rogelio Naranjo</li>
              <li>Carteles psicodélicos, conciertos y música de los sesenta</li>
              <li>El arte en los discos de acetato de los sesenta</li>
            </ul>

            <p className="title font_medium">Memoria ambulante</p>
            <p>
              Visitamos tu escuela secundaria.
            </p>
            <img className="learn_image img-responsive center-block" src={learn_03} />
            <p>
              Visitamos las escuelas secundarias de la zona aledaña al CCUT para, a través del Movimiento Estudiantil del 68, reflexionar sobre las problemáticas actuales que enfrentan los jóvenes mediante temas como identidad, derechos humanos y género.
            </p>

            <p className="title font_medium">Visitamos tu escuela preparatoria.</p>
            <img className="learn_image img-responsive center-block" src={learn_04} />
            <p>
              Visitamos las escuelas de nivel medio de toda la ciudad y área metropolitana para construir un pensamiento crítico en torno a la ciudadanía a través de la reflexión sobre el Movimiento estudiantil de 1968.
            </p>
            <p>
              Más informes: publicos.tlatelolco@gmail.com
            </p>
            <p className="title font_medium">Nivel licenciatura</p>
            <p>
              Para los estudiantes de licenciatura hemos diseñado diversos recorridos de acuerdo a su formación y sus intereses:
            </p>
            <p>
              Arquitectura y espacio público en los años sesenta
            </p>
            <p>
              Recorrido por la exposición que contextualiza el concepto de modernidad de los años sesenta en México y recorrido por la Unidad Habitacional Nonoalco-Tlatelolco que profundiza en el diseño arquitectónico de Mario Pani y el concepto fundador de esta ciudad dentro de una ciudad.
            </p>

            <img className="learn_image img-responsive center-block" src={learn_05} />

            <p>
              Pedagogía y materias de educación y divulgación
            </p>
            <p>
              Charla introductoria sobre la labor educativa de los museos y del área de Mediación Educativa del CCUT, acompañada de un recorrido por el espacio expositivo para estudiar cómo trabajar en el museo diversas propuestas críticas en relación con la educación formal y la formación de ciudadanía.
            </p>

            <img className="learn_image img-responsive center-block" src={learn_06} />

            <p>
              Historia, ciencias políticas, comunicación y afines
            </p>
            <p>
              Recorridos que pueden concentrarse en un tema político o histórico particular de la exposición para comprender contextos sociales específicos.
            </p>
            <p>
              Si desean profundizar en alguna temática particular en la exposición pueden solicitarla por el correo electrónico publicos.tlatelolco@gmail.com
            </p>
            <p>
              Actividades complementarias para nivel licenciatura
            </p>
            <p>
              Medios de comunicación ayer y hoy. Construcción de artículo periodístico.<br />
              Poesía para la memoria. Taller de poesía.<br />
              Cuerpo territorio de libertad. Reflexión a partir de poesía sobre el cuerpo.<br />
              Pancartas políticas. Tú desapareces, yo desaparezco. Discusión y definición de problemas políticos.
            </p>
            <p className="title font_medium">Actividades para profesores</p>
            <p>
              Curso-taller: “El museo, espacio de discusión, reflexión y construcción de identidad”
            </p>
            <img className="learn_image img-responsive center-block" src={learn_07} />
            <p>
              La actividad consta de una charla de parte del área de Mediación Educativa donde se dan a conocer las estrategias para acercar a los públicos escolares al M68, con el objetivo de generar una experiencia significativa de discusión y reflexión sobre la identidad, la memoria y el presente. Se lleva a cabo un recorrido por sala para mostrar algunas de las actividades que realizamos con los jóvenes en las distintas exposiciones. Finalmente se diseña una propuesta por parte de los profesores para compartir un material didáctico sobre el museo en nuestra Aula Virtual.
            </p>
            <p>
              Duración: 3 horas.<br />
              Se puede agendar para un mínimo de 10 profesores.
            </p>
            <p>
              Laboratorio de construcción de recursos de mediación
            </p>
            <p>
              Espacio destinado a desarrollar recursos de mediación a partir de un diálogo entre profesores y museo. En este curso desarrollaremos materiales didácticos y guiones que nos ayudarán a acercar a los estudiantes  a los contenidos expositivos y acervos del museo.<br />
              Estos materiales formarán parte del Aula Virtual del nuevo M68.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-sm-push-2">
            <div className="faqs_container">
              <h3 className="big_title font_medium">Preguntas Frecuentes</h3>

              <p className="title font_medium">¿Cómo agendo mi visita?</p>
              <p>
                Sólo debes escribir a publicos.tlatelolco@gmail.com y dar los siguientes datos:<br />
                Exposición que desean visitar, fecha, hora que desean la visita, cantidad de alumnos, grado académico y especificar, de ser necesario, si desean profundizar en un tema de la exposición en particular. Se te responderá inmediatamente y se te informará si tenemos disponibilidad para esa fecha.
              </p>
              <p>
                Debes escribir mínimo una semana antes.
              </p>
              <p>
                El cupo máximo son 80 visitantes al mismo tiempo, si desean que se atienda un grupo más grande deben agendar con un mes de anticipación para poder coordinar dicha actividad.
              </p>

              <p className="title font_medium">¿Cuál es el costo de las actividades de mediación?</p>
              <p>
                Se puede solicitar la entrada libre al museo al mismo correo donde solicites la visita. La visita guiada no tiene costo. Todas las actividades complementarias no tienen costo extra e incluyen materiales.
              </p>
              <p className="title font_medium">¿Con qué servicios contamos?</p>
              <ul>
                <li>Tenemos estacionamiento para autos y para autobuses escolares.</li>
                <li>Contamos con guardarropa para que los estudiantes dejen sus mochilas.</li>
                <li>Contamos con un área para que los visitantes tomen su refrigerio y una pequeña cafetería, favor de organizarse con el área de mediación vía correo electrónico para darles un tiempo a los visitantes para ingerir alimentos de ser necesario.</li>
              </ul>
            </div>
          </div>
        </div>
      
          */
        }
      </div>
    </div>
  }
}
const mapStateTopProps = state => ({
  app: state.app
})
export default connect(mapStateTopProps)(Learn)