import axios from './instance'

const getInfo = id => {
  return axios.get(`/returnObject/${id}`)
    .then(async (res) => {
      const data = res.data[0]
        , titles = data.title.split('||').filter((_, i) => i !== 0)
        , author = data.author[0].toLowerCase()

      let authors = data.author

      let metaAgent = await axios.get('get_catalog/vc_agent')
      let agent = data.meta_author
        ? data.meta_author
          .split('||')
          .map(agent => {
            let ag = metaAgent.data.filter(meta => Number(meta.id) === Number(agent))
            ag = ag.length === 0 ? '' : ag[0].name
            return ag
          })
        : []

      let statementOfUse = await axios.get('get_catalog/vc_statements_of_use')
        , metaStatementOfUse = data.meta_statements_of_use
          ? statementOfUse.data
            .map(statement => {
              return statement.id === data.meta_statements_of_use ? statement.name : null
            })
            .filter(statement => statement !== null)
          : null

      let isValidString = false

      if (data.commented_sheet !== null) isValidString = (data.commented_sheet.trim()).length > 0

      let images = data.files.viewables === undefined ? [] : data.files.viewables
        , files = data.files.others === undefined ? [] : data.files.others

      let modalFiles = [...images, ...files]

      authors = authors
        .map((author, index) => agent[index] ? `${author} (${agent[index]}).` : author)
        .map(author => author.length ? author : null)
        .filter(author => author !== null)

      let howTo = authors.map(author => author.replace('.', '')).join(', ')
      let year = (data.publish_date === 'null' || !Boolean(data.publish_date)) ? '' : `, year ${data.publish_date}`
      //let fondo = !Boolean(data.fondo) ? '' : `, fondo ${data.fondo}` version 1
      let fondo = !Boolean(data.fondo) ? '' : `, ${data.fondo}`
      howTo = `${howTo}, <span class="oblique">${data.title.split('||')[0]}</span> ${year} ${fondo}, ${data.meta_origin_collection[0]} Recuperado de ${window.location.href}`

      const inputs = {
        id: id,
        thumb: data.thumb,
        headers: {
          title: data.title.split('||')[0],
          author: author.replace('no identificado', '')
        },
        basicInfo: [
          {
            type: 'multiple',
            title: 'Otros títulos ',
            value: titles
          }, {
            type: 'multiple',
            title: 'Agentes',
            value: authors
          },
          {
            type: 'multiple',
            title: 'Lugar de creación',
            value: data.creation_place !== null && data.creation_place !== '' ? data.creation_place.split('||') : null
          },
          {
            type: 'single',
            title: 'Fecha creación',
            value: data.creation_date
          },
          {
            type: 'single',
            title: 'fecha de publicación',
            value: data.publish_date === 'null' ? null : data.publish_date
          },
          {
            type: 'single',
            title: 'Publicado por',
            value: data.publisher
          },
          {
            type: 'single',
            title: 'Descripción física',
            value: data.elementos
          },
          {
            type: 'multiple',
            title: 'Soporte',
            value: data.meta_format
          },
          {
            type: 'multiple',
            title: 'Dimensiones',
            value: data.measures !== null && data.measures !== '' ? data.measures.split('||') : null
          },
          {
            type: 'single',
            title: 'Institución de procedencia',
            value: data.meta_origin_collection[0]
          },
          {
            type: 'single',
            title: 'Colección y/o fondo',
            value: data.fondo
          },
          {
            type: 'single',
            title: 'Cómo citar',
            value: howTo,
            isHTML: true
          },
          {
            type: 'multiple',
            title: 'Procedencia',
            value: typeof data.donator === 'string' && data.donator.length ? data.donator.split('||') : null
          },
          {
            type: 'multiple',
            title: 'Declaraciones de uso',
            value: metaStatementOfUse
          }
        ],
        extendedInfo: [
          {
            type: 'single',
            title: 'Nota general',
            value: data.description === '' ? null : data.description
          },
          {
            type: 'single',
            title: 'Resumen y comentarios',
            value: data.commented_sheet !== '' && isValidString ? data.commented_sheet : null
          },
          {
            type: 'single',
            title: 'Formato del recurso',
            value: data.meta_medium === '' ? null : data.meta_medium
          },
          {
            type: 'single',
            title: 'Tipo de obra',
            value: data.meta_gender === '' ? null : data.meta_gender
          },
          {
            type: 'single',
            title: 'Ámbito',
            value: data.meta_tipology === '' ? null : data.meta_tipology
          },
          {
            type: 'multiple',
            title: 'Enfoque transversal',
            value: data.meta_transversal_approach === '' ? null : data.meta_transversal_approach
          },
          {
            type: 'single',
            title: 'Palabras clave',
            value: null
          },
          {
            type: 'single',
            title: 'Número de registro en colección de origen',
            value: data.register_origin_collection_num === '' ? null : data.register_origin_collection_num
          },
          {
            type: 'single',
            title: 'Ubicación topográfica',
            value: data.topographical_ubication === '' ? null : data.topographical_ubication
          },
        ],
        keywords: [...data.topics, ...data.meta_meta_keywords].filter(item => item !== '' && item !== null),
        images: images,
        files: files,
        modal: modalFiles,
        comesFrom: data.meta_origin_collection[0]
      }
      inputs.basicInfo = inputs.basicInfo.filter(input => input.type === 'multiple'
        ? input.value !== null && input.value.length > 0
        : input.value !== null && input.value !== '')

      inputs.extendedInfo = inputs.extendedInfo.filter(input => input.type === 'multiple'
        ? input.value !== null && input.value.length > 0
        : input.value !== null && input.value !== '')
      return inputs
    })
}

const getRelated = (id, data) => {
  return axios.get(`/getRelated/${id}`)
    .then(res => {
      let related = Object.keys(res.data)
      let objectData = { ...data }
      objectData.related = related
        .map(rel => res.data[rel])
        .filter(item => {
          let imgExt = item.thumb !== null ? item.thumb.split('.') : ['tiff']
          imgExt = imgExt[imgExt.length - 1]
          return item.thumb !== null && imgExt !== 'tiff' && item.id !== 19710
        })
        .filter((item, index) => index < 4 && Number(item.id) !== Number(id))
      return objectData
    })
}

const getImageLabels = (id, data) => {
  return axios.get(`/objectLabels/${id}`)
    .then(res => {
      let labels = [...res.data].map(label => label.label)
        , temp = []

      for (let index = 0; index < labels.length; index++) {
        let exists = temp.filter(label => label === labels[index])
        if (exists.length === 0) temp.push(labels[index])
      }
      return { ...data, labels: temp.filter(l => l !== null) }
    })
}


export const getObject = id => {
  return getInfo(id)
    .then(res => { return getRelated(id, res) })
    .then(res => { return getImageLabels(id, res) })
}