import React from 'react'
import axios from '../../../../services/instance'


/**
 * Clase para manejar el formulario de contacto
 *
 * @export
 * @class Contact
 * @extends {React.Component}
 */
export default class Contact extends React.Component {

  state = {
    name: '',
    email: '',
    title: '',
    message: '',
    hasError: false,
    sent: false,
    inAnimation: false,
    hidden: true
  }


  /**
   * actualiza el estado del input asignado
   *
   * @memberof Contact
   */
  onChange = (type, message) => {
    this.setState({ [type]: message.target.value })
  }


  /**
   * envia los datos del formulario al servidor
   *
   * @memberof Contact
   */
  onSend = () => {
    let { name, email, title, message } = this.state

    axios.get(`/send/email/${name}/${title}/${email}/${message}`)
      .then(response => {
        this.setState({
          name: '',
          email: '',
          title: '',
          message: '',
          hasError: false,
          sent: true,
          inAnimation: false,
          hidden: true
        })
      })
      .catch(error => {
        console.log(error)
      })
  }


  /**
   * valida que los input del formulario cumplan los requisitos minimos para activar el botón
   *
   * @memberof Contact
   */
  isEnable = () => {
    // Se desactiva el siguiente warning por que detecta \ como un escape de caracter innecesario 
    //eslint-disable-next-line
    let regexEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return this.state.title === '' &&
      this.state.email === '' &&
      this.state.name === '' &&
      this.state.message === '' &&
      this.state.email.match(regexEmail) === null

  }

  /**
   * Modifica los valores del formulario si el prop show es modificado
   *
   * @param {*} nextProps
   * @memberof Contact
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.setState({
        hidden: false
      })
      setTimeout(() => { this.setState({ inAnimation: 100 }) }, 100)
    } else {
      this.setState({
        name: '',
        email: '',
        title: '',
        message: '',
        hasError: false,
        sent: false,
        inAnimation: false,
        hidden: true
      })
    }
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Contact
   */
  render() {
    let enable = this.isEnable()
    let hidden = this.state.hidden ? 'hidden' : ''
    let inAnimation = this.state.inAnimation ? 'in' : ''

    return <div onClick={this.props.onClick} id="contact" className={`popup_block full_height contact_animation  ${inAnimation} ${hidden}`}>
      <div className="wrapper">
        <div className="content">
          <div className="popup_container" >
            <div onClick={e => e.stopPropagation()} className="popup_content">

              <div onClick={this.props.onClick} className="close_btn"><i className="icon icon-times"></i></div>

              <h1 className="title font_bold uppercase text-center">Contáctanos</h1>
              <p className="description">5117 2818 ext. 49691 y 49697<br /> Av. Ricardo Flores Magón 1. Tlatelolco, 06995 Cuauhtémoc, CDMX</p>

              {
                !this.state.sent
                  ? <div className="form font_regular">
                    <input id="contact_name" className="message_input " type="text" placeholder="Nombre"
                      value={this.state.name}
                      onChange={e => this.onChange('name', e)}
                    />
                    <input id="contact_mail" className="message_input " type="text" placeholder="Correo"
                      value={this.state.email}
                      onChange={e => this.onChange('email', e)}
                    />
                    <input id="contact_title" className="message_input " type="text" placeholder="Asunto"
                      value={this.state.title}
                      onChange={e => this.onChange('title', e)}
                    />
                    <span className="subtitle">Mensaje</span>
                    <textarea rows="4" cols="50"
                      value={this.state.message}
                      onChange={e => this.onChange('message', e)}
                    ></textarea>
                    <div className="text-center">
                      <button onClick={this.onSend} disabled={enable} id="contact_send_btn" className='pink_btn btn_shadow home_animation ' type="submit">
                        <span>Enviar</span>
                      </button>
                    </div>
                  </div>
                  : <React.Fragment>
                    <p>Mensaje enviado.</p>
                    <p>Pronto nos pondremos en contacto contigo</p>
                  </React.Fragment>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  }
}