import axios from './instance'

export const authUserService = params => {
  return axios.post('/loginSite', params)
}

export const createUser = params => {
  return axios.post('/users', params)
}

export const askForPassword = email => {
  return axios.get(`/user_ask_password/${email}`)
}

export const isTokenValid = token => {
  return axios.get(`/check_token_psw/${token}`)
}

export const newPassword = params => {
  return axios.post('/regenerate_password', params)
}

export const createActivity = params => {
  return axios({
    method: 'post',
    url: '/createLesson?filedoc',
    data: params,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const getActivities = () => {
  return axios.get('/lessons/0/0/0')
    .then(res => ({
      ...res,
      data: res.data.filter(item => item.status === 1)
    }))
}