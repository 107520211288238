import React from 'react'

/**
 * Componente stateless para la representación de la información adicional
 *
 * @param {*} props
 * @returns
 */
const extendedInfo = props => {
  return <div className="extended_info">
    {
      props.info.map(itemInfo => {
        const fullBlock = itemInfo.type === 'single' && itemInfo.value.length > 200 ? 'info_block_full' : ''
        // Se desactiva el siguiente warning por que detecta \ como un escape de caracter innecesario 
        //eslint-disable-next-line
        let regex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
          , hasMatch = null

        if (itemInfo.type === 'single' && itemInfo.value.match(regex) !== null) hasMatch = itemInfo.value.match(regex)[0]

        return <div key={itemInfo.title} className={`info_block ${fullBlock}`}>
          <div className="title font_bold uppercase">{itemInfo.title}</div>
          {
            itemInfo.type === 'single'
              ? itemInfo.value.match(regex) !== null
                ? <div className="description"><a target="_blank" rel="noopener noreferrer" href={hasMatch}>{itemInfo.value.split('\n').map((t, i) => <React.Fragment key={i}>
                  {t}
                  <br />
                </React.Fragment>)}</a></div>
                : <div className="description">{itemInfo.value.split('\n').map((t, i) => <React.Fragment key={i}>
                  {t}
                  <br />
                </React.Fragment>)}</div>
              : itemInfo.value.map(value => <div key={value} className="description">{value.split('\n').map((t, i) => <React.Fragment key={i}>
                {t}
                <br />
              </React.Fragment>)}</div>)
          }
        </div>
      })
    }
  </div>
}

export default extendedInfo