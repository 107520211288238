import React from 'react'
import { Redirect, Route } from 'react-router-dom'


/**
 * Validador de rutas privadas
 *
 * @param {*} props
 */
const privateRoute = props => <div>
  {
    sessionStorage.getItem('0mZTFbxo1u')
      ? <Route path={props.path} component={props.component} />
      : <Redirect to='/' />
  }
</div>

export default privateRoute