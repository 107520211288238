import React from 'react'
import { connect } from 'react-redux'

import 'react-input-range/lib/css/index.css'

import animations from '../../utils/animations'

import ErrorBoundary from '../../baseComponents/ErrorBoundary'
import Loading from '../../baseComponents/Loading'
import Masonry from '../../baseComponents/Masonry'
import ReactGA from 'react-ga'

import { getFavs } from '../../services/userFavs'

import { setFavText } from '../../redux/actions/filters'
import { setFavs } from '../../redux/actions/user'
import { getCollection } from '../../services/userFavs'

import myCollectionTitle from '../../../assets/img/titles/title_my_collection.png'


/**
 * Clase para controlar la interfaz de la sección mi colección
 *
 * @class MyCollection
 * @extends {React.Component}
 */
class MyCollection extends React.Component {
  timers = []

  state = {
    items: [],
    amountItems: 0,
    pages: 0,
    hasMansonryLoaded: false,
    isFetching: false,
    hasLoaded: false,
    emptyResult: false,
    advanceSearch: false,
    emptySearch: false,
    textFilter: '',
    collection: null,
  }


  /**
   * Dispara la busqueda de elementos sobre los favoritos del usuario
   *
   * @memberof MyCollection
   */
  onSearch = () => {
    this.setState({ objects: [], isFetching: true, emptySearch: false })
    getFavs()
      .then(items => {
        this.setState({ items, isFetching: false, hasLoaded: true, emptyResult: items.length === 0, emptySearch: items.length === 0 })
        this.props.dispatch(setFavs(items))
      })
      .catch(err => {
        this.setState({ isFetching: false })
        throw new Error(err)
      })
  }


  /**
   * Actualiza el estado de la rejilla de los favoritos
   *
   * @memberof MyCollection
   */
  masonryStatusChange = () => { this.setState({ hasMansonryLoaded: true }) }


  onClickHandler = () => { }


  /**
   * Reinicia el filtro de busqueda
   *
   * @memberof MyCollection
   */
  onSubmitSearch = () => {
    this.props.dispatch(setFavText(this.state.textFilter === '' ? '0' : this.state.textFilter))
    setTimeout(this.onSearch, 500)
  }


  /**
   * Carga los favoritos del usuario
   *
   * @memberof MyCollection
   */
  componentDidMount() {
    animations.collection.in()
    ReactGA.pageview(this.props.location.pathname)
    document.title = 'Mi colecciones - m68 - ciudadanías en movimiento'
    getCollection()
      .then(res => { this.setState({ collection: res }) })
      .catch(err => console.log(err))
    setTimeout(() => {
      getFavs()
        .then(items => {
          animations.collection.in()
          this.setState({ items, isFetching: false, hasLoaded: true, emptyResult: items.length === 0 })
          this.props.dispatch(setFavs(items))
        })
        .catch(err => {
          this.setState({ isFetching: false })
          throw new Error(err)
        })
    }, 1000)
    window.addEventListener('scroll', this.loadOnScroll)
  }


  /**
   * Limpia las subscripciones antes de que el componente se elimine
   *
   * @memberof MyCollection
   */
  componentWillUnmount() {
    this.timers.map(timer => clearTimeout(timer))
    window.removeEventListener('scroll', this.loadOnScroll)
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof MyCollection
   */
  render() {
    return <ErrorBoundary>
      {
        this.state.hasLoaded
          ? <div id="collection" className='section collection_animation section_padding'>
            <div className="container-fluid">
              <div className="row">
                <div className="limit-advance col-sm-2">
                  <div id="objects_filter">
                    <div className="row no_margin">
                      <div className="col-sm-12 no_padding">
                        <table style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td>
                                <div className="form">
                                  <input
                                    value={this.state.textFilter}
                                    className="search_input"
                                    onChange={e => { this.setState({ textFilter: e.target.value }) }}
                                    onSubmit={() => { if (this.state.textFilter.length > 3) this.onSubmitSearch() }}
                                    style={{ width: '100%' }}
                                    type="text"
                                    placeholder="Buscar en tu colección ..." />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="search_advanced_button row">
                      <div className="col-xs-12 col-sm-6 text-center">
                        <button className="pink_btn btn_shadow" onClick={() => { if (this.state.textFilter.length > 3) this.onSubmitSearch() }}>
                          <span>Buscar</span>
                        </button>
                      </div>
                      <div className="col-xs-12 col-sm-6 text-center">
                        <button className="white_btn btn_shadow" onClick={() => { this.setState({ textFilter: '' }, this.onSubmitSearch) }}>
                          <span>Limpiar</span>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='calc-advance col-sm-10'>
                  {
                    /*
                    this.state.collection
                      ? <div>
                        <h3 className="title font-bold mycollection-title">
                          {this.state.collection.title}
                        </h3>
                        <h5 className="mycollection-title">
                          {this.state.collection.description}
                        </h5>
                        <Masonry
                          deleteFav
                          onDelete={this.onSearch}
                          advanceSearch={this.props.filters.advanceMode}
                          isLoaded={this.masonryStatusChange}
                          items={this.state.collection.collection}
                          onClickHandler={this.onClickHandler} />
                      </div>
                      : null
                      */
                  }

                  <div id="objects_container">
                    <h4 className="mycollection-title">
                      <img className="img-responsive" src={myCollectionTitle} alt="mi colección" />
                    </h4>
                    {
                      this.state.emptyResult
                        ? <div className="col-sm-12 text-center spinner">
                          {
                            this.state.emptySearch
                              ? <h4 className="mycollection-message">Aún no tienes objetos relacionados con tu búsqueda</h4>
                              : <h4 className="mycollection-message">¡Vaya! Esta lista está vacía</h4>
                          }
                        </div>
                        : <React.Fragment>
                          <Masonry
                            deleteFav
                            onDelete={this.onSearch}
                            advanceSearch={this.props.filters.advanceMode}
                            isLoaded={this.masonryStatusChange}
                            items={this.state.items}
                            onClickHandler={this.onClickHandler} />
                        </React.Fragment>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
          : <Loading />
      }
    </ErrorBoundary>
  }
}
const mapStateToProps = state => ({
  filters: state.filters,
  data: state.data
})

export default connect(mapStateToProps)(MyCollection)
