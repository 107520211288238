import React from 'react'
import { connect } from 'react-redux'

import Form from './Form'

/**
 * Maneja el estado de interfaz para la sección del profesor
 *
 * @class TeacherSection
 * @extends {React.Component}
 */
class TeacherSection extends React.Component {

  state = {
    section: 'login',
    user: null,
    email: '',
    password: ''
  }

  /**
   * carga el usuario cuando el componente se ha montado
   *
   * @memberof TeacherSection
   */
  componentDidMount() {
    this.loadUser()
  }

  /**
   * Controlador para cargar el usuario
   *
   * @memberof TeacherSection
   */
  loadUser = () => {
    let user = sessionStorage.getItem('0mZTFbxo1u')
    if (user !== null) {
      user = JSON.parse(user)
      this.setState({ section: 'form', user })
    }
  }


  /**
   * Actualiza el estado de interfaz
   *
   * @memberof TeacherSection
   */
  changeSectionHandler = () => {
    this.setState({ section: 'success' })
    this.loadUser()
  }


  /**
   * Cambia la sección cuando el usuario es creado
   *
   * @memberof TeacherSection
   */
  onNewUserCreated = ({ email, password }) => {
    this.setState({ email, password, section: 'login' })
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof TeacherSection
   */
  render() {
    return !this.props.user.isLogged
      ? <React.Fragment>
        <div className="row text-center virtual_teacher">
          <h4 className="text-learn">Si aún no te has registrado como profesor y te gustaría diseñar tus propias clases, escribe al área de mediación solicitando tu cuenta.</h4>
          <h4 className="text-learn">
            <a className="pink_font" href="mailto:publicos.tlatelolco@gmail.com">publicos.tlatelolco@gmail.com</a>
          </h4>
        </div>
      </React.Fragment>
      : this.props.user.ur === 6 || this.props.user.ur === 1
        ? <Form user={this.state.user} />
        : <React.Fragment>
          <div className="row text-center virtual_teacher">
            <h4 className="text-learn">Si aún no te has registrado como profesor y te gustaría diseñar tus propias clases, escribe al área de mediación solicitando tu cuenta.</h4>
            <h4 className="text-learn">
              <a className="pink_font" href="mailto:publicos.tlatelolco@gmail.com">publicos.tlatelolco@gmail.com</a>
            </h4>
          </div>
        </React.Fragment>
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(TeacherSection)
