import img1 from '../../../../assets/img/background/bg_00.png'
import img2 from '../../../../assets/img/background/bg_01.png'
import img3 from '../../../../assets/img/background/bg_02.png'
import img4 from '../../../../assets/img/background/bg_03.png'
import img5 from '../../../../assets/img/background/bg_04.png'
import img6 from '../../../../assets/img/background/bg_05.png'
import img7 from '../../../../assets/img/background/bg_06.png'
import img8 from '../../../../assets/img/background/bg_07.png'
import img9 from '../../../../assets/img/background/bg_08.png'
import img10 from '../../../../assets/img/background/bg_09.png'
import img11 from '../../../../assets/img/background/bg_10.png'
import img12 from '../../../../assets/img/background/bg_11.png'
import img13 from '../../../../assets/img/background/bg_12.png'
import img14 from '../../../../assets/img/background/bg_13.png'
import img15 from '../../../../assets/img/background/bg_14.png'
import img16 from '../../../../assets/img/background/bg_15.png'
import img17 from '../../../../assets/img/background/bg_16.png'
import img18 from '../../../../assets/img/background/bg_17.png'
import img19 from '../../../../assets/img/background/bg_18.png'
import img20 from '../../../../assets/img/background/bg_19.png'
import img21 from '../../../../assets/img/background/bg_20.png'
import img22 from '../../../../assets/img/background/bg_21.png'
import img23 from '../../../../assets/img/background/bg_22.png'


/** 
 * Arreglo de imagenes utilizadas en las animaciones
 */
export default [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23
]