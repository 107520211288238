import React from 'react'

/**
 * Component stateless para filtro archivo
 *
 * @param {Object} props
 */
const thematicCoverageFilter = props => props.activeSearch === 'keyword'
  ? <React.Fragment>
    {
      props.filters.map((filter, index) => {
        let bold = index % 2 === 0
          ? 'font_bold keyword_bold'
          : ''
        return <span key={index} onClick={() => props.addFilterHandler('keyword', filter.name)} className={`search_item_link ${bold}`}>{filter.name}</span>
      })
    }
  </React.Fragment>
  : null

export default thematicCoverageFilter