import React from 'react'
import ReactDOM from 'react-dom'
import Loading from '../../baseComponents/Loading'
import animations from '../../utils/animations'
import axios from '../../services/instance'

import aboutSmallLogo from '../../../assets/img/logo_m68_small.svg'
import aboutImage from '../../../assets/img/acerca_00.jpg'

import title_collections from '../../../assets/img/titles/title_collections.svg'

import collections_01 from '../../../assets/img/collections_01.jpg'
import collections_02 from '../../../assets/img/collections_02.jpg'

import ReactGA from 'react-ga'

import { connect } from 'react-redux'

/**
 * Clase controladora de la sección about
 *
 * @export
 * @class About
 * @extends {React.Component}
 */
class About extends React.Component {

  state = {
    logos: [],
    m68_partners: 'M68 es posible gracias al generoso apoyo de:',
    strategic_partners: 'Socio estratégico:',
    universitary_committee: 'Comité Universitario M68: Ciudadanías en movimiento',
    UNAM: 'Universidad Nacional Autónoma de México (UNAM)',
    public_archives: 'Archivos Públicos:',
    civil_society_archives: 'Archivos de la Sociedad Civil',
    media_archives: 'Medios de comunicación',
    universitary_archives: 'Otros archivos universitarios',
    isLoading: true,
  }


  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)
    axios.get('/cms/logos')
      .then(res => {
        let data = res.data && res.data.logos
        data = typeof data === 'string' ? JSON.parse(data) : {}
        let logos = data.logos instanceof Array ? data.logos : Object.values(data.logos)
        this.setState({
          ...this.state,
          ...data,
          logos,
          isLoading: false,
        }, () => {
          if (this.props.location.hash === '#participantes') {
            const domNode = ReactDOM.findDOMNode(this.about_parties)
            setTimeout(() => domNode.scrollIntoView({ block: 'start', behavior: 'smooth' }), 1000)
          }
          document.title = 'm68 - ciudadanías en movimiento'
          animations.about.in()
        })
      })
      .catch(err => { console.log(err) })
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof About
   */


  render() {
    const { isLoading, m68_partners, UNAM, civil_society_archives, logos, media_archives, public_archives, strategic_partners, universitary_archives, universitary_committee } = this.state

    let { archives } = this.props.app
      , archiveKeys = Object.keys(archives)
      , leftBlock = []
      , rightBlock = []
      , middle = archiveKeys.length > 1 ? Math.floor(archiveKeys.length / 2) : 1

    leftBlock = archiveKeys.map(key => archives[key]).filter((_, i) => i <= middle)
    rightBlock = archiveKeys.map(key => archives[key]).filter((_, i) => i > middle)

    if (isLoading) return <Loading />

    return <div id="about" className="section section_padding">
      <div id="about_intro" className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="info_block">
              <div className="title_image">
                <img alt='image1' id="about_small_logo" className='img-responsive about_animation' src={aboutSmallLogo} />
                {/*
                <p id="about_subtitle" className='subtitle pink_font font_medium about_animation'>
                  A 50 años
                </p>
                */}
              </div>
              <div id="about_big_image" className='image about_animation'>
                <div id="about_main_slider" className="about_slider about_animation">
                  {
                    /*
                    <Link to='/coleccion/25724'>
                      <div><img alt='image2' className="img-responsive center-block" src={aboutImage} /></div>
                    </Link>
                    */
                  }
                  <div><img alt='image2' className="img-responsive center-block" src={aboutImage} /></div>
                </div>
              </div>
              <div id="about_description" className='description about_animation '>
                {
                  this.props.app.about.split('\n')
                    .map(paragraph => <p>{paragraph}</p>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about_sponsors" className="container about_animation">
        <div className="row">
          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{m68_partners}</h2>
              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'm68_partners')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      console.log(logo.size)
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{strategic_partners}</h2>
              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'strategic_partners')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about_collections" className="container about_animation">
        <div className="row">
          <div className="col-sm-12">
            <div className="title">
              <img id="about_collections_title" className="img-responsive" alt="Colección" src={title_collections} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-xs-10 col-xs-push-1 ">
                <div className="gallery">
                  <img className="img-responsive center-block" alt="galeria_01" src={collections_01} />
                  <div className="img-float">
                    <img className="img-responsive" alt="galeria_02" src={collections_02} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            {
              this.props.app.collection_txt.split('\n')
                .map(paragraph => <p>{paragraph}</p>)
            }
          </div>
        </div>

      </div>
      <div ref={ref => { this.about_parties = ref }} id="about_parties" className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="big_font font_bold  letter_spaced">Instituciones y archivos participantes</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">

            <div id="partiesAccordion" className="accordion">
              {
                leftBlock.map(block => <div key={block.id} className="party">
                  <div className="title uppercase font_bold">{block.title}</div>
                  <div className="info_block list_levels">
                    {
                      Object.keys(block.content).map(item => <div key={block.content[item].id} className="accordion-group">
                        <div className="accordion-heading">
                          <a className="accordion-toggle collapse in" data-toggle="collapse" data-parent="#partiesAccordion" href={`#${block.content[item].id}`}>
                            <span className="level_1">{block.content[item].title}</span>
                          </a>
                        </div>
                        <div id={`${block.content[item].id}`} className="accordion-body collapse accordion_level_2 in">
                          <div className="accordion-inner">
                            {
                              Object.keys(block.content[item].content).map(level2 => <div className="accordion" id="ccutAccordion">
                                <div className="accordion-group">
                                  <div className="accordion-heading">
                                    <a className="accordion-toggle collapse in" data-toggle="collapse" data-parent="#unam" href={`#${block.content[item].content[level2].id}`}>
                                      <span className="level_2">{block.content[item].content[level2].title}</span>
                                    </a>
                                  </div>
                                  <div id={block.content[item].content[level2].id} className="accordion-body collapse in">
                                    <div className="accordion-inner">
                                      {
                                        Object.keys(block.content[item].content[level2].content).map(level3 => <span className="level_3">{block.content[item].content[level2].content[level3].title}</span>)
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>)
                            }

                          </div>
                        </div>
                      </div>)
                    }
                  </div>
                </div>)
              }
            </div>
          </div>







          <div className="col-sm-6">
            <div className="accordion  ">
              {
                rightBlock.map(block => <div key={block.id} className="party">
                  <div className="title uppercase font_bold">{block.title}</div>
                  <div className="info_block list_levels">
                    {
                      Object.keys(block.content).map(item => <div key={block.content[item].id} className="accordion-group">
                        <div className="accordion-heading">
                          <a className="accordion-toggle collapse in" data-toggle="collapse" data-parent="#partiesAccordion" href={`#${block.content[item].id}`}>
                            <span className="level_1">{block.content[item].title}</span>
                          </a>
                        </div>
                        <div id={`${block.content[item].id}`} className="accordion-body collapse accordion_level_2 in">
                          <div className="accordion-inner">
                            {
                              Object.keys(block.content[item].content).map(level2 => <div className="accordion" id="ccutAccordion">
                                <div className="accordion-group">
                                  <div className="accordion-heading">
                                    <a className="accordion-toggle collapse in" data-toggle="collapse" data-parent="#unam" href={`#${block.content[item].content[level2].id}`}>
                                      <span className="level_2">{block.content[item].content[level2].title}</span>
                                    </a>
                                  </div>
                                  <div id={block.content[item].content[level2].id} className="accordion-body collapse in">
                                    <div className="accordion-inner">
                                      {
                                        Object.keys(block.content[item].content[level2].content).map(level3 => <span className="level_3">{block.content[item].content[level2].content[level3].title}</span>)
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>)
                            }

                          </div>
                        </div>
                      </div>)
                    }
                  </div>
                </div>)
              }
            </div>
          </div>
          <div className='col-sm-12'>
            <p className="">Agradecemos a la Coordinación de Colecciones Universitarias Digitales (CCUD)  de la UNAM por su asesoría y apoyo.</p>
          </div>
        </div>
      </div>



      <div className="container" id="about_sponsors_02">
        <div className="row">
          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{universitary_committee}</h2>
              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'universitary_committee')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{UNAM}</h2>

              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'UNAM')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{public_archives}</h2>
              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'public_archives')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{civil_society_archives}</h2>
              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'civil_society_archives')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{media_archives}</h2>
              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'media_archives')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="sponsors_container">
              <h2 className="big_font font_bold text-center letter_spaced">{universitary_archives}</h2>
              <div className="grid">
                {
                  logos
                    .filter(logo => logo.target === 'universitary_archives')
                    .map(logo => {
                      let url = `https://m68collection.s3-us-west-2.amazonaws.com/${logo.src}`
                      return <div
                        key={logo.id}
                        className={`item ${logo.size}`}>
                        <img className="img-responsive center-block" alt={logo.alt} src={url} />
                      </div>
                    })
                }
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  app: state.app
})

export default connect(mapStateToProps)(About)