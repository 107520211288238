import React from 'react'
import ReactGA from 'react-ga'
import Loading from '../../baseComponents/Loading'

import { connect } from 'react-redux'

import animations from '../../utils/animations'

import './index.css'

/**
 * Clase para la representación de la pantalla Legal
 *
 * @param {*} props
 */
class Legal extends React.Component {

  /**
   * Carga las animaciones
   *
   * @memberof Legal
   */
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)
    animations.legal.in()
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) animations.legal.in()
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Legal
   */
  render() {
    const { isLoading, legals } = this.props.footer

    if (isLoading) return <Loading />

    return <div id="legal" className="section section_padding privacy_animation">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-push-2">
            <div id="privacy_title" className='font_medium text-center privacy_animation'>
              <h1 className="font_bold">{legals.title}</h1>
            </div>
            <div className='description privacy_animation'>
              <div dangerouslySetInnerHTML={{ __html: legals.content }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => ({
  footer: state.footer,
})

export default connect(mapStateToProps)(Legal)