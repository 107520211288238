import React from 'react'

import Header from '../Header'

const renderElement = element => {
  if (element.isHTML) return <div className="description" dangerouslySetInnerHTML={{ __html: element.value }} />
  return <div className="description">{element.value.split('\n').map((t, i) => <React.Fragment key={i}>
    {t}
    <br />
  </React.Fragment>)}</div>
}
/**
 * Componente stateless para la representación de la información básisca
 * 
 * @param {Object} props
 * @returns
 */
const basicInfo = props => {
  return <div className="basic_info">
    {/*
    <div className="submenu text-right">
      <div onClick={props.onClick} id="close_object" className="close_btn button">
        <i className="icon icon-times"></i>
      </div>
    </div>
    */}
    <Header headers={props.headers} />

    {
      props.info.map(itemInfo => {
        return <div key={itemInfo.title} className="info_block">
          <div className="title font_bold uppercase">{itemInfo.title}</div>
          {
            itemInfo.type === 'single'
              ? renderElement(itemInfo)
              : itemInfo.value.map(value => <div key={value} className="description">{value.split('\n').map((t, i) => <React.Fragment key={i}>
                {t}
                <br />
              </React.Fragment>)}</div>)
          }
        </div>
      })
    }
  </div>
}

export default basicInfo