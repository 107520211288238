import React from 'react'
import { createActivity } from '../../../../services/learn'
import { isFormValid } from '../../../../utils/fieldValidations'


/**
 * Formulario para crear actividades del profesor
 *
 * @export
 * @class Form
 * @extends {React.Component}
 */
export default class Form extends React.Component {

  state = {
    lecture: '',
    activity: '',
    activityName: '',
    file: null,
    warningFields: []
  }


  /**
   * Valida los campos del formulario
   *
   * @memberof Form
   */
  validateFields = () => {
    let fields = {
      lecture: {
        name: 'tema',
        value: this.state.lecture
      },
      activity: {
        name: 'tipo de actividad',
        value: this.state.activity
      },
      activityName: {
        name: 'nombre de la actividad',
        value: this.state.activityName
      },
      file: {
        name: 'archivo',
        value: this.state.file
      }
    }
    isFormValid(fields, fields => {
      if (fields.length === 0) this.createActivity()
      else this.setState({ warningFields: fields })
    })
  }


  /**
   * Manda al servidor los datos para crear la actividad
   *
   * @memberof Form
   */
  createActivity = () => {
    let data = new FormData()
    data.append("filedoc", this.state.file)
    data.append("user_id", this.props.user.ui)
    data.append("title", this.state.activityName)
    data.append("topic", this.state.lecture)
    data.append("activity", this.state.activity)
    createActivity(data)
      .then(res => {
        this.setState({ warningFields: [], lecture: '', activity: '', activityName: '', file: null })
        alert('actividad creada correctamente')
      })
      .catch(error => {
        console.log('error', error)
        alert('Hubo un error al subir tu clase, por favor intenta más tarde')
      })
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Form
   */
  render() {
    return <div id="virtual_classroom_glossary" className="virtual_classroom_option">
      <div className="row inner-login">
        <div className="col-sm-8 col-sm-push-2 text-center">
          <h3 className="big_title font_medium required-account">Actividad</h3>
          <div className="title font_bold_ required-account">Sube tu actividad y llena los campos requeridos</div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <label>Elige un nivel</label>
              <div className="styled-select btn_shadow">
                <select className='btn_shadow_ awesome_input' value={this.state.lecture} onChange={e => { this.setState({ lecture: e.target.value }) }}>
                  <option disabled value=''>Ninguno</option>
                  <option>Primaria</option>
                  <option>Secundaria</option>
                  <option>Preparatoria</option>
                  <option>Universidad</option>
                </select>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <label>Elige el tipo de actividad</label>
              <div className="styled-select btn_shadow">
                <select className='btn_shadow_ awesome_input' value={this.state.activity} onChange={e => { this.setState({ activity: e.target.value }) }}>
                  <option disabled value=''>Ninguno</option>
                  <optgroup label="Movimiento estudiantil de 1968 en México">
                    <option value='Movimiento estudiantil de 1968 en México - en el memorial'>En el memorial</option>
                    <option value='Movimiento estudiantil de 1968 en México - en línea'>En línea</option>
                  </optgroup>
                  <optgroup label="Movimientos sociales contemporáneos en México">
                    <option value='Movimientos sociales contemporáneos en México - en el memorial'>En el memorial</option>
                    <option value='Movimientos sociales contemporáneos en México - en línea'>En línea</option>
                  </optgroup>
                </select>
              </div>
            </div>

            <div className="col-xs-12">
              <label>Nombre de la actividad</label>
              <input className='btn_shadow awesome_input' value={this.state.activityName} onChange={e => { this.setState({ activityName: e.target.value }) }} />
            </div>
            <div className="col-xs-12">
              <label>Sube el archivo con la actividad</label>
              <p onClick={() => this.fileInput.click()} className='btn_shadow awesome_input filename-faker'>{this.state.file === null ? 'Selecciona un archivo' : String(this.state.file.name)}</p>
              <input ref={ref => this.fileInput = ref} className='btn_shadow awesome_input hidden' onChange={e => { if (e.target.files.length !== 0) this.setState({ file: e.target.files[0] }) }} type='file' accept=".pdf,.mp4,.jpg" />
            </div>
          </div>
          <button onClick={this.validateFields} className="pink_btn btn_shadow create-account">
            <span>Guardar</span>
          </button>
          {
            this.state.warningFields.map((warning, index) => <p key={index} className={`error error-${index}`}>{warning.message}</p>)
          }
        </div>
      </div>
    </div>
  }
}
