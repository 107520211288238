import React from 'react'
import { connect } from 'react-redux'

import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'

import animations from '../../utils/animations'

import ErrorBoundary from '../../baseComponents/ErrorBoundary'
import Infobar from './components/Infobar'
import Loading from '../../baseComponents/Loading'
import Masonry from '../../baseComponents/Masonry'
import SearchBar from './components/SearchBar'
import EmptyResult from '../../baseComponents/EmptyResult'
import ReactGA from 'react-ga'

import { getPaginated } from '../../services/paginated'
import { getFilters } from '../../services/filters'
import { setFilterData } from '../../redux/actions/data'

import spinner from '../../../assets/img/spinner.svg'

import { setItems, setScrollPosition, setAmount, setLimit } from '../../redux/actions/data'
import {
  setPage,
  setCollection,
  setDateRange,
  setKeyword,
  setTextFilter,
  setTransversalApproach,
  setLabel,

  addGender,
  removeGender,
  addMedium,
  removeMedium,
  addTypology,
  removeTypology,
  addLang,
  removeLang,
  addTechnique,
  removeTechnique,
  addKeyword,
  removeKeyword,
  setAdvanceMode,
  addAgentType,
  removeAgentType,
  addAdvanceCollection,
  removeAdvanceCollection,
  addFund,
  removeFund,
  addTitleType,
  removeTitleType,
  shouldDisplay,
  shouldHide,
  resetFilters,
} from '../../redux/actions/filters'

/**
 * Clase para controlar el estado de interfaz de la interna de colección
 *
 * @class Collection
 * @extends {React.Component}
 */
class Collection extends React.Component {
  timers = []

  state = {
    items: [],
    amountItems: 0,
    pages: 0,
    hasMansonryLoaded: false,
    isFetching: false,
    hasLoaded: false,
    emptyResult: false,
    advanceSearch: false,
    dateRange: { min: 1960, max: (new Date()).getFullYear() },
    text: '',
    shouldLoadData: false,
    searchLimit: 'full'
  }


  cleanRepeated = (objects) => {
    let items = {}
    for (let index = 0; index < objects.length; index++) {
      let item = objects[index]
      items[item.id] = { ...item, itemIndex: index }
    }
    items = Object.values(items).sort((a, b) => a.itemIndex - b.itemIndex)
    return items
  }

  /**
   * ejecuta la busqueda realizada por el usuario
   *
   * @memberof Collection
   */
  onSearch = () => {
    this.setState({ objects: [], isFetching: true, pages: 0 })
    this.props.dispatch(setPage(0))
    getPaginated(this.state.searchLimit, 0)
      .then(res => {
        let items = this.cleanRepeated(res.items)
        this.setState({ ...res, isFetching: false, amountItems: res.amountItems, items: items, emptyResult: items.length === 0 })
        animations.collection.in()
      })
      .catch(err => console.error('[Collection] componentDidMount =>', err))
  }


  /**
   * Reinicia el valor de un filtro de busqueda
   *
   * @param {String} filter - filtro a reiniciar
   * @param {String} value - valor adicional para algunos filtros
   * @memberof Collection
   */
  onResetFilter = (filter, value) => {
    switch (filter) {
      case 'collection':
        this.props.dispatch(setCollection())
        break
      case 'transversalApproach':
        this.props.dispatch(setTransversalApproach())
        break
      case 'dateRange':
        this.props.dispatch(setDateRange())
        this.setState({ dateRange: { min: 1960, max: (new Date()).getFullYear() } })
        break
      case 'text':
        this.props.dispatch(setTextFilter())
        break
      case 'keyword':
        this.props.dispatch(setKeyword())
        break
      case 'label':
        this.props.dispatch(setLabel())
        break
      case 'genders':
        this.props.dispatch(removeGender(value))
        break
      case 'mediums':
        this.props.dispatch(removeMedium(value))
        break
      case 'typologies':
        this.props.dispatch(removeTypology(value))
        break
      case 'languages':
        this.props.dispatch(removeLang(value))
        break
      case 'techniques':
        this.props.dispatch(removeTechnique(value))
        break
      case 'thematicCoverage':
        this.props.dispatch(removeKeyword(value))
        break
      case 'agentType':
        this.props.dispatch(removeAgentType(value))
        break
      case 'funds':
        this.props.dispatch(removeFund(value))
        break
      case 'advanceCollection':
        this.props.dispatch(removeAdvanceCollection(value))
        break
      case 'titleType':
        this.props.dispatch(removeTitleType(value))
        break
      default:
        break
    }
    this.onSearch()
  }



  /**
   * Actualiza el modo de busqueda a avanzado
   *
   * @memberof Collection
   */
  onAdvanceSearch = () => {
    // this.props.dispatch(resetFilters())
    this.props.dispatch(setAdvanceMode())
    this.onSearch()
  }


  /**
   * Actualiza el estado interno de la rejilla
   *
   * @memberof Collection
   */
  masonryStatusChange = () => { this.setState({ hasMansonryLoaded: true }) }


  /**
   * Guarda el estado de la rejilla en redux
   *
   * @memberof Collection
   */
  onClickHandler = () => {
    this.props.dispatch(setItems(this.state.items))
    this.props.dispatch(setAmount(this.state.amountItems))
    this.props.dispatch(setLimit(this.state.pages))
    this.props.dispatch(setScrollPosition(window.pageYOffset))
  }


  /**
   * Carga nuevos elementos cuando el scroll llega al final
   *
   * @memberof Collection
   */
  loadOnScroll = e => {
    let el = document.getElementById('content-end')
      , rect = null
      , isAtEnd

    if (el !== null) {
      rect = el.getBoundingClientRect()
      isAtEnd = (
        Math.floor(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
      )
    }

    if (isAtEnd && this.props.filters.page < this.state.pages) {
      if (this.state.shouldLoadData === false) {
        this.setState({ shouldLoadData: true, hasMansonryLoaded: false })
        this.props.dispatch(setPage(this.props.filters.page + 1))
        getPaginated(this.state.searchLimit)
          .then(res => {
            this.setState(prevState => ({
              ...res,
              items: this.cleanRepeated([...prevState.items, ...res.items]),
              amountItems: res.amountItems,
              shouldLoadData: false
            }))
          })
          .catch(err => console.error('[Collection] componentDidMount =>', err))
      }
    }
  }

  /**
   * Aplica el filtro de tipo de obra
   *
   * @param {*} value
   * @memberof Collection
   */
  applyGender = value => {
    let exist = this.props.filters.genders.filter(gender => gender === value).length
    if (!exist) this.props.dispatch(addGender(value))
    else this.props.dispatch(removeGender(value))
    this.onSearch()
  }

  /**
   * Aplica el filtro de formato
   *
   * @param {*} value
   * @memberof Collection
   */
  applyMedium = value => {
    let exist = this.props.filters.mediums.filter(medium => medium === value).length
    if (!exist) this.props.dispatch(addMedium(value))
    else this.props.dispatch(removeMedium(value))
    this.onSearch()
  }
  /**
   * Aplica el filtro de ámbito
   *
   * @param {*} value
   * @memberof Collection
   */
  applyTypology = value => {
    let exist = this.props.filters.typologies.filter(typology => typology === value).length
    if (!exist) this.props.dispatch(addTypology(value))
    else this.props.dispatch(removeTypology(value))
    this.onSearch()
  }

  /**
   * Aplica el filtro de lengua
   *
   * @param {*} value
   * @memberof Collection
   */
  applyLang = value => {
    let exist = this.props.filters.languages.filter(lang => lang === value).length
    if (!exist) this.props.dispatch(addLang(value))
    else this.props.dispatch(removeLang(value))
    this.onSearch()
  }
  /**
   * Aplica el filtro de soporte
   *
   * @param {*} value
   * @memberof Collection
   */
  applyTechnique = value => {
    let exist = this.props.filters.techniques.filter(technique => technique === value).length
    if (!exist) this.props.dispatch(addTechnique(value))
    else this.props.dispatch(removeTechnique(value))
    this.onSearch()
  }
  /**
   * Aplica el filtro de palabra clave
   *
   * @param {*} value
   * @memberof Collection
   */
  applyKeyword = value => {
    let exist = this.props.filters.thematicCoverage.filter(keyword => keyword === value).length
    if (!exist) this.props.dispatch(addKeyword(value))
    else this.props.dispatch(removeKeyword(value))
    this.onSearch()
  }
  /**
   * Actualiza el valor inicial del filtro fecha
   *
   * @param {*} value
   * @memberof Collection
   */
  onChangeStart = value => {
    if (value.min === (new Date()).getFullYear() && value.max === (new Date()).getFullYear()) {
      this.setState({ dateRange: { min: 2017, max: (new Date()).getFullYear() } })
    }
  }
  /**
   * Actualiza el valor del filtro fecha
   *
   * @param {Object} date
   * @memberof Collection
   */
  changeDateHandler = date => {
    let dateRange = {
      min: date.min < 1960 ? 1960 : date.min,
      max: date.max > (new Date()).getFullYear() ? (new Date()).getFullYear() : date.max
    }
    if (date.min === (new Date()).getFullYear() && date.max === (new Date()).getFullYear() + 1) {
      dateRange = {
        min: 2015,
        max: (new Date()).getFullYear()
      }
    }
    this.setState({ dateRange })
    // this.props.dispatch(setDateRange(`${dateRange.min}_${dateRange.max}`))
  }

  /**
   * plica el filtro de fecha
   *
   * @memberof Collection
   */
  applyDate = () => {
    let { dateRange } = this.state

    this.props.dispatch(setDateRange(`${dateRange.min}_${dateRange.max}`))
    this.props.dispatch(shouldDisplay())
    this.onSearch()
  }


  /**
   * Reinicia el modo de busqueda a básico
   *
   * @memberof Collection
   */
  resetAdvanceSearch = () => {
    this.props.dispatch(resetFilters())
    this.props.dispatch(shouldHide())
    this.onSearch()
    this.setState({ dateRange: { min: 1960, max: (new Date()).getFullYear() } })
  }

  /**
   * Aplica el filtro de tipo de agente
   *
   * @param {*} value
   * @memberof Collection
   */
  applyAgentType = value => {
    let exist = this.props.filters.agentType.filter(keyword => keyword === value).length
    if (!exist) this.props.dispatch(addAgentType(value))
    else this.props.dispatch(removeAgentType(value))
    this.onSearch()
  }

  /**
   * Aplica el filtro de colección en modo avanzado
   *
   * @param {*} value
   * @memberof Collection
   */
  applyAdvanceCollection = value => {
    let exist = this.props.filters.advanceCollection.filter(keyword => keyword === value).length
    if (!exist) this.props.dispatch(addAdvanceCollection(value))
    else this.props.dispatch(removeAdvanceCollection(value))
    this.onSearch()
  }


  applyTitleType = value => {
    let exist = this.props.filters.titleType.filter(keyword => keyword === value).length
    if (!exist) this.props.dispatch(addTitleType(value))
    else this.props.dispatch(removeTitleType(value))
    this.onSearch()
  }

  onTextSearch = () => {
    this.props.dispatch(setTextFilter(this.state.text))
    this.onSearch()
  }

  /**
   * Aplica el filtro de fondo
   *
   * @param {*} value
   * @memberof Collection
   */
  applyFund = value => {
    let exist = this.props.filters.funds.filter(keyword => keyword === value).length
    if (!exist) this.props.dispatch(addFund(value))
    else this.props.dispatch(removeFund(value))
    this.onSearch()
  }

  /**
   * Reliza la carga inicial de objetos en la rejilla
   *
   * @memberof Collection
   */
  componentDidMount() {
    animations.collection.in()
    ReactGA.pageview(this.props.location.pathname)
    document.title = 'Colecciones - m68 - ciudadanías en movimiento'
    if (this.props.data.items.length === 0) {
      this.setState({ isFetching: true })
      getPaginated(this.state.searchLimit)
        .then(res => {
          let items = this.cleanRepeated(res.items)
          this.setState({ ...res, isFetching: false, emptyResult: res.items.length === 0, amountItems: res.amountItems, items: items, })
          return getFilters()
        })
        .then(res => {
          this.props.dispatch(setFilterData({
            ...res,
            hasLoaded: true
          }))
          animations.collection.in()
        })
        .catch(err => {
          this.setState({ isFetching: false })
          throw new Error(err)
        })
    } else {
      getFilters()
        .then(res => {
          this.props.dispatch(setFilterData({
            ...res,
            hasLoaded: true
          }))

          this.setState({
            items: this.props.data.items,
            amountItems: this.props.data.amountItems,
            pages: this.props.data.pages,
          })
          this.timers[0] = setTimeout(() => {
            window.scrollTo(0, this.props.data.scrollPosition)
            animations.collection.in()
          }, 3000)
        })
        .catch(err => { throw new Error('No se pudo obtener la información') })
    }
    window.addEventListener('scroll', this.loadOnScroll)
  }

  handleOptionChange = changeEvent => {
    this.setState({
      searchLimit: changeEvent.target.value
    })
  }



  /**
   * Limpia suscripciones antes de destruir la clase
   *
   * @memberof Collection
   */
  componentWillUnmount() {
    this.timers.map(timer => clearTimeout(timer))
    window.removeEventListener('scroll', this.loadOnScroll)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filters !== nextProps.filters) {
      this.setState({ text: nextProps.filters.text === '0' ? '' : nextProps.filters.text })
    }
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Collection
   */
  render() {
    let date_pickers = []
      , date = new Date()
      , date_last = 0;
    for (let i = 1960; i <= date.getFullYear(); i += 10) date_pickers.push(i)
    date_last = date_pickers[date_pickers.length - 1];
    if (date_last !== date.getFullYear()) date_pickers.push(date.getFullYear())

    // if (this.state.isFetching) return <Loading />
    let { searchLimit } = this.state

    return <ErrorBoundary>
      {
        this.props.data.filterData.hasLoaded
          ? <div id="collection" className='section collection_animation section_padding'>
            <div className="container-fluid">
              {
                !this.props.filters.advanceMode
                  ? <SearchBar onSearch={this.onSearch} onAdvanceSearch={this.onAdvanceSearch} />
                  : null
              }
              <div className="row">
                {
                  this.props.filters.advanceMode
                    ? <div className="limit-advance col-sm-2">
                      <div id="objects_filter">
                        <div id="close-objects_filter" onClick={this.onAdvanceSearch}>
                          <i className="icon icon-times"></i>
                        </div>
                        <h4 className="title">Búsqueda avanzada</h4>
                        <div className="panel-group font_medium" id="accordion">

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle " href="#collapse12" aria-expanded="true">Buscar en la colección</a>
                              </h4>
                            </div>
                            <div id="collapse12" className="panel-collapse collapse.in collapse in">
                              <div className="panel-body">
                                <div className="inner-panel" style={{ overflow: 'initial' }}>
                                  <div className="form ">
                                    <div className='form-group'>
                                      <input
                                        value={this.state.text}
                                        onChange={e => { this.setState({ text: e.target.value }) }}
                                        onKeyPress={e => { if (e.key === 'Enter') this.onTextSearch() }}
                                        className="search_input"
                                        style={{ width: '100%' }}
                                        type="text"
                                        placeholder="Buscar en la colección..." />
                                    </div>

                                    <div className='form-group'>
                                      <label className="radio_filter">
                                        <input
                                          onChange={this.handleOptionChange}
                                          checked={searchLimit === 'full'}
                                          type="radio" name="inlineRadioOptions"
                                          value='full'
                                          id="full" /> Búsqueda completa
                                      </label>
                                      <label className="radio_filter">
                                        <input
                                          onChange={this.handleOptionChange}
                                          checked={searchLimit === 'title'}
                                          type="radio"
                                          name="inlineRadioOptions"
                                          value='title'
                                          id="title" /> Buscar en título
                                      </label>
                                      <label className="radio_filter">
                                        <input
                                          onChange={this.handleOptionChange}
                                          checked={searchLimit === 'agent'}
                                          type="radio"
                                          name="inlineRadioOptions"
                                          value='agent'
                                          id="agent" /> Buscar en Autor
                                      </label>
                                      <label className="radio_filter">
                                        <input
                                          onChange={this.handleOptionChange}
                                          checked={searchLimit === 'keywords'}
                                          type="radio"
                                          name="inlineRadioOptions"
                                          value='keywords'
                                          id="keywords" /> Buscar en palabras clave
                                      </label>
                                    </div>

                                    <button
                                      id="search_submit_btn"
                                      className="pink_btn search_icon_btn"
                                      onClick={this.onTextSearch}
                                      style={{ width: '50px', marginTop: '5px' }}
                                    >
                                      <span><i style={{ fontSize: '25px', lineHeight: '25px' }} className="icon icon-search"></i></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {
                            /*
                            <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse8">Tipo de agente</a>
                              </h4>
                            </div>
                            <div id="collapse8" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.agentType
                                      .map((keyword, index) => {
                                        let checked = this.props.filters.agentType.filter(g => g === keyword.id).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyAgentType(keyword.id)} type="checkbox" value={keyword.id} />{keyword.name}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                            */
                          }
                          {
                            /*
                            <div className="panel panel-default">
                                                      <div className="panel-heading">
                                                        <h4 className="panel-title">
                                                          <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse11">Tipo de título</a>
                                                        </h4>
                                                      </div>
                                                      <div id="collapse11" className="panel-collapse collapse">
                                                        <div className="panel-body">
                                                          <div className="inner-panel">
                                                            {
                                                              this.props.data.filterData.titleType
                                                                .map((titleType, index) => {
                                                                  let checked = this.props.filters.titleType.filter(g => g === titleType.id).length
                                                                  return <div key={index} className="filter-action checkbox">
                                                                    <label>
                                                                      <input checked={checked} onClick={() => this.applyTitleType(titleType.id)} type="checkbox" value={titleType.id} />{titleType.name}</label>
                                                                  </div>
                                                                })
                                                            }
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                          
                            */
                          }

                          {
                            /*
                            <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse7">Palabras clave</a>
                              </h4>
                            </div>
                            <div id="collapse7" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.keywords
                                      .map((keyword, index) => {
                                        let checked = this.props.filters.thematicCoverage.filter(g => g === keyword.id).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyKeyword(keyword.id)} type="checkbox" value={keyword.id} />{keyword.name}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                            */
                          }

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse3">Ámbito</a>
                              </h4>
                            </div>
                            <div id="collapse3" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.typologies
                                      .map((typology, index) => {
                                        let checked = this.props.filters.typologies.filter(g => g === typology.id).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyTypology(typology.id)} type="checkbox" value={typology.id} />{typology.name}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse6">Fecha</a>
                              </h4>
                            </div>
                            <div id="collapse6" className="panel-collapse collapse">
                              <div className="panel-body padding10_0">
                                <div className="search_bar_range">
                                  <div className="search_bar_year_col year_range_container">
                                    <InputRange
                                      step={10}
                                      maxValue={(new Date()).getFullYear()}
                                      minValue={1960}
                                      value={this.state.dateRange}
                                      onChange={value => this.changeDateHandler(value)}
                                      onChangeStart={this.onChangeStart}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-12 text-center">
                                  <button className="pink_btn btn_shadow" onClick={this.applyDate}>
                                    <span>Aplicar</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse1">Tipo de obra</a>
                              </h4>
                            </div>
                            <div id="collapse1" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.genders
                                      .map((gender, index) => {
                                        let checked = this.props.filters.genders.filter(g => g === gender.id).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyGender(gender.id)} type="checkbox" value={gender.id} />{gender.name}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          {
                            /*
                            <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse2">Formato</a>
                              </h4>
                            </div>
                            <div id="collapse2" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.mediums
                                      .map((medium, index) => {
                                        let checked = this.props.filters.mediums.filter(g => g === medium.id).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyMedium(medium.id)} type="checkbox" value={medium.id} />{medium.name}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                            */
                          }

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse5">Soporte</a>
                              </h4>
                            </div>
                            <div id="collapse5" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.techniques
                                      .map((technique, index) => {
                                        let checked = this.props.filters.techniques.filter(g => g === technique.id).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyTechnique(technique.id)} type="checkbox" value={technique.id} />{technique.name}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse4">Lengua</a>
                              </h4>
                            </div>
                            <div id="collapse4" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel small-panel">
                                  {
                                    this.props.data.filterData.languages
                                      .map((language, index) => {
                                        let checked = this.props.filters.languages.filter(g => g === language.id).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyLang(language.id)} type="checkbox" value={language.id} />{language.name}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse10">Fondo</a>
                              </h4>
                            </div>
                            <div id="collapse10" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.fundList
                                      .map((fund, index) => {
                                        let checked = this.props.filters.funds.filter(g => g === fund.fondo).length
                                        return <div key={index} className="filter-action checkbox">
                                          <label>
                                            <input checked={checked} onClick={() => this.applyFund(fund.fondo)} type="checkbox" value={fund.fondo} />{fund.fondo}</label>
                                        </div>
                                      })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" className="accordion-toggle collapsed" href="#collapse9">Institución</a>
                              </h4>
                            </div>
                            <div id="collapse9" className="panel-collapse collapse">
                              <div className="panel-body">
                                <div className="inner-panel">
                                  {
                                    this.props.data.filterData.collections
                                      .map(collectionItem => <div key={collectionItem.title} className="filter-action checkbox">
                                        <h4 className="panel-title" style={{ marginBottom: '10px' }}>{collectionItem.title}</h4>
                                        {
                                          collectionItem.data.map((item, index) => {
                                            let checked = this.props.filters.advanceCollection.filter(g => g === item.id).length
                                            return <div key={index} className="filter-action checkbox">
                                              <label>
                                                <input checked={checked} onClick={() => this.applyAdvanceCollection(item.id)} type="checkbox" value={item.id} />
                                                {item.name}
                                              </label>
                                            </div>
                                          })
                                        }
                                      </div>)

                                  }
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>

                        <div className="search_advanced_button row">
                          <div className="col-xs-12 text-center">
                            <button className="white_btn btn_shadow" onClick={this.resetAdvanceSearch} >
                              <span>Limpiar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                }
                <div className={`${this.props.filters.advanceMode ? 'calc-advance col-sm-10' : 'col-sm-12'}`}>
                  <div id="objects_container">
                    {
                      this.state.isFetching
                        ? <Loading />
                        : this.state.emptyResult
                          ? <React.Fragment>
                            <Infobar
                              amount={this.state.amountItems}
                              onSearch={this.onSearch}
                              reset={this.onResetFilter} />
                            <EmptyResult />
                          </React.Fragment>
                          : <React.Fragment>
                            <Infobar
                              amount={this.state.amountItems}
                              onSearch={this.onSearch}
                              reset={this.onResetFilter} />
                            <Masonry
                              advanceSearch={this.props.filters.advanceMode}
                              isLoaded={this.masonryStatusChange}
                              items={this.state.items}
                              onClickHandler={this.onClickHandler} />
                            {
                              this.props.filters.page < this.state.pages - 1
                                ? <React.Fragment>
                                  <div id="content-end" className="row" >
                                    <div className="col-sm-12 text-center">
                                      <img src={spinner} alt='spinner' />
                                    </div>
                                  </div>
                                  <div className="col-sm-12 text-center spinner"></div>
                                </React.Fragment>
                                : null
                            }
                          </React.Fragment>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
          : <Loading />
      }
    </ErrorBoundary>
  }
}
const mapStateToProps = state => ({
  filters: state.filters,
  data: state.data
})

export default connect(mapStateToProps)(Collection)
