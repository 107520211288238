import axios from './instance'
import axios2 from 'axios'

export const getGenders = () => {
  return axios.get('/catalog_filters/vc_gender')
    .then(res => {
      return {
        type: 'genders',
        data: res.data
      }
    })
}
export const getMedium = () => {
  return axios.get('/catalog_filters/vc_medium')
    .then(res => {
      return {
        type: 'mediums',
        data: res.data
      }
    })
}
export const getTipology = () => {
  return axios.get('/catalog_filters/vc_tipology')
    .then(res => {
      return {
        type: 'typologies',
        data: res.data
      }
    })
}
export const getLanguages = () => {
  return axios.get('/catalog_filters/vc_language')
    .then(res => {
      return {
        type: 'languages',
        data: res.data
      }
    })
}
export const getTechniques = () => {
  return axios.get('/catalog_filters/vc_techniques')
    .then(res => {
      return {
        type: 'techniques',
        data: res.data
      }
    })
}

export const getTransversalApproaches = () => {
  return axios.get('/site_catalog/vc_transversal_approach')
    .then(res => {
      return {
        type: 'transversalApproaches',
        data: res.data
      }
    })
}

export const getThematicCoverage = () => {
  return axios.get('/returnThematicJSON')
    .then(res => {
      return {
        type: 'keywords',
        data: res.data
      }
    })
}

export const getAgetTypes = () => {
  return axios.get('/site_catalog/vc_agent')
    .then(res => {
      return {
        type: 'agentType',
        data: res.data
      }
    })
}

export const getFunds = () => {
  return axios.get('/list_fondos')
    .then(res => {
      return {
        type: 'fundList',
        data: res.data.filter((_, i) => i !== 0)
      }
    })
}

export const getTitleType = () => {
  return axios.get('/site_catalog/vc_title')
    .then(res => {
      return {
        type: 'titleType',
        data: res.data
      }
    })
}

export const getCollection = () => {
  return axios.get('/returnCollections')
    .then(res => {
      return {
        type: 'collections',
        data: [
          {
            title: 'Archivos universitarios'.toUpperCase(),
            data: res.data.filter(value => value.type === null ? false : value.type.toLowerCase() === 'Archivos universitarios'.toLowerCase())
          },
          {
            title: 'Archivos públicos'.toUpperCase(),
            data: res.data.filter(value => value.type === null ? false : value.type.toLowerCase() === 'Archivos públicos'.toLowerCase())
          },
          {
            title: 'Archivos de la sociedad civil'.toUpperCase(),
            data: res.data.filter(value => value.type === null ? false : value.type.toLowerCase() === 'Organismos de la sociedad civil'.toLowerCase())
          },
          {
            title: 'Medios de comunicación'.toUpperCase(),
            data: res.data.filter(value => value.type === null ? false : value.type.toLowerCase() === 'Medios de comunicación'.toLowerCase())
          },
          {
            title: 'Archivos privados'.toUpperCase(),
            data: res.data.filter(value => value.type === null ? false : value.type.toLowerCase() === 'Archivos privados'.toLowerCase())
          }
        ]
      }
    })
}

export const getFilters = () => {
  let filters = [
    getTransversalApproaches(), getThematicCoverage(), getCollection(),
    getGenders(), getMedium(), getTipology(), getLanguages(),
    getTechniques(), getAgetTypes(), getFunds(), getTitleType()
  ]

  return axios2.all(filters)
    .then(res => {
      let filterValues = {}

      for (let index = 0; index < res.length; index++)
        filterValues[res[index].type] = res[index].data

      filterValues.keywords = filterValues.keywords.sort((a, b) => a.name.localeCompare(b.name))

      return filterValues
    })
}
