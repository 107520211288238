import React from 'react'
import ReactGA from 'react-ga'

import animations from '../../utils/animations'


/**
 * Clase para renderizar cuando una ruta no existe en el contexto
 *
 * @export
 * @class NotFound
 * @extends {React.Component}
 */
export default class NotFound extends React.Component {


  /**
   * Ejecuta la animación cuando el componente se ha montado
   *
   * @memberof NotFound
   */
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)
    animations.privacy.in()
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof NotFound
   */
  render() {
    return <div id="privacy" className="section section_padding privacy_animation">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-push-2">
            <div id="privacy_title" className='font_medium text-center privacy_animation'>
              <h1 className="font_bold">Página no encontrada</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}