import React from 'react'

import Login from '../../../Login'

/**
 * Clase para manejar el inicio de sesión
 *
 * @export
 * @class LoginForm
 * @extends {React.Component}
 */
export default class LoginForm extends React.Component {

  state = {
    inAnimation: false,
    hidden: true
  }


  /**
   * Modifica los valores del formulario si el prop show es modificado
   *
   * @param {Object} nextProps
   * @memberof LoginForm
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.setState({
        hidden: false
      })
      setTimeout(() => { this.setState({ inAnimation: 100 }) }, 100)
    } else {
      this.setState({
        inAnimation: false,
        hidden: true
      })
    }
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof LoginForm
   */
  render() {
    let hidden = this.state.hidden ? 'hidden' : ''
    let inAnimation = this.state.inAnimation ? 'in' : ''

    return <div onClick={this.props.onClick} id="login" className={`popup_block full_height contact_animation ${inAnimation} ${hidden}`}>
      <div className="wrapper">
        <div className="content">
          <div className="popup_container" >
            <div onClick={e => e.stopPropagation()} className="popup_content">
              <div onClick={this.props.onClick} className="close_btn"><i className="icon icon-times"></i></div>
              <Login onSuccess={this.props.onClick} showMessage={this.props.showMessage} show={this.props.show} />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
