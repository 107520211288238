import React from 'react'
import { canvas } from './Three'

import './Background.css'

/**
 * Clase que se encarga manejar las animaciones del sitio
 *
 * @export
 * @class Background
 * @extends {React.Component}
 */
export default class Background extends React.Component {
  componentDidMount() {
    canvas(this.threeRootElement, false)
  }

  render() {
    return <div id='canvas_container' className='background' ref={element => this.threeRootElement = element}></div>
  }
}