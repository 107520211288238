import React from 'react'
import { connect } from 'react-redux'

import { authUser } from '../../redux/actions/user'

import Login from './Login'
import NewUser from './NewUser'
import ForgotPassword from './ForgotPassword'


/**
 * Clase contraladora del flujo de formulario de login
 *
 * @class LoginForm
 * @extends {React.Component}
 */
class LoginForm extends React.Component {

  state = {
    section: 'login',
    user: null,
    email: '',
    password: ''
  }


  /**
   * Carga el usuario en redux si este existe
   *
   * @memberof LoginForm
   */
  loadUser = () => {
    let user = sessionStorage.getItem('0mZTFbxo1u')
    if (user !== null) {
      user = JSON.parse(user)
      this.setState({ user })
    }
  }


  /**
   * Actualiza el estado de interfaz
   *
   * @memberof LoginForm
   */
  changeSectionHandler = sectionName => {
    if (sectionName === 'success') {
      let user = sessionStorage.getItem('0mZTFbxo1u')
      this.props.dispatch(authUser(...user))
      if (typeof this.props.onSuccess === 'function') this.props.onSuccess()
    } else {
      this.setState({ section: sectionName })
      this.loadUser()
    }
  }


  /**
   * Dispara el Formulario de login despues de ser creado un usuario
   *
   * @memberof LoginForm
   */
  onNewUserCreated = ({ email, password }) => {
    this.setState({ email, password, section: 'login' })
  }


  /**
   * Reinicia el estado de interfaz del login
   *
   * @param {Object} nextProps
   * @memberof LoginForm
   */
  componentWillReceiveProps(nextProps) {
    if (!nextProps.show) this.setState({ section: 'login' })
  }

  componentDidMount() {
    this.loadUser()
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof LoginForm
   */
  render() {
    return this.state.section === 'login'
      ? <Login show={this.props.show} showMessage={this.props.showMessage} user={this.state} onChangeSectionHandler={this.changeSectionHandler} />
      : this.state.section === 'forgot'
        ? <ForgotPassword onNewUserCreated={this.onNewUserCreated} onChangeSectionHandler={this.changeSectionHandler} />
        : <NewUser onNewUserCreated={this.onNewUserCreated} onChangeSectionHandler={this.changeSectionHandler} />
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(LoginForm)
