import React from 'react'
import { connect } from 'react-redux'

import 'react-input-range/lib/css/index.css'

import animations from '../../utils/animations'

import ErrorBoundary from '../../baseComponents/ErrorBoundary'
import Loading from '../../baseComponents/Loading'
import Masonry from '../../baseComponents/Masonry'
import ReactGA from 'react-ga'

import { getFavs } from '../../services/userFavs'

import { setFavText } from '../../redux/actions/filters'
import { setFavs } from '../../redux/actions/user'
import { getCollection } from '../../services/userFavs'


/**
 * Clase para controlar la interfaz de la sección mi colección
 *
 * @class GalleryScreen
 * @extends {React.Component}
 */
class GalleryScreen extends React.Component {
  timers = []

  state = {
    items: [],
    amountItems: 0,
    pages: 0,
    hasMansonryLoaded: false,
    isFetching: false,
    hasLoaded: false,
    emptyResult: false,
    advanceSearch: false,
    emptySearch: false,
    textFilter: '',
    collection: null,
  }


  /**
   * Dispara la busqueda de elementos sobre los favoritos del usuario
   *
   * @memberof GalleryScreen
   */
  onSearch = () => {
    this.setState({ objects: [], isFetching: true, emptySearch: false })
    getFavs()
      .then(items => {
        animations.collection.in()
        this.setState({ items, isFetching: false, hasLoaded: true, emptyResult: items.length === 0, emptySearch: items.length === 0 })
        this.props.dispatch(setFavs(items))
      })
      .catch(err => {
        this.setState({ isFetching: false })
        throw new Error(err)
      })
  }


  /**
   * Actualiza el estado de la rejilla de los favoritos
   *
   * @memberof GalleryScreen
   */
  masonryStatusChange = () => { this.setState({ hasMansonryLoaded: true }) }


  onClickHandler = () => { }


  /**
   * Reinicia el filtro de busqueda
   *
   * @memberof GalleryScreen
   */
  onSubmitSearch = () => {
    this.props.dispatch(setFavText(this.state.textFilter === '' ? '0' : this.state.textFilter))
    setTimeout(this.onSearch, 500)
  }


  /**
   * Carga los favoritos del usuario
   *
   * @memberof GalleryScreen
   */
  componentDidMount() {
    animations.collection.in()
    ReactGA.pageview(this.props.location.pathname)
    document.title = 'Mi colecciones - m68 - ciudadanías en movimiento'
    getCollection()
      .then(res => {
        animations.collection.in()
        this.setState({ collection: res, isFetching: false, hasLoaded: true, emptyResult: res.length === 0 })
      })
      .catch(err => {
        this.setState({ isFetching: false })
        throw new Error(err)
      })

    window.addEventListener('scroll', this.loadOnScroll)
  }


  /**
   * Limpia las subscripciones antes de que el componente se elimine
   *
   * @memberof GalleryScreen
   */
  componentWillUnmount() {
    this.timers.map(timer => clearTimeout(timer))
    window.removeEventListener('scroll', this.loadOnScroll)
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof GalleryScreen
   */
  render() {
    return <ErrorBoundary>
      {
        this.state.hasLoaded
          ? <div id="collection" className='section collection_animation section_padding'>
            <div className="container-fluid">
              <div className="row">
                <div className='calc-advance col-sm-10'>
                  {
                    this.state.collection
                      ? <div>
                        <h3 className="title font-bold GalleryScreen-title">
                          {this.state.collection.title}
                        </h3>
                        <h5 className="GalleryScreen-title">
                          {this.state.collection.description}
                        </h5>
                        <Masonry
                          deleteFav
                          onDelete={this.onSearch}
                          advanceSearch={this.props.filters.advanceMode}
                          isLoaded={this.masonryStatusChange}
                          items={this.state.collection.collection}
                          onClickHandler={this.onClickHandler} />
                      </div>
                      : null
                  }
                </div>
              </div>

            </div>
          </div>
          : <Loading />
      }
    </ErrorBoundary>
  }
}
const mapStateToProps = state => ({
  filters: state.filters,
  data: state.data
})

export default connect(mapStateToProps)(GalleryScreen)
