import React from 'react'

/**
 * Component stateless para filtro de texto
 *
 * @param {Object} props
 */
const textFilter = props => <div className="col-sm-6 col-md-7 no_padding">
  <table>
    <tbody>
      <tr>
        <td>
          <div className="form ">
            <input
              value={props.text}
              onChange={props.onTextChange}
              onKeyPress={e => { if (e.key === 'Enter') props.onSearch() }}
              className="search_input"
              type="text"
              placeholder="Buscar en la colección ..." />
            <button
              id="search_submit_btn"
              className="pink_btn btn_shadow search_icon_btn"
              onClick={props.onSearch}
            >
              <span><i className="icon icon-search"></i></span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

export default textFilter