export const authUser = (user = { ui: 0, ut: '', ur: 0 }) => ({
  type: 'AUTH',
  ui: user.ui,
  ut: user.ut,
  ur: user.ur
})

export const removeAuth = () => ({
  type: 'UNAUTH'
})

export const showAlert = () => ({
  type: 'SHOWALERT'
})

export const setFavs = (favs = []) => ({
  type: 'FAVS',
  favs
})

export const setFav = (fav = {}) => ({
  type: 'ADDFAV',
  fav
})

export const removeFav = (id = 0) => ({
  type: 'REMOVEFAVS',
  id
})
