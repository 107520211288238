const initialState = {
  isLogged: false,
  ui: 0,
  ut: '',
  ur: 0,
  showAlert: false,
  userFavs: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH': return { ...state, isLogged: true, ui: action.ui, ut: action.ut, ur: action.ur }
    case 'FAVS': return { ...state, userFavs: action.favs }
    case 'ADDFAV': return { ...state, userFavs: [...state.userFavs, action.fav] }
    case 'REMOVEFAVS': return { ...state, userFavs: state.userFavs.filter(fav => Number(fav.id) !== Number(action.id)) }
    case 'SHOWALERT': return { ...state, showAlert: !state.showAlert }
    case 'UNAUTH': return initialState
    default: return state
  }
}