import { updateAnimation } from '../baseComponents/Background/Three'

const animation_time = 1200, $ = window.$, bg_animation_time = 500

/**
 * route: /
 */
function inHome() {
  $("#home_logo").addClass("fadein")
  setTimeout(function () { $("#home_logo").addClass("in") }, 1300)
  setTimeout(function () { $("#home_ciudadanias").addClass("in") }, 1850)
  setTimeout(function () { $("#home_description").addClass("in") }, 2150)
  setTimeout(function () { $("#home_search").addClass("in") }, 2200)
  setTimeout(function () { $("#home_search_icon").addClass("in"); }, 2350)
  setTimeout(function () { $("#home_search_btn").addClass("in"); }, 2950)
  setTimeout(function () { $("#home_search_btn").removeClass("home_animation") }, 3800)
  setTimeout(function () { $("#home_intro_text").addClass("in") }, 3050)
  setTimeout(function () { $("#home_all_collections_link").addClass("in") }, 3100)
  setTimeout(function () { $("#home_footer").addClass("in") }, 3150)
  setTimeout(function () { $("#footer").addClass("out") }, 150)
  setTimeout(function () { $("#menu").addClass("in") }, 3150)
  setTimeout(function () {
    updateAnimation("home")
  }, bg_animation_time)
}

function outHome() {
  $("#home_logo").removeClass("in").removeClass("fadein").addClass("out")
  $("#home_search_btn").addClass("home_animation")

  setTimeout(function () {
    $("#home_search_btn").removeClass("in").addClass("out")
    $("#home_search_icon").removeClass("in").addClass("out")
    $("#home_intro_text").removeClass("in").addClass("out")
    $("#home_all_collections_link").removeClass("in").addClass("out")
    $("#home_description").removeClass("in").addClass("out")
    $("#home_footer").removeClass("in").addClass("out")
    $("#home_search").removeClass("in").addClass("out")
    $("#footer").removeClass("out").addClass("in")
  }, 250)

  setTimeout(function () {
    $("#home").find(".in").removeClass("in")
    $("#home").find(".out").removeClass("out")
    $("#home").addClass("hidden")
  }, animation_time)

  updateAnimation("homeOut")
}

/**
 * route: /collection
 */
function inCollection() {
  setTimeout(function () { $("#collection").addClass("in") }, 120)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").addClass("in") }, 350)

  updateAnimation("collection")
}

function outCollection() {
  setTimeout(function () { $("#collection").removeClass("in").addClass("out") }, 100)

  setTimeout(function () {
    $("#collection").removeClass("in").removeClass("out")
    $("#collection").find(".in").removeClass("in")
    $("#collection").find(".out").removeClass("out")
    $("#collection").addClass("hidden")
  }, animation_time)
}

/**
 * path: /collection/:id
 */
function inSingle() {
  $("#single").removeClass("hidden")
  setTimeout(function () { $("#single").addClass("in") }, 120)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").addClass("in") }, 350)

  updateAnimation("collection")
}
function outSingle() {
  setTimeout(function () { $("#single").removeClass("in").addClass("out") }, 100)

  setTimeout(function () {
    $("#single").removeClass("in").removeClass("out")
    $("#single").find(".in").removeClass("in")
    $("#single").find(".out").removeClass("out")
    $("#single").addClass("hidden")
  }, animation_time)
}

/**
 * path: /about
 */
function inAbout() {
  $("#about").removeClass("hidden")
  setTimeout(function () { $("#about_small_logo").addClass("in"); }, 100)
  setTimeout(function () { $("#about_subtitle").addClass("in"); }, 100)
  setTimeout(function () { $("#about_big_image").addClass("in") }, 100)
  setTimeout(function () { $("#about_description").addClass("in") }, 120)
  setTimeout(function () { $("#about_maps_container").addClass("in") }, 320)
  setTimeout(function () { $("#about_sponsors").addClass("in") }, 320)
  setTimeout(function () { $("#about_collections").addClass("in") }, 320)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").addClass("in") }, 350)
  setTimeout(function () {
    updateAnimation("about")
  }, bg_animation_time);

}
function outAbout() {
  setTimeout(function () { $("#about_small_logo").removeClass("in").addClass("out") }, 180)
  setTimeout(function () { $("#about_subtitle").removeClass("in").addClass("out") }, 180)
  setTimeout(function () { $("#about_description").removeClass("in").addClass("out") }, 100)
  setTimeout(function () { $("#about_big_image").removeClass("in").addClass("out") }, 0)
  setTimeout(function () { $("#about_sponsors").addClass("in") }, 100)
  setTimeout(function () { $("#about_collections").addClass("in") }, 100)
  setTimeout(function () { $("#about_maps_container").addClass("in") }, 100)
  setTimeout(function () {
    $("#about").find(".in").removeClass("in")
    $("#about").find(".out").removeClass("out")
    $("#about").addClass("hidden")
  }, animation_time)
  updateAnimation("aboutOut")
}

/**
 * path: /visit
 */
function inVisit() {
  $("#visit").removeClass("hidden")
  setTimeout(function () { $("#visit_title").addClass("in") }, 100)
  setTimeout(function () { $("#visit_description").addClass("in") }, 100)
  setTimeout(function () { $("#visit_subtitle_02").addClass("in") }, 100)
  setTimeout(function () { $("#visit_text_01").addClass("in") }, 200)
  setTimeout(function () { $("#visit_text_02").addClass("in") }, 400)
  setTimeout(function () { $("#map_container").addClass("in") }, 600)
  setTimeout(function () { $("#visit_other_spaces").addClass("in") }, 1000)

  setTimeout(function () { $("#menu").addClass("in") }, 650)
  setTimeout(function () { $("#footer").addClass("in") }, 650)
  setTimeout(function () {
    updateAnimation("visit")
  }, bg_animation_time);
}
function outVisit() {
  setTimeout(function () { $("#visit_title").removeClass("in").addClass("out") }, 0)
  setTimeout(function () { $("#visit_description").removeClass("in").addClass("out") }, 0)
  setTimeout(function () { $("#visit_subtitle_02").removeClass("in").addClass("out") }, 0)
  setTimeout(function () { $("#visit_text_01").removeClass("in").addClass("out") }, 0)
  setTimeout(function () { $("#visit_text_02").removeClass("in").addClass("out") }, 0)
  setTimeout(function () { $("#visit_other_spaces").removeClass("in").addClass("out") }, 0)
  setTimeout(function () { $("#map_container").removeClass("in").addClass("out") }, 0)

  setTimeout(function () {
    $("#visit").find(".in").removeClass("in")
    $("#visit").find(".out").removeClass("out")
    $("#visit").addClass("hidden")
  }, animation_time)
  updateAnimation("visitOut")
}

function inPrivacy() {
  setTimeout(function () { $("#privacy").addClass("in") }, 120)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").addClass("in") }, 350)
  setTimeout(function () {
    updateAnimation("legal")
  }, bg_animation_time);
}
function outPrivacy() {
  setTimeout(function () { $("#privacy").removeClass("in").addClass("out") }, 100)

  setTimeout(function () {
    $("#privacy").removeClass("in").removeClass("out")
    $("#privacy").find(".in").removeClass("in")
    $("#privacy").find(".out").removeClass("out")
    $("#privacy").addClass("hidden")
  }, animation_time)
  updateAnimation("legalOut")
}

function inLearn() {
  setTimeout(function () { $("#learn").addClass("in") }, 120)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").addClass("in") }, 350)
  setTimeout(function () {
    updateAnimation("learn")
  }, bg_animation_time);

}
function outLearn() {
  setTimeout(function () { $("#learn").removeClass("in").addClass("out") }, 100)

  setTimeout(function () {
    $("#learn").removeClass("in").removeClass("out")
    $("#learn").find(".in").removeClass("in")
    $("#learn").find(".out").removeClass("out")
    $("#learn").addClass("hidden")
  }, animation_time)
  updateAnimation("learnOut")
}

function inCredits() {
  setTimeout(function () { $("#credits").addClass("in") }, 120)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").removeClass("out").addClass("in") }, 350)
  setTimeout(function () {
    updateAnimation("legal")
  }, bg_animation_time);
}
function outCredits() {
  setTimeout(function () { $("#credits").removeClass("in").addClass("out") }, 100)

  setTimeout(function () {
    $("#credits").removeClass("in").removeClass("out")
    $("#credits").find(".in").removeClass("in")
    $("#credits").find(".out").removeClass("out")
    $("#credits").addClass("hidden")
  }, animation_time)
  updateAnimation("legalOut")
}
function inLegal() {
  setTimeout(function () { $("#legal").addClass("in") }, 120)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").removeClass("out").addClass("in") }, 350)
  setTimeout(function () {
    updateAnimation("legal")
  }, bg_animation_time);
}
function outLegal() {
  setTimeout(function () { $("#legal").removeClass("in").addClass("out") }, 100)

  setTimeout(function () {
    $("#legal").removeClass("in").removeClass("out")
    $("#legal").find(".in").removeClass("in")
    $("#legal").find(".out").removeClass("out")
    $("#legal").addClass("hidden")
  }, animation_time)
  updateAnimation("legalOut")
}


/**
 * route: /my-coleccion
 */
function inMyCollection() {
  setTimeout(function () { $("#collection").addClass("in") }, 120)
  setTimeout(function () { $("#menu").addClass("in") }, 350)
  setTimeout(function () { $("#footer").addClass("in") }, 350)

  updateAnimation("collection")
}

function outMyCollection() {
  setTimeout(function () { $("#collection").removeClass("in").addClass("out") }, 100)

  setTimeout(function () {
    $("#collection").removeClass("in").removeClass("out")
    $("#collection").find(".in").removeClass("in")
    $("#collection").find(".out").removeClass("out")
    $("#collection").addClass("hidden")
  }, animation_time)
}


const animation = {
  home: { in: inHome, out: outHome },
  collection: { in: inCollection, out: outCollection },
  single: { in: inSingle, out: outSingle },
  about: { in: inAbout, out: outAbout },
  visit: { in: inVisit, out: outVisit },
  learn: { in: inLearn, out: outLearn },
  privacy: { in: inPrivacy, out: outPrivacy },
  credits: { in: inCredits, out: outCredits },
  legal: { in: inLegal, out: outLegal },
  myCollection: { in: inMyCollection, out: outMyCollection }
}

export default animation