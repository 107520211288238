import React from 'react'

/**
 * Componente stateless para renderizar un mensaje de búsqueda sin resultados
 *
 * @param {Object} props
 */
const emptyResult = props => <div className="col-sm-12 text-center spinner">
  <h2>¿No encontraste lo que buscabas? Te recomendamos buscar por archivo de origen, título de la obra, palabra clave o línea temática de la Colección M68.</h2>
</div>
export default emptyResult