import React from 'react'
import { connect } from 'react-redux'

import TextFilter from './TextFilter'
import TransversalApproachFilter from './TransversalApproachFilter'
import ThematicCoverageFilter from './ThematicCoverageFilter'
import DateRangeFilter from './DateRangeFilter'
import CollectionFilter from './CollectionFilter'

import {
  setCollection,
  setDateRange,
  setKeyword,
  setTextFilter,
  setPage,
  setTransversalApproach,
} from '../../../../redux/actions/filters'


/**
 * Clase para manejar el estado de los filtros en la colección
 *
 * @class SearchBar
 * @extends {React.Component}
 */
class SearchBar extends React.Component {
  state = {
    textFilter: '',
    dateRange: { min: 1960, max: (new Date()).getFullYear() },
    isDateRangeChanged: false,
    collection: '',
    transversalApproach: '0',
    keyword: '0',
    activeSearch: 'none',
  }


  /**
   * Actualiza el valor del texto a buscar
   * 
   * @param {Object} e
   * @memberof SearchBar
   */
  onTextChangeHandler = e => {
    this.setState({ textFilter: e.target.value })
  }

  /**
   * Dispara la busqueda del filtro texto
   *
   * @memberof SearchBar
   */
  onSearchHandler = () => {
    // this.props.dispatch(resetFilters())
    this.props.dispatch(setTextFilter(this.state.textFilter === '' ? '0' : this.state.textFilter))
    this.setState({ activeSearch: 'none' })
    this.props.onSearch()
  }


  /**
   * Actualiza la interfaz del filtro seleccionado
   *
   * @param {String} filter
   * @memberof SearchBar
   */
  showFilterHandler = filter => {
    this.setState(prevState => ({ activeSearch: filter === prevState.activeSearch ? 'none' : filter }))
  }

  /**
   * Agrega el filtro seleccionado a redux
   *
   * @param {String} filter
   * @param {String} id
   * @memberof SearchBar
   */
  addFilterHandler = (filter, id) => {
    // this.props.dispatch(resetFilters())

    switch (filter) {
      case 'transversal': {
        this.props.dispatch(setTransversalApproach(id))
        break
      }
      case 'keyword': {
        this.props.dispatch(setKeyword(id))
        break
      }
      case 'collection': {
        this.props.dispatch(setCollection(id))
        break
      }
      default: break
    }
    this.props.dispatch(setPage(0))
    this.setState({ activeSearch: 'none' })
    this.props.onSearch()
  }

  /**
   * Maneja el cambio de estado en el filtro fecha
   *
   * @param {Object} date
   * @memberof SearchBar
   */
  changeDateHandler = date => {
    let dateRange = {
      min: date.min < 1960 ? 1960 : date.min,
      max: date.max > (new Date()).getFullYear() ? (new Date()).getFullYear() : date.max
    }
    if (date.min === (new Date()).getFullYear() && date.max === (new Date()).getFullYear() + 1) {
      dateRange = {
        min: 2015,
        max: (new Date()).getFullYear()
      }
    }

    this.setState({ dateRange })
  }

  /**
   * Dispara la busqueda con el filtro fecha
   *
   * @memberof SearchBar
   */
  applySearchFilter = () => {
    let { min, max } = this.state.dateRange
    this.props.dispatch(setDateRange(`${min}_${max}`))
    this.setState({ activeSearch: 'none', isDateRangeChanged: true })
    this.props.onSearch()
  }

  /**
   * Actualiza el valor del filtro fecha (soluciona problema de último año seleccionado)
   *
   * @param {Object} value
   * @memberof SearchBar
   */
  onChangeStart = value => {
    if (value.min === 2018 && value.max === 2018) {
      this.setState({ dateRange: { min: 2017, max: 2018 } })
    }
  }

  /**
   * Dispara el modo de busqueda avanzada
   *
   * @memberof SearchBar
   */
  advanceSearch = () => {
    this.showFilterHandler('none')
    this.props.onAdvanceSearch()
  }

  /**
   * Actualiza la información inicial
   *
   * @memberof SearchBar
   */
  componentDidMount() {
    /*     let date = { min: 1960, max: 2018 }
        if (this.props.filters.dateRange !== '0') {
          let dates = this.props.filters.dateRange.split('_')
          date = { min: Number(dates[0]), max: Number(dates[1]) }
        } */
    let { text } = this.props.filters
    this.setState({ textFilter: text === '0' ? '' : text })
  }


  /**
   * Actualiza los valores del filtro fecha cuando el componente padre modifica los valores
   *
   * @param {Object} nextProps
   * @param {Object} nextState
   * @memberof SearchBar
   */
  componentWillReceiveProps(nextProps, nextState) {
    if (this.state.textFilter !== '' && nextProps.filters.text === '0') {
      this.setState({ textFilter: '' })
    }
    if (this.state.isDateRangeChanged && nextProps.filters.dateRange === '0') {
      this.setState({ dateRange: { min: 1960, max: 2018 }, isDateRangeChanged: false })
    }
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof SearchBar
   */
  render() {
    let active = this.state.activeSearch

    return <div className='row'>
      <div className='col-sm-12'>
        <div id="collection_search_bar" className={`${active !== 'none' ? 'advance' : ''}`}>
          <div id="collection_search">
            <div className="row no_margin">
              <TextFilter
                text={this.state.textFilter}
                onTextChange={this.onTextChangeHandler}
                onSearch={this.onSearchHandler}
              />
              <div className="col-sm-6 col-md-5 no_padding">
                <table className="submenu">
                  <tbody>
                    <tr>
                      <td><div onClick={() => { this.showFilterHandler('transversal') }} className={`search_item ${active !== 'transversal' ? 'active' : ''} font_medium`}> Tema </div></td>

                      <td><div onClick={() => { this.showFilterHandler('date') }} className={`search_item ${active !== 'date' ? 'active' : ''} font_medium`}> Fecha </div></td>

                      <td><div onClick={() => { this.showFilterHandler('collection') }} className={`search_item ${active !== 'collection' ? 'active' : ''} font_medium`}> Archivo </div></td>

                      <td><div onClick={() => { this.showFilterHandler('keyword') }} className={`search_item ${active !== 'keyword' ? 'active' : ''} font_medium`}> Palabras Clave </div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="advance_search" style={{ zIndex: 0, width: '100%', right: 0, textAlign: 'center', color: 'black' }}>
            <div onClick={this.advanceSearch} className={`search_item font_medium`}>
              <span style={{ color: 'black' }}> Búsqueda avanzada {'>'}</span>
            </div>
          </div>
          <div className="advance_search_container">
            <div className="info">
              <div className="submenu text-right">
                <div onClick={() => this.showFilterHandler('none')} id="close_advance_search" className="close_btn button"><i className="icon icon-times"></i></div>
              </div>
              <div id="advance_search_types">
                <TransversalApproachFilter
                  filters={this.props.data.filterData.transversalApproaches}
                  activeSearch={this.state.activeSearch}
                  addFilterHandler={this.addFilterHandler}
                />
                <ThematicCoverageFilter
                  filters={this.props.data.filterData.keywords}
                  activeSearch={this.state.activeSearch}
                  addFilterHandler={this.addFilterHandler}
                />
                <CollectionFilter
                  filters={this.props.data.filterData.collections}
                  activeSearch={this.state.activeSearch}
                  addFilterHandler={this.addFilterHandler}
                />
                <DateRangeFilter
                  activeSearch={this.state.activeSearch}
                  date={this.state.dateRange}
                  onDateChange={this.changeDateHandler}
                  onSearch={this.applySearchFilter}
                  onChangeStart={this.onChangeStart}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div onClick={() => this.showFilterHandler('none')} id="close_advance_blackout" className={`${active === 'none' ? 'hidden' : ''}`}></div>
    </div>
  }
}
const mapStateToProps = state => ({
  filters: state.filters,
  data: state.data
})
export default connect(mapStateToProps)(SearchBar)
