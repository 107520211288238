import * as THREE from 'three'
import { TweenMax, Expo, Power1, } from "gsap/TweenMax"
import imageLoader from './ImageLoader'

let camera, scene, renderer, elements = []
let geometry, group, originalPositions = [], aboutPositions = [], visitPositions = [], learnPositions = []
let projPosition, projVector, current_section, resizeTimer

const $ = window.$
const cameraPos = 500


/**
 * Función que detecta si el navegador soporta webgl
 *
 * @returns { Boolean } - retorna verdadero o falso
 */
function webglAvailable() {
  try {
    var canvas = document.createElement("canvas");
    return !!
      window.WebGLRenderingContext &&
      (canvas.getContext("webgl") ||
        canvas.getContext("experimental-webgl"));
  } catch (e) {
    return false;
  }
}


/**
 * Inicializa la escena de Threejs
 *
 * @param {HTMLObjectElement} container 
 */
function init(container) {

  //$("#canvas_container").height();

  scene = new THREE.Scene()
  camera = new THREE.PerspectiveCamera(45, container.offsetWidth / container.offsetHeight, 1, 1000)
  //camera = new THREE.PerspectiveCamera(45, 1280 / 720, 1, 1000)
  camera.position.set(0, 0, cameraPos)
  //camera.lookAt(new THREE.Vector3(0, 0, 0))

  renderer = new THREE.WebGLRenderer({ alpha: true })

  renderer.setSize(container.offsetWidth, container.offsetHeight)
  container.appendChild(renderer.domElement)

  projPosition = new THREE.Vector2();
  projVector = new THREE.Vector3();

  for (let index = 0; index < 23; index++) elements.push(imageLoader[index])

  geometry = new THREE.PlaneGeometry(50, 50, 32)
  group = new THREE.Group()

  elements = elements.map((img, index) => {
    let textureLoader = new THREE.TextureLoader()
    let material = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.99,
      map: textureLoader.load(img)
    })

    let plane = new THREE.Mesh(geometry, material)

    plane.position.x = 0
    plane.position.y = 400
    plane.position.z = 0
    plane.receiveShadow = true
    plane.speed = randRangeFloat(10, 15)
    //plane.material.uniforms.transparent = true;
    // originalPositions.push({
    //   x: plane.position.x,
    //   y: plane.position.y,
    //   z: plane.position.z
    // })

    group.add(plane)
    return plane
  })
  scene.add(group)

  window.addEventListener('resize', resizeScene)
  addMouseWheelEventListener(handleWheel)

}


/**
 * Ejecuta la animación por defecto
 *
 */
function animate() {
  requestAnimationFrame(animate)
  renderer.render(scene, camera)

  if ($(".section").length > 0) {
    TweenMax.to(camera.position, 0.2, { y: ($(".section").offset().top - $(window).scrollTop()) * 0.2 })
  }

}


/**
 * Escucha cambios de dimensiones y reajusta el canvas
 *
 */
function resizeScene() {
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()
  renderer.setSize(window.innerWidth, window.innerHeight)

  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function () {
    rearrengeCardsPosition()
  }, 250);


}


/**
 * Reinicia las posiciones de las tarjetas a animar
 *
 */
function rearrengeCardsPosition() {
  if (current_section)
    updateAnimation(current_section)
}


var handleWheel = function (event) {
  // cross-browser wheel delta
  // Chrome / IE: both are set to the same thing - WheelEvent for Chrome, MouseWheelEvent for IE
  // Firefox: first one is undefined, second one is MouseScrollEvent
  var e = window.event || event;

  if ($(".section").length > 0)
    if ($(window).height() - $(".section").height() < $(".section").offset().top - $(window).scrollTop()) {
      camera.position.y += e.wheelDelta * 0.07
    }

  if (camera.position.y >= 0)
    camera.position.y = 0
  //e.preventDefault();
};


/**
 * Agrega un listener al mouse para detectar posiciones
 *
 * @param {*} scrollHandler
 */
var addMouseWheelEventListener = function (scrollHandler) {
  if (window.addEventListener) {
    // IE9+, Chrome, Safari, Opera
    window.addEventListener("mousewheel", scrollHandler, false);
    // Firefox
    window.addEventListener("DOMMouseScroll", scrollHandler, false);
  }
  else {
    // // IE 6/7/8
    window.attachEvent("onmousewheel", scrollHandler);
  }
}




function ScreenToWorldPosition(screenPosition, camera) {
  projPosition.x = (screenPosition.x / window.innerWidth) * 2 - 1;
  projPosition.y = - (screenPosition.y / window.innerHeight) * 2 + 1;
  projVector.set(projPosition.x, projPosition.y, 0.5);
  projVector.unproject(camera);
  return {
    x: projVector.x, y: projVector.y, z: projVector.z
  };
}


/**
 * Animación para la ruta home
 *
 * @param {*} plane
 */
function homeMovement(plane) {
  let speed = randRange(30, 40)
  TweenMax.to(plane.position, speed, { x: plane.position.x + 20, y: plane.position.y + 50, ease: Power1.easeInOut, onComplete: homeMovement, onCompleteParams: [plane] })
  //TweenMax.to(plane.rotation, plane.speed, { y: randRangeFloat(-0.2, 0.2), ease: Power1.easeInOut })
}


/**
 * Animación para el movimiento de las tarjetas
 *
 * @param {*} plane
 */
function cardMovement(plane) {
  TweenMax.to(plane.position, plane.speed, { y: plane.position.y - randRange(-5, 5), ease: Power1.easeInOut, onComplete: cardMovement, onCompleteParams: [plane] })
  //TweenMax.to(plane.rotation, plane.speed, { y: randRangeFloat(-0.2, 0.2), ease: Power1.easeInOut })
}


/**
 * Reinicia las posiciones de los objetos
 *
 */
function resetPositions() {
  elements.forEach((plane, index) => {
    TweenMax.killTweensOf(plane.position);
    plane.material.opacity = 1
    plane.position.x = 0
    plane.position.y = 400
    plane.rotation.x = 0
    plane.rotation.y = 0
    plane.rotation.z = 0
  })
}


/* HOME */

/**
 *  Inicializa las posiciones de la ruta home
 *
 */
function setHomePositions() {
  var posX = 120
  var posY = 100
  var randomColum = 3
  var columSize = 60

  resetPositions()

  originalPositions = []
  let homePhotos = [0, 1, 2, 3, 20, 22, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21]

  for (let i = 0; i < homePhotos.length; i++) {
    originalPositions.push({ x: posX, y: posY, z: 200, plane: elements[homePhotos[i]] })
    originalPositions[i].plane.position.x = posX
    originalPositions[i].plane.position.y = posY
    originalPositions[i].plane.position.z = 800
    originalPositions[i].plane.material.opacity = 1

    posX += columSize
    if (posX > columSize * 3) {
      if (i < 5) {
        randomColum = 1
      } else {
        randomColum = 0
      }
      posY -= 60
      posX = columSize * randomColum
    }
  }
}


/**
 * Animación de entrada para la ruta home
 *
 */
function homeAnimation() {
  setHomePositions()
  for (let i = 0; i < originalPositions.length; i++) {
    let speed = randRangeFloat(2, 3.5)
    originalPositions[i].plane.position.z = 800
    originalPositions[i].plane.position.x = originalPositions[i].x + 100
    originalPositions[i].plane.position.y = originalPositions[i].y

    TweenMax.to(originalPositions[i].plane.position, speed, { x: originalPositions[i].x, y: originalPositions[i].y, z: 200 - randRange(-10, 10), delay: 1, ease: Expo.easeInOut, onComplete: homeMovement, onCompleteParams: [originalPositions[i].plane] })
    TweenMax.to(originalPositions[i].plane.material, 0.1, { opacity: 1 })
  }
}


/**
 * Animación de salida para la ruta home
 *
 */
function homeOutAnimation() {
  let delay = 0
  let speed = 1
  for (let i = 0; i < originalPositions.length; i++) {
    TweenMax.to(originalPositions[i].plane.position, speed, { y: originalPositions[i].plane.position.y + 80, delay: delay, ease: Expo.easeIn })
    TweenMax.to(originalPositions[i].plane.material, speed, { opacity: 0, delay: delay, ease: Expo.easeIn })
    speed -= 0.02
    if (speed < 0.5) {
      speed = 0.5
    }
  }
}


/* ABOUT */

/**
 * Inicializa las posiciones de la ruta de about
 *
 */
function setAboutPositions() {
  var sectionVector, worldVector
  aboutPositions = []

  resetPositions()

  elements.forEach((plane, index) => {
    // plane.position.x = 0
    // plane.position.y = 400
    TweenMax.killTweensOf(plane.position);
    elements[index].material.opacity = 1
  })

  sectionVector = new THREE.Vector2($("#about_intro").offset().left, $("#about_intro").offset().top)
  worldVector = ScreenToWorldPosition(sectionVector, camera)

  /* section head cards */
  aboutPositions.push({ x: worldVector.x * 5, y: 80, z: 260, plane: elements[0] })
  aboutPositions.push({ x: worldVector.x * 60, y: 45, z: 260, plane: elements[1] })
  aboutPositions.push({ x: worldVector.x * 70, y: -10, z: 260, plane: elements[2] })
  aboutPositions.push({ x: worldVector.x * 70, y: -80, z: 240, plane: elements[3] })

  /* section extra cards */
  aboutPositions.push({ x: worldVector.x * -85, y: -300, z: 240, plane: elements[20] })
  aboutPositions.push({ x: worldVector.x * -70, y: -320, z: 260, plane: elements[21] })

  aboutPositions.push({ x: worldVector.x * 80, y: -920, z: 230, plane: elements[5] })
  aboutPositions.push({ x: worldVector.x * 80, y: -900, z: 260, plane: elements[6] })

  aboutPositions.push({ x: worldVector.x * -80, y: -1320, z: 260, plane: elements[9] })
  aboutPositions.push({ x: worldVector.x * -80, y: -1300, z: 230, plane: elements[14] })

}

/**
 * Animación de entrada para la ruta about
 *
 */
function aboutAnimation() {
  setAboutPositions()

  for (let i = 0; i < aboutPositions.length; i++) {
    let speed = randRangeFloat(1, 2.5);
    let delay = randRangeFloat(0, 0.5);
    aboutPositions[i].plane.position.x = aboutPositions[i].x - randRange(-5, 5)
    aboutPositions[i].plane.position.y = aboutPositions[i].y - randRange(-5, 5)
    aboutPositions[i].plane.position.z = aboutPositions[i].z - randRange(-10, 10)
    aboutPositions[i].plane.material.opacity = 0
    TweenMax.to(aboutPositions[i].plane.position, speed, { x: aboutPositions[i].x, y: aboutPositions[i].y, z: aboutPositions[i].z, delay: delay, ease: Expo.easeInOut, onComplete: cardMovement, onCompleteParams: [aboutPositions[i].plane] })
    TweenMax.to(aboutPositions[i].plane.material, speed, { opacity: 1, delay: delay, ease: Expo.easeInOut })
  }
  current_section = "about"
}

/**
 * Animación de salida para la ruta about
 *
 */
function aboutOutAnimation() {
  elements.forEach((plane, index) => {
    let speed = randRangeFloat(1, 2);
    TweenMax.killTweensOf(plane.position);
    TweenMax.to(plane.position, speed, { x: plane.position.x - randRange(-10, 10), y: plane.position.y - randRange(-10, 10), z: plane.position.z - randRange(-10, 10), ease: Expo.easeInOut })
    TweenMax.to(plane.material, speed, { opacity: 0, ease: Expo.easeInOut })
  })
}


/* COLLECTION */
/**
 * Animación de salida para la ruta collection
 *
 */
function collectionAnimation() {
  elements.forEach((plane, index) => {
    let speed = randRangeFloat(2, 2.5);
    TweenMax.to(plane.position, speed, { y: 250, ease: Expo.easeInOut })
  })
  current_section = "collection"
}


/* VISIT */

/**
 * Inicializa las posiciones de la ruta de visit
 *
 */
function setVisitPositions() {

  var sectionVector, worldVector, offsetX
  visitPositions = []
  resetPositions()

  sectionVector = new THREE.Vector2($("#map_container").offset().left, $("#map_container").offset().top)
  worldVector = ScreenToWorldPosition(sectionVector, camera)

  offsetX = 0
  if (window.innerHeight < 700) {
    offsetX = 50
  }

  /* section head cards */
  visitPositions.push({ x: worldVector.x, y: 90, z: 250, plane: elements[6] })
  visitPositions.push({ x: worldVector.x + 60, y: 30, z: 380, plane: elements[8] })
  visitPositions.push({ x: worldVector.x + (170 + offsetX), y: -60, z: 280, plane: elements[12] })
  visitPositions.push({ x: worldVector.x - (185 + offsetX), y: -80, z: 280, plane: elements[18] })

}

/**
 * Animación de entrada para la ruta visit
 *
 */
function visitAnimation() {
  setVisitPositions()
  for (let i = 0; i < visitPositions.length; i++) {
    let speed = randRangeFloat(2, 3.5);
    visitPositions[i].plane.position.x = visitPositions[i].x - randRange(-5, 5)
    visitPositions[i].plane.position.y = visitPositions[i].y - randRange(-5, 5)
    visitPositions[i].plane.position.z = visitPositions[i].z
    visitPositions[i].plane.material.opacity = 0

    TweenMax.to(visitPositions[i].plane.position, speed, { x: visitPositions[i].x, y: visitPositions[i].y, z: visitPositions[i].z, ease: Expo.easeInOut, onComplete: cardMovement, onCompleteParams: [visitPositions[i].plane] })
    TweenMax.to(visitPositions[i].plane.material, speed, { opacity: 1, ease: Expo.easeInOut })
  }
  current_section = "visit"
}
/**
 * Animación de salida para la ruta visit
 *
 */
function visitOutAnimation() {
  elements.forEach((plane, index) => {
    let speed = randRangeFloat(1, 2);
    TweenMax.killTweensOf(plane.position);
    TweenMax.to(plane.position, speed, { x: plane.position.x - randRange(-10, 10), y: plane.position.y - randRange(-10, 10), ease: Expo.easeInOut })
    TweenMax.to(plane.material, speed, { opacity: 0, ease: Expo.easeInOut })
  })
}


/* LEARN */
/**
 * Inicializa las posiciones de la ruta de learn
 *
 */
function setLearnPositions() {
  var sectionVector, worldVector, offsetX
  learnPositions = []
  offsetX = 0
  if (window.innerHeight < 700) {
    offsetX = 50
  }

  resetPositions()

  sectionVector = new THREE.Vector2($("#learn_title").offset().left, $("#learn_title").offset().top)
  worldVector = ScreenToWorldPosition(sectionVector, camera)

  /* section head cards */
  learnPositions.push({ x: worldVector.x - (180 + offsetX), y: 20, z: 250, plane: elements[21] })
  learnPositions.push({ x: worldVector.x - (160 + offsetX), y: -10, z: 260, plane: elements[20] })
  learnPositions.push({ x: worldVector.x + (160 + offsetX), y: 40, z: 250, plane: elements[7] })
  learnPositions.push({ x: worldVector.x + (210 + offsetX), y: 10, z: 210, plane: elements[6] })
  learnPositions.push({ x: worldVector.x + (165 + offsetX), y: -230, z: 250, plane: elements[10] })
  learnPositions.push({ x: worldVector.x + (130 + offsetX), y: -260, z: 280, plane: elements[11] })

  learnPositions.push({ x: worldVector.x - (130 + offsetX), y: -390, z: 280, plane: elements[13] })

  learnPositions.push({ x: worldVector.x + (165 + offsetX), y: -500, z: 250, plane: elements[19] })
  learnPositions.push({ x: worldVector.x + (105 + offsetX), y: -520, z: 290, plane: elements[0] })

  learnPositions.push({ x: worldVector.x - (165 + offsetX), y: -700, z: 250, plane: elements[4] })
  learnPositions.push({ x: worldVector.x - (140 + offsetX), y: -720, z: 260, plane: elements[14] })

  learnPositions.push({ x: worldVector.x + (165 + offsetX), y: -900, z: 250, plane: elements[17] })
  learnPositions.push({ x: worldVector.x + (145 + offsetX), y: -920, z: 260, plane: elements[18] })

  learnPositions.push({ x: worldVector.x - (165 + offsetX), y: -1000, z: 250, plane: elements[2] })
  learnPositions.push({ x: worldVector.x - (135 + offsetX), y: -1020, z: 260, plane: elements[5] })
}
/**
 * Animación de entrada para la ruta learn
 *
 */
function learnAnimation() {
  setLearnPositions()
  for (let i = 0; i < learnPositions.length; i++) {
    let speed = randRangeFloat(2, 3.5);
    learnPositions[i].plane.position.x = learnPositions[i].x - randRange(-5, 5)
    learnPositions[i].plane.position.y = learnPositions[i].y - randRange(-5, 5)
    learnPositions[i].plane.position.z = learnPositions[i].z
    learnPositions[i].plane.material.opacity = 0

    TweenMax.to(learnPositions[i].plane.position, speed, { x: learnPositions[i].x, y: learnPositions[i].y, z: learnPositions[i].z, ease: Expo.easeInOut, onComplete: cardMovement, onCompleteParams: [learnPositions[i].plane] })
    TweenMax.to(learnPositions[i].plane.material, speed, { opacity: 1, ease: Expo.easeInOut })
  }
  current_section = "learn"
}
/**
 * Animación de salida para la ruta learn
 *
 */
function learnOutAnimation() {
  elements.forEach((plane, index) => {
    let speed = randRangeFloat(1, 2);
    TweenMax.to(plane.position, speed, { x: plane.position.x - randRange(-10, 10), y: plane.position.y - randRange(-10, 10), ease: Expo.easeInOut })
    TweenMax.to(plane.material, speed, { opacity: 0, ease: Expo.easeInOut })
  })
}

/* LEGAL */
/**
 * Animación de entrada para la ruta legal
 *
 */
function legalAnimation() {
  elements.forEach((plane, index) => {
    let speed = randRangeFloat(2, 2.5);
    TweenMax.to(plane.position, speed, { y: 250, ease: Expo.easeInOut })
  })
}
/**
 * Animación de salida para la ruta learn
 *
 */
function legalOutAnimation() {
  elements.forEach((plane, index) => {
    let speed = randRangeFloat(2, 2.5);
    TweenMax.to(plane.position, speed, { y: 250, ease: Expo.easeInOut })
  })
}

/**
 * Genera un número aleatorio entre dos valores dados
 *
 * @param {Number} min
 * @param {Number} max
 * @returns {Number} - valor aleatorio entero
 */
function randRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Genera un número de punto flotante entre dos valores dados
 *
 * @param {Number} min
 * @param {Number} max
 * @returns {Number} - valor aleatorio de punto flotante
 */
function randRangeFloat(min, max) {
  return Math.random() * (max - min) + min;
}


/**
 * Retorna un elemento canvas para las animaciones
 *
 * @param {HTMLObjectElement} container
 */
export const canvas = container => {
  if (webglAvailable()) {
    init(container)
    animate()
  }
  // setInterval(cardsRotation, 1500)
}

/**
 * Función para actualizar el estado de las animaciones
 *
 * @param {String} path
 */
export const updateAnimation = path => {
  if (webglAvailable()) {
    switch (path) {
      //case 'home-out': visitAnimation(); break;
      case 'home': homeAnimation(); break;
      case 'homeOut': homeOutAnimation(); break;
      case 'collection': collectionAnimation(); break;
      case 'about': aboutAnimation(); break;
      case 'aboutOut': aboutOutAnimation(); break;
      case 'learn': learnAnimation(); break;
      case 'learnOut': learnOutAnimation(); break;
      case 'visit': visitAnimation(); break;
      case 'visitOut': visitOutAnimation(); break;
      case 'legal': legalAnimation(); break;
      case 'leaglOut': legalOutAnimation(); break;
      default: break
    }
  }
}