import React from 'react'
import { Redirect } from 'react-router-dom'

import { isTokenValid, newPassword } from '../../services/learn'
import { isFormValid } from '../../utils/fieldValidations'

/**
 * Clase contralar la interfaz de la pantalla RegeneratePassword
 *
 * @export
 * @class Privacy
 * @extends {React.Component}
 */
export default class RegeneratePassword extends React.Component {

  state = {
    password: '',
    confirmPassword: '',
    loading: true,
    isTokenValid: false,
    isRegenerated: false,
    warningFields: []
  }
  /**
   * Valida los campos del formulario
   *
   * @memberof RegeneratePassword
   */
  validateFields = () => {
    let fields = {
      password: {
        name: 'contraseña',
        value: this.state.password
      }
    }
    isFormValid(fields, fields => {
      if (fields.length === 0 && this.state.password === this.state.confirmPassword)
        this.createPasswordHandler()
      else if (this.state.password !== this.state.confirmPassword)
        this.setState({ warningFields: [{ name: 'contraseñas', message: 'las contraseñas no coinciden' }] })
      else this.setState({ warningFields: fields })
    })
  }


  /**
   * Crea la nueva contraseña en el servidor
   *
   * @memberof RegeneratePassword
   */
  createPasswordHandler = () => {
    newPassword({
      token: `${this.props.match.params.id}`,
      password: `${this.state.password}`,
      password_confirmation: `${this.state.confirmPassword}`
    })
      .then(res => {
        if (res.data.message === 'Contraseña regenerada.') this.setState({ isRegenerated: true })
      })
      .catch(err => console.log('error', err))
  }


  /**
   * Se valida que el token sea valido, de no ser así se redirecciona a home
   *
   * @memberof RegeneratePassword
   */
  componentDidMount() {
    isTokenValid(this.props.match.params.id)
      .then(res => {
        if (res.data.message === 'Enlace Válido') this.setState({ loading: false, isTokenValid: true })
        if (res.data.message === 'Enlace inválido') this.setState({ loading: false, isTokenValid: false })
      })
      .catch(err => console.log('error'))
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof RegeneratePassword
   */
  render() {
    return this.state.loading
      ? <h1> </h1>
      : this.state.isTokenValid
        ? <div className='popup_block full_height contact_animation in'>
          <div className="wrapper">
            <div className="content">
              <div className="popup_container" >
                <div className="popup_content">
                  {
                    this.state.isRegenerated
                      ? <div className="inner-login text-center">
                        <h1 className="title font_bold uppercase text-center" style={{ fontSize: '25px' }}>Tu contraseña ha sido modificada</h1>
                        <button onClick={() => { this.props.history.push('/') }} className="pink_btn btn_shadow">
                          <span>Ir al sitio</span>
                        </button>
                      </div>
                      : <div className="inner-login text-center">
                        <h1 className="title font_bold uppercase text-center">Cambiar contraseña</h1>
                        <div className="title font_bold_">Ingresa tu nueva contraseña</div>
                        <input
                          type='password'
                          className='btn_shadow awesome_input'
                          value={this.state.password}
                          onChange={e => { this.setState({ password: e.target.value }) }}
                          placeholder='Contraseña' />
                        <input
                          type='password'
                          className='btn_shadow awesome_input'
                          value={this.state.confirmPassword}
                          onChange={e => { this.setState({ confirmPassword: e.target.value }) }}
                          placeholder='Confirmar contraseña' />
                        <button onClick={this.validateFields} className="pink_btn btn_shadow">
                          <span>Modificar</span>
                        </button>
                        {
                          this.state.warningFields.map((warning, index) => <p key={index} className={`error error-${index}`}>{warning.message}</p>)
                        }
                      </div>

                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        : <Redirect to='/' />
  }
}