const initialState = {
  items: [],
  scrollPosition: 0,
  amountItems: 0,
  pages: 0,
  filterData: {
    collections: [],
    keywords: [],
    transversalApproaches: [],
    genders: [],
    mediums: [],
    typologies: [],
    languages: [],
    techniques: [],
    thematicCoverage: [],
    agentType: [],
    fundList: [],
    hasLoaded: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ITEMS': return { ...state, items: action.items, }
    case 'SET_SCROLL_POSITION': return { ...state, scrollPosition: action.scrollPosition, }
    case 'SET_AMOUNT': return { ...state, amountItems: action.amountItems, }
    case 'SET_LIMIT': return { ...state, pages: action.pages, }
    case 'SET_FILTER_DATA': return { ...state, filterData: action.filterData }
    default: return state
  }
}
