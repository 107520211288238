import React from 'react'
import StarIcon from './StarIcon'
import theme_pdf_icon from '../../../../assets/img/virtual_classroom/pdf_icon.jpg'

const stars = [1, 2, 3, 4, 5]

/**
 * Clase para la representación de la pantalla Lectures
 *
 * @param {*} props
 */
const lectures = props => <div id="virtual_classroom_themes" className="virtual_classroom_option">
  <div className="row">
    <div className="col-sm-8 col-sm-push-2">
      <h3 className="big_title font_medium">¿Qué tema te interesa conocer?</h3>

      <div id="theme_estudiantil" className="virtual_classroom_theme">
        <div className="title font_bold">
          Actividades en la exposición M68
        </div>

        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#primaria_memorial">
              <span className="level_1">Primaria</span>
            </a>
          </div>
          <div id="primaria_memorial" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">
              {
                /*
                <div className="description">
                  <p> Actividades en el Memorial para el movimiento estudiantil de 1968 en México</p>
                </div>
                */
              }
              {
                props.activities.elemetarySchool
                  .filter(activism => activism.activity.search('en el memorial') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.elemetarySchool
                  .filter(activism => activism.activity.search('en el memorial') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>


        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#secundaria_memorial">
              <span className="level_1">Secundaria</span>
            </a>
          </div>
          <div id="secundaria_memorial" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">
              {
                props.activities.highSchool
                  .filter(activism => activism.activity.search('en el memorial') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.highSchool
                  .filter(activism => activism.activity.search('en el memorial') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>


        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#preparatoria_memorial">
              <span className="level_1">Preparatoria</span>
            </a>
          </div>
          <div id="preparatoria_memorial" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">
              {
                props.activities.highSchool2
                  .filter(activism => activism.activity.search('en el memorial') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.highSchool2
                  .filter(activism => activism.activity.search('en el memorial') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>


        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#universidad_memorial">
              <span className="level_1">Universidad</span>
            </a>
          </div>
          <div id="universidad_memorial" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">
              {
                props.activities.university
                  .filter(activism => activism.activity.search('en el memorial') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.university
                  .filter(activism => activism.activity.search('en el memorial') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>

      </div>

      <div id="theme_sociales" className="virtual_classroom_theme">
        <div className="title font_bold">
          Actividades en la Colección digital M68
        </div>

        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#primaria_digital">
              <span className="level_1">Primaria</span>
            </a>
          </div>
          <div id="primaria_digital" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">
              {
                /*
                <div className="description">
                  <p> Actividades en el Memorial para el movimiento estudiantil de 1968 en México</p>
                </div>
                */
              }
              {
                props.activities.elemetarySchool
                  .filter(activism => activism.activity.search('en línea') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.elemetarySchool
                  .filter(activism => activism.activity.search('en línea') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>

        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#secundaria_digital">
              <span className="level_1">Secundaria</span>
            </a>
          </div>
          <div id="secundaria_digital" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">
              {
                props.activities.highSchool
                  .filter(activism => activism.activity.search('en línea') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.highSchool
                  .filter(activism => activism.activity.search('en línea') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>

        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#preparatoria_digital">
              <span className="level_1">Preparatoria</span>
            </a>
          </div>
          <div id="preparatoria_digital" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">
              {
                props.activities.highSchool2
                  .filter(activism => activism.activity.search('en línea') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.highSchool2
                  .filter(activism => activism.activity.search('en línea') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>

        <div className="accordion-group">
          <div className="accordion-heading">
            <a className="accordion_link accordion-toggle collapsed" data-toggle="collapse" data-parent="#theme_estudiantil" href="#universidad_digital">
              <span className="level_1">Universidad</span>
            </a>
          </div>
          <div id="universidad_digital" className="accordion-body collapse accordion_level_2 ">
            <div className="accordion-inner">

              {
                props.activities.university
                  .filter(activism => activism.activity.search('en línea') !== -1)
                  .map(activity => <div key={activity.id} className="media virtual_classroom_theme_media">
                    <div className="media-left media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        <img className="icon center-block" src={theme_pdf_icon} alt="PDF" />
                        Descargar
                      </a>
                    </div>
                    <div className="media-body media-middle">
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} target="_blank" rel="noopener noreferrer">
                        {activity.title}
                      </a>
                      <br />
                      <a href={activity.filename} onClick={() => props.onLectureRank(activity)} className='stars-ranking' target="_blank" rel="noopener noreferrer">
                        {stars.map(value => <StarIcon key={value} rank={activity.rating} value={value} />)}
                      </a>
                    </div>
                  </div>)
              }
              {
                props.activities.university
                  .filter(activism => activism.activity.search('en línea') !== -1).length === 0
                  ? <h4>Aún no hay actividades disponibles</h4>
                  : null
              }
            </div>
          </div>
        </div>


      </div>

    </div>

  </div>
</div >


export default lectures