import React from 'react'
import { withRouter } from 'react-router-dom'
import { askForPassword } from '../../services/learn'
import { isFormValid } from '../../utils/fieldValidations'

/**
 * Clase para recuperar la contraseña de los usuarios
 *
 * @class ForgotPassword
 * @extends {React.Component}
 */
class ForgotPassword extends React.Component {
  state = {
    email: '',
    warningFields: [],
    success: false,
    token: ''
  }


  /**
   * Valida los valores del formulario
   *
   * @memberof ForgotPassword
   */
  validateFields = () => {
    let fields = {
      email: {
        name: 'correo electrónico',
        value: this.state.email
      },
    }
    isFormValid(fields, fields => {
      if (fields.length === 0) this.recoverPassword()
      else this.setState({ warningFields: fields })
    })
  }


  /**
   * Solicita un token para recuperar la contraseña
   *
   * @memberof ForgotPassword
   */
  recoverPassword = () => {
    const { email } = this.state
    askForPassword(email)
      .then(res => {
        if (res.data.status === 200) this.setState({ success: true, token: res.data.token })
      })
      .catch(error => console.log('error', error))
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement} 
   * @memberof ForgotPassword
   */
  render() {
    return this.state.success
      ? <div className="inner-login text-center">
        <h1 className="title font_bold uppercase text-center">¡Listo!, revisa tu correo</h1>
        {
          /*
          <div className="pink_btn btn_shadow">
          <a target='_blank' href={`/#/nueva-contrasena/${this.state.token}`}>
            <span>Botón de prueba</span>
          </a>
        </div>
          */
        }
      </div>
      : <div className="inner-login text-center">
        <h1 className="title font_bold uppercase text-center">Nueva contraseña</h1>
        <p className="required-account">Escribe tu correo electrónico</p>
        <input
          className='btn_shadow awesome_input'
          value={this.state.email}
          onChange={e => { this.setState({ email: e.target.value }) }}
          placeholder='Email' />
        <button onClick={this.validateFields} className="pink_btn btn_shadow">
          <span>Enviar</span>
        </button>
        {
          this.state.warningFields.map((warning, index) => <p key={index} className={`error error-${index}`}>{warning.message}</p>)
        }
        <h5 className='create-account grey_font'><span className="action-account pink_font" onClick={() => this.props.onChangeSectionHandler('login')}>Volver</span></h5>
      </div>
  }
}

export default withRouter(ForgotPassword)
