import React from 'react'
import ReactGA from 'react-ga'
import animations from '../../utils/animations'
import Loading from '../../baseComponents/Loading'

import { connect } from 'react-redux'

import './index.css'

/**
 * Clase para la representación de la pantalla credits
 *
 * @export
 * @class Credits
 * @extends {React.Component}
 */
class Credits extends React.Component {


  /**
   * Dispara las animaciones cuando el componente se ha montado
   *
   * @memberof Credits
   */
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)
    animations.credits.in()
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) animations.credits.in()
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns
   * @memberof Credits
   */
  render() {
    const { isLoading, acknowledgement } = this.props.footer
    const { section1, section2, section3 } = acknowledgement

    if (isLoading) return <Loading />

    return <div id="credits" className="section section_padding credits_animation">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className='description'>

              <div id="credits_container" >
                <div className="row info_block">
                  <div className="col-sm-12" >
                    <h1 className="font_bold big_title">{section1.title}</h1>
                  </div>
                  {
                    section1.content.map((item, index) => {
                      return <div className="col-sm-4" key={index}>
                        <div dangerouslySetInnerHTML={{ __html: item }} />
                      </div>
                    })
                  }
                </div>

                <div className="row info_block">
                  <div className="col-sm-12 ">
                    <h1 className="font_bold big_title">{section2.title}</h1>
                  </div>

                  {
                    section2.content.map((item, index) => {
                      return <div className="col-sm-4" key={index}>
                        <div dangerouslySetInnerHTML={{ __html: item }} />
                      </div>
                    })
                  }

                </div>

                <div className="row info_block">
                  <div className="col-sm-12 ">
                    <h1 className="font_bold big_title">{section3.title}</h1>
                  </div>
                  <div className="col-sm-12 credits_paragraph">
                    <div dangerouslySetInnerHTML={{ __html: section3.content }} />
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => ({
  footer: state.footer,
})

export default connect(mapStateToProps)(Credits)