import React from 'react'

import './FilesContainer.css'


/**
 * Componente stateless para la representación del visor de documentos
 *
 * @param {*} props
 * @returns
 */
const filesContainer = props => {
  let url = props.src.replace('.jpg', '')
  return <iframe
    title='M68-file-reader'
    className="viewer-embed" src={`https://docs.google.com/gview?url=${url}&embedded=true`}
    frameBorder="0"></iframe>
}


export default filesContainer