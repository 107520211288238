import React from 'react'

/**
 * Clase para la representación de la pantalla Glossary
 *
 * @param {*} props
 */
const glossary = props => <div id="virtual_classroom_glossary" className="virtual_classroom_option">
  <div className="row">
    <div className="col-sm-8 col-sm-push-2">
      <h3 className="big_title font_medium">Acitividades de mediación</h3>


      {
        props.items.map(item => <div key={item.word} className="virtual_classroom_concept">
          <div className="title font_bold">
            {item.word}
          </div>
          <div className="description">
            {item.meaning.split('\n').map(t => <p>{t}</p>)}
          </div>
          {
            item.url
              ? <img alt='Imagen' src={item.url} className='image-cover' />
              : null
          }
        </div>)
      }
      {
        !props.items.length
          ? <div className="virtual_classroom_concept">
            <div className="title font_bold">Aún no hay actividades disponibles</div>
          </div>
          : null
      }

      <h3 className="big_title font_medium">Fuentes bibliográficas </h3>

      {
        props.words.map((item, index) => <div key={index} className="virtual_classroom_concept">
          <div className="title">
            {item}
          </div>
        </div>)
      }
      {
        !props.words.filter(w => w.trim().length !== 0).length
          ? <div className="virtual_classroom_concept">
            <div className="title font_bold">Aún no hay fuentes disponibles</div>
          </div>
          : null
      }
    </div>
  </div>
</div>

export default glossary