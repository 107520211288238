import axios from './instance'

export const rateLesson = (id, rate, lastRate) => {
  let form = new FormData()
  form.append('lesson_id', id)
  form.append('rate', lastRate < rate ? rate - lastRate : rate - lastRate)
  return axios('/rateLesson', {
    method: 'POST',
    data: form
  })
}