import React from 'react'
import { connect } from 'react-redux'
import animations from '../../utils/animations'



/**
 * Clase para manejar el estado de interfaz del footer de la apliación
 *
 * @export
 * @class SocialNetworks
 * @extends {React.Component}
 */
class SocialNetworks extends React.Component {

  /**
   * Ejectuta la animación de salida
   *
   * @memberof SocialNetworks
   */
  onclickHandler = path => {

    if (this.props.location.pathname === path) return
    switch (this.props.location.pathname) {
      case '/': { animations.home.out(); break }
      case '/coleccion': { animations.collection.out(); break }
      case '/acerca-de': { animations.about.out(); break }
      case '/visita': { animations.visit.out(); break }
      case '/aviso-de-privacidad': { animations.privacy.out(); break }
      case '/aprende': { animations.learn.out(); break }

      case '/creditos-y-agradecimientos': { animations.credits.out(); break }
      case '/legal': { animations.legal.out(); break }
      case '/terminos-y-condiciones': { animations.privacy.out(); break }
      default: { animations.single.out() }
    }

    setTimeout(() => { this.props.history.push(path) }, 1200)
  }

  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof SocialNetworks
   */
  render() {
    const { isLoading, footerLinkNames } = this.props.footer

    return <div id="footer" className="grey_font footer_animation">
      {
        !isLoading &&
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-8">

              <a href='elemento' style={{ marginLeft: '10px' }} className='menu_animation' onClick={e => { e.preventDefault(); this.onclickHandler('/creditos-y-agradecimientos') }}>{footerLinkNames.acknowledgement}</a><br className="hidden-lg hidden-md hidden-sm" />
              <a href='elemento' style={{ marginLeft: '10px' }} className='menu_animation' onClick={e => { e.preventDefault(); this.onclickHandler('/legal') }}>{footerLinkNames.legals}</a>
              <a href='elemento' style={{ marginLeft: '10px' }} className='menu_animation' onClick={e => { e.preventDefault(); this.onclickHandler('/terminos-y-condiciones') }}>{footerLinkNames.terms}</a>
              <a href='elemento' style={{ marginLeft: '10px' }} className='menu_animation' onClick={e => { e.preventDefault(); this.onclickHandler('/aviso-de-privacidad') }}>{footerLinkNames.privacy}</a>
              <a href='elemento' style={{ marginLeft: '10px' }} className='menu_animation' onClick={e => { e.preventDefault(); this.onclickHandler('/declaracion-de-uso') }}>{footerLinkNames.declaration}</a>

            </div>
            <div className="col-sm-4">
              {/* <div className="aura grey_font">
              by <a href="http://www.auracultura.com/" target="_blank"><img className="" src={aura_logo} /></a>
            </div> */}
            </div>
          </div>
        </div>
      }
    </div>
  }
}

const mapStateToProps = state => ({
  footer: state.footer
})

export default connect(mapStateToProps)(SocialNetworks)