import React from 'react'
import axios from 'axios'
import WatsonResponse from './Components/WatsonResponse'

/**
 * Clase para el manejo del chatbot
 *
 * @export
 * @class Chat
 * @extends {React.Component}
 */
export default class Chat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      messages: [],
      message: '',
      currentContext: null
    }
    this.scroll = React.createRef()
  }

  /**
   * Maneja el estado de visibilidad del chat
   *
   * @memberof Chat
   */
  onClick = () => { this.setState(prevState => ({ isOpen: !prevState.isOpen })) }


  /**
   * Actualiza el estado de texto
   *
   * @memberof Chat
   */
  onTextChange = e => { this.setState({ message: e.target.value }) }


  /**
   * Ejecuta el comando realizado por el usuario
   *
   * @memberof Chat
   */
  onSearch = () => {
    let message = this.state.message
    this.setState(prevState => ({
      messages: [...prevState.messages, { emitter: 'me', type: 'text', message: prevState.message },],
      message: ''
    }), () => { if (this.scroll.current !== null) this.scroll.current.scrollTo(0, this.scroll.current.scrollHeight) })
    axios.post(`https://m68-chatbot.herokuapp.com/conversation`, {
      text: message,
      contexto: this.state.currentContext
    })
      .then(res => {
        this.setState(prevState => ({
          currentContext: res.data.context,
          messages: [
            ...prevState.messages,
            ...res.data.output.generic.map(result => {
              return { emitter: 'watson', type: result.response_type, ...result, message: res.data.output.text[0] }
            })
          ],
        }))
        this.scroll.current.scrollTo(0, this.scroll.current.scrollHeight)
      })
      .catch(err => console.log('D:', err))
  }


  /**
   * Ejectuta la acción del usuario sobre un elemento clickeable del chat
   *
   * @memberof Chat
   */
  onOptionClick = value => {
    axios.post(`https://m68-chatbot.herokuapp.com/conversation`, {
      text: value.input.text,
      context: this.state.currentContext
    })
      .then(res => {
        this.setState(prevState => ({
          currentContext: res.data.context,
          messages: [
            ...prevState.messages,
            ...res.data.output.generic.map((result, index) => {
              return {
                emitter: 'watson',
                type: result.response_type,
                ...result,
                message: res.data.output.text[index] ? res.data.output.text[index] : result.text
              }
            })
          ],
        }))
        if (this.scroll.current) this.scroll.current.scrollTo(0, this.scroll.current.scrollHeight)
      })
      .catch(err => console.log(err))
  }


  /**
   * Inicializa el contexto del chat
   *
   * @memberof Chat
   */
  componentDidMount() {
    axios.post(`https://m68-chatbot.herokuapp.com/conversation`, { text: 'hola', contexto: this.state.currentContext })
      .then(res => {
        this.setState(prevState => ({
          currentContext: res.data.context,
          messages: [
            ...prevState.messages,
            ...res.data.output.generic.map(result => {
              return { emitter: 'watson', type: result.response_type, ...result, message: res.data.output.text[0] }
            })
          ],
        }))
        if (this.scroll.current) this.scroll.current.scrollTo(0, this.scroll.current.scrollHeight)
      })
      .catch(err => console.log(err))
  }


  /**
   * Maneja el estado de la interfaz de usuario
   *
   * @returns {HTMLObjectElement}
   * @memberof Chat
   */
  render() {
    return !this.state.isOpen
      ? <p onClick={this.onClick} className='chat-btn-open'><span><i className="icon icon-question"></i></span></p>
      : <div className='chat-container'>
        <div className='chat-header media'>
          <h5 className='media-body media-middle'>INFORMACIÓN M68</h5>
          <p
            className='media-right '
            onClick={this.onClick}
            style={{ margin: 0, padding: '10px' }}>
            <span style={{ fontSize: '20px' }}><i className="icon icon-chevron-down"></i></span>
          </p>
        </div>
        <div className='chat-content' ref={this.scroll}>
          {
            this.state.messages.map((message, index) => {
              return message.emitter === 'me'
                ? <div key={index} className='chat-block'>
                  <p className='chat-message right-message' key={`${message.message}-${index}`}>{message.message}</p>
                </div>
                : <WatsonResponse
                  key={index}
                  data={message}
                  isLast={index === this.state.messages.length - 1}
                  onOptionClick={this.onOptionClick}
                  history={this.props.history}
                />
            })
          }
        </div>
        <div className='chat-input row'>
          <input
            autoFocus
            value={this.state.message}
            onChange={this.onTextChange}
            onKeyPress={e => { if (e.key === 'Enter') this.onSearch() }}
            id="home_search_input"
            className="search_input chat-input-text col-xs-10"
            style={{ borderBottom: 'none', }}
            type="text"
            placeholder="Escribe aquí" />
          <button
            onClick={this.onSearch}
            disabled={this.state.message.length === 0}
            className='col-xs-2 chat-btn pink_font'
            style={{ border: 'none' }}
          >
            <span><i className="icon icon-arrow-send"></i></span>
          </button>
        </div>
      </div>
  }
}